/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 * Learn more about it here: https://the-guild.dev/graphql/codegen/plugins/presets/preset-client#reducing-bundle-size
 */
const documents = {
    "\n  mutation useAishameterCushion_CreateCarPriceEstimationLog($input: CreateCarPriceEstimationLogInput!) {\n    createCarPriceEstimationLog(input: $input) {\n      isSuccess\n    }\n  }\n": types.UseAishameterCushion_CreateCarPriceEstimationLogDocument,
    "\n  query CarEstimateValuationNewQuery($ownedCarId: ID!) {\n    ownedCar(id: $ownedCarId) {\n      id\n      makeName\n      carModelName\n      gradeName\n      firstRegistrationYear\n      ...CarEstimateValuationNew_useCarEstimateValuationNewForm_ownedCar\n    }\n  }\n": types.CarEstimateValuationNewQueryDocument,
    "\n  mutation useCarEstimateValuationNew_CreateCarEstimateValuationV2($input: CreateCarEstimateValuationV2Input!) {\n    createCarEstimateValuationV2(input: $input) {\n      carEstimateValuation {\n        id\n        ulid\n        ownedCarId\n      }\n    }\n  }\n": types.UseCarEstimateValuationNew_CreateCarEstimateValuationV2Document,
    "\n  fragment CarEstimateValuationNew_useCarEstimateValuationNewForm_ownedCar on OwnedCar {\n    id\n    makeName\n    carModelName\n    gradeName\n    firstRegistrationYear\n    odorStatus\n    odorStatusComment\n    accidentStatus\n    accidentComment\n    latestMileageKm\n    inspectionCertificateImage {\n      id\n      url\n    }\n    favoriteImage {\n      id\n      url\n    }\n  }\n": types.CarEstimateValuationNew_UseCarEstimateValuationNewForm_OwnedCarFragmentDoc,
    "\n  mutation CarOfficialValuationNew_useCarOfficialValuationNewMutation_EditCarOfficialValuation(\n    $input: EditCarOfficialValuationInput!\n  ) {\n    editCarOfficialValuation(input: $input) {\n      carOfficialValuation {\n        id\n        ulid\n        ownedCarId\n        ...CarOfficialValuationShared_carOfficialValuation\n      }\n    }\n  }\n": types.CarOfficialValuationNew_UseCarOfficialValuationNewMutation_EditCarOfficialValuationDocument,
    "\n  query CarOfficialValuationNew_useCarValuationNewQuery_CarOfficialValuation($ownedCarId: ID!, $ulid: String!) {\n    carOfficialValuation(ownedCarId: $ownedCarId, ulid: $ulid) {\n      id\n      ...CarOfficialValuationShared_carOfficialValuation\n      ownedCar {\n        id\n        ...CarOfficialValuationShared_ownedCar\n      }\n    }\n  }\n": types.CarOfficialValuationNew_UseCarValuationNewQuery_CarOfficialValuationDocument,
    "\n  query CarOfficialValuationNew_useCarOfficialValuationQuery_OwnedCar($ownedCarId: ID!) {\n    ownedCar(id: $ownedCarId) {\n      id\n      ...CarOfficialValuationShared_ownedCar\n    }\n  }\n": types.CarOfficialValuationNew_UseCarOfficialValuationQuery_OwnedCarDocument,
    "\n  mutation CarValuationNew_CarEstimateValuationForm_useCarEstimateValuationForm_CreateCarEstimateValuationV2(\n    $input: CreateCarEstimateValuationV2Input!\n  ) {\n    createCarEstimateValuationV2(input: $input) {\n      carEstimateValuation {\n        id\n        ulid\n        ownedCarId\n      }\n    }\n  }\n": types.CarValuationNew_CarEstimateValuationForm_UseCarEstimateValuationForm_CreateCarEstimateValuationV2Document,
    "\n  mutation CarValuationNew_CarOfficialValuationForm_useCarOfficialValuationMutation_EditCarOfficialValuation(\n    $input: EditCarOfficialValuationInput!\n  ) {\n    editCarOfficialValuation(input: $input) {\n      carOfficialValuation {\n        id\n        ulid\n        ownedCarId\n      }\n    }\n  }\n": types.CarValuationNew_CarOfficialValuationForm_UseCarOfficialValuationMutation_EditCarOfficialValuationDocument,
    "\n  fragment CarValuationNew_useCarValuationNewForm_carOfficialValuation on CarOfficialValuation {\n    id\n    pickupZipCode\n  }\n": types.CarValuationNew_UseCarValuationNewForm_CarOfficialValuationFragmentDoc,
    "\n  fragment CarValuationNew_useCarValuationNewForm_ownedCar on OwnedCar {\n    id\n    makeName\n    carModelName\n    gradeName\n    firstRegistrationYear\n    odorStatus\n    odorStatusComment\n    accidentStatus\n    accidentComment\n    latestMileageKm\n    inspectionCertificateImage {\n      id\n      url\n    }\n    favoriteImage {\n      id\n      url\n    }\n    ownedCarRepairFiles {\n      id\n      fileUrl\n      fileName\n      isPdf\n    }\n  }\n": types.CarValuationNew_UseCarValuationNewForm_OwnedCarFragmentDoc,
    "\n  query CarValuationNew_useCarValuationNewQuery_CarOfficialValuation($ownedCarId: ID!, $ulid: String!) {\n    carOfficialValuation(ownedCarId: $ownedCarId, ulid: $ulid) {\n      id\n      ...CarValuationNew_useCarValuationNewForm_carOfficialValuation\n      ownedCar {\n        id\n        makeName\n        carModelName\n        gradeName\n        firstRegistrationYear\n        ...CarValuationNew_useCarValuationNewForm_ownedCar\n      }\n    }\n  }\n": types.CarValuationNew_UseCarValuationNewQuery_CarOfficialValuationDocument,
    "\n  query CarValuationNew_useCarValuationNewQuery_OwnedCar($ownedCarId: ID!) {\n    ownedCar(id: $ownedCarId) {\n      id\n      makeName\n      carModelName\n      gradeName\n      firstRegistrationYear\n      ...CarValuationNew_useCarValuationNewForm_ownedCar\n    }\n  }\n": types.CarValuationNew_UseCarValuationNewQuery_OwnedCarDocument,
    "\n  mutation RequestDocument($input: RequestDocumentInput!) {\n    requestDocument(input: $input) {\n      isSuccess\n      errors {\n        message\n        path\n      }\n    }\n  }\n": types.RequestDocumentDocument,
    "\n  mutation UpdateUser($input: UpdateUserInput!) {\n    updateUser(input: $input) {\n      user {\n        id\n        zipCode\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n": types.UpdateUserDocument,
    "\n  query FavoritesIndexComponent($prefectureCode: Int) {\n    viewer {\n      id\n      favoritedCarStocks {\n        id\n        ulid\n        landTransportCost(prefectureCode: $prefectureCode)\n        ...SmallCarCard_carStock\n      }\n    }\n  }\n": types.FavoritesIndexComponentDocument,
    "\n  mutation CreateInflowSource($input: CreateInflowSourceInput!) {\n    createInflowSource(input: $input) {\n      isSuccess\n      errors {\n        message\n        path\n      }\n    }\n  }\n": types.CreateInflowSourceDocument,
    "\n  mutation InquireBuyingNewInquireBuying($input: InquireBuyingInput!) {\n    inquireBuying(input: $input) {\n      inquiry {\n        id\n        user {\n          id\n          lastName\n          zipCode\n          firstName\n        }\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n": types.InquireBuyingNewInquireBuyingDocument,
    "\n  mutation oneClickContract($input: CreateOneClickContractInput!) {\n    createOneClickContract(input: $input) {\n      errors {\n        message\n        path\n      }\n    }\n  }\n": types.OneClickContractDocument,
    "\n  query SearchConditionShow {\n    viewer {\n      id\n      lineAccount {\n        id\n        carStockSearchCondition {\n          id\n          minAmount\n          maxAmount\n          maxMileageKm\n          minCarModeYearBeforeYear\n          bodyTypeGroups {\n            id\n            bodyTypeGroup\n          }\n          searchConditionCarNames {\n            id\n            carModelName\n            makeName\n          }\n        }\n      }\n    }\n  }\n": types.SearchConditionShowDocument,
    "\n  query SearchConditionShow_CarStockNames($carModelNameKeyword: String, $makeNameKeyword: String) {\n    carStockNames(carModelNameKeyword: $carModelNameKeyword, makeNameKeyword: $makeNameKeyword) {\n      carModelNames\n      makeNames\n    }\n  }\n": types.SearchConditionShow_CarStockNamesDocument,
    "\n  mutation UpdateCarStockSearchCondition($input: UpdateCarStockSearchConditionInput!) {\n    updateCarStockSearchCondition(input: $input) {\n      carStockSearchCondition {\n        id\n        minAmount\n        maxAmount\n        maxMileageKm\n        minCarModeYearBeforeYear\n        bodyTypeGroups {\n          id\n          bodyTypeGroup\n        }\n        searchConditionCarNames {\n          id\n          carModelName\n          makeName\n        }\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n": types.UpdateCarStockSearchConditionDocument,
    "\n  mutation DestroyCarStockSearchCondition($input: DestroyCarStockSearchConditionInput!) {\n    destroyCarStockSearchCondition(input: $input) {\n      isSuccess\n      errors {\n        message\n        path\n      }\n    }\n  }\n": types.DestroyCarStockSearchConditionDocument,
    "\n  fragment SpecialShow_carStock on CarStock {\n    id\n    ...CarCardGroup_carStock\n  }\n": types.SpecialShow_CarStockFragmentDoc,
    "\n  query SpecialShowComponent(\n    $first: Int\n    $after: String\n    $salesStatus: CarSalesStatusEnum\n    $tagGroup: TagGroupEnum\n    $prefectureCode: Int\n  ) {\n    carStocks(first: $first, after: $after, salesStatus: $salesStatus, tagGroup: $tagGroup) {\n      edges {\n        node {\n          id\n          landTransportCost(prefectureCode: $prefectureCode)\n          ...SpecialShow_carStock\n        }\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n": types.SpecialShowComponentDocument,
    "\n  mutation createUserReviewBuyingAfterSale($input: CreateUserReviewBuyingAfterSaleInput!) {\n    createUserReviewBuyingAfterSale(input: $input) {\n      success\n    }\n  }\n": types.CreateUserReviewBuyingAfterSaleDocument,
    "\n  mutation createUserReviewBuyingContract($input: CreateUserReviewBuyingContractInput!) {\n    createUserReviewBuyingContract(input: $input) {\n      success\n    }\n  }\n": types.CreateUserReviewBuyingContractDocument,
    "\n  mutation createUserReview($input: CreateUserReviewInput!) {\n    createUserReview(input: $input) {\n      errors {\n        message\n        path\n      }\n    }\n  }\n": types.CreateUserReviewDocument,
    "\n  fragment CarIndex_CarMovies_carStock on CarStock {\n    id\n    video {\n      id\n      url\n      thumbnailUrl\n    }\n    ...CarMoviePopup_carStock\n  }\n": types.CarIndex_CarMovies_CarStockFragmentDoc,
    "\n  query ClosedCarStocksIndexComponent($first: Int, $salesStatus: CarSalesStatusEnum, $prefectureCode: Int) {\n    carStocks(first: $first, salesStatus: $salesStatus) {\n      edges {\n        node {\n          id\n          landTransportCost(prefectureCode: $prefectureCode)\n          ...CarsIndex_carStock\n        }\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n": types.ClosedCarStocksIndexComponentDocument,
    "\n  query HasVideoCarStocksIndexComponent($first: Int, $salesStatus: CarSalesStatusEnum, $hasVideo: Boolean) {\n    carStocks(first: $first, salesStatus: $salesStatus, hasVideo: $hasVideo) {\n      edges {\n        node {\n          id\n          ...CarsIndex_carStock\n        }\n      }\n    }\n  }\n": types.HasVideoCarStocksIndexComponentDocument,
    "\n  fragment CarsIndex_carStock on CarStock {\n    id\n    ...CarCardGroup_carStock\n    ...CarIndex_CarMovies_carStock\n    ...CarsSection_carStock\n  }\n": types.CarsIndex_CarStockFragmentDoc,
    "\n  fragment CarsSection_carStock on CarStock {\n    id\n    ...SmallCarCard_carStock\n  }\n": types.CarsSection_CarStockFragmentDoc,
    "\n  fragment CarCard_carStock on CarStock {\n    id\n    ulid\n    mileageKm\n    carModelName\n    gradeName\n    salesStatus\n    tags {\n      id\n      name\n    }\n    promotion {\n      id\n      catchCopy\n      staffComment\n      postedBy {\n        id\n        imageUrl\n      }\n    }\n    video {\n      id\n      url\n      thumbnailUrl\n    }\n  }\n": types.CarCard_CarStockFragmentDoc,
    "\n  fragment CarMovies_carStock on CarStock {\n    id\n    ...CarCard_carStock\n    ...CarMoviePopup_carStock\n  }\n": types.CarMovies_CarStockFragmentDoc,
    "\n  query CarsMoviesComponent(\n    $first: Int\n    $after: String\n    $hasVideo: Boolean\n    $salesStatus: CarSalesStatusEnum\n    $prefectureCode: Int\n  ) {\n    carStocks(first: $first, after: $after, hasVideo: $hasVideo, salesStatus: $salesStatus) {\n      edges {\n        node {\n          id\n          landTransportCost(prefectureCode: $prefectureCode)\n          ...CarMovies_carStock\n        }\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n": types.CarsMoviesComponentDocument,
    "\n  fragment CarsSearch_carStock on CarStock {\n    id\n    ...OneColumnCarCard_carStock\n    ...TwoColumnCarCard_carStock\n  }\n": types.CarsSearch_CarStockFragmentDoc,
    "\n  fragment OneColumnCarCard_carStock on CarStock {\n    id\n    ulid\n    carModelYear\n    mileageKm\n    makeName\n    carModelName\n    gradeName\n    totalPaymentAmount\n    salesStatus\n    publishedAt\n    imageUrls\n    oneClickContractCounts\n    landTransportCosts {\n      cost\n      prefectureCode\n    }\n    promotion {\n      id\n      catchCopy\n      staffComment\n      postedBy {\n        id\n        imageUrl\n      }\n    }\n  }\n": types.OneColumnCarCard_CarStockFragmentDoc,
    "\n  fragment TwoColumnCarCard_carStock on CarStock {\n    id\n    ulid\n    carModelYear\n    mileageKm\n    makeName\n    carModelName\n    gradeName\n    totalPaymentAmount\n    salesStatus\n    publishedAt\n    imageUrls\n    oneClickContractCounts\n    tags {\n      id\n      name\n    }\n    landTransportCosts {\n      cost\n      prefectureCode\n    }\n  }\n": types.TwoColumnCarCard_CarStockFragmentDoc,
    "\n  fragment CarEquipment_carStock on CarStock {\n    id\n    mainCarStockEquipments {\n      key\n      value\n      text\n    }\n    subCarStockEquipments {\n      active\n      text\n      group\n    }\n  }\n": types.CarEquipment_CarStockFragmentDoc,
    "\n  fragment CarFeature_carStock on CarStock {\n    id\n    carModelYear\n    mileageKm\n    inspectionExpiryDate\n    carStockDetail {\n      id\n      repairHistoryCategory\n    }\n    promotion {\n      id\n      catchCopy\n      description\n    }\n    dealerBranch {\n      id\n      prefectureName\n    }\n  }\n": types.CarFeature_CarStockFragmentDoc,
    "\n  fragment CarFooter_carStock on CarStock {\n    id\n    ulid\n    makeName\n    carModelName\n    gradeName\n    totalPaymentAmount\n    salesPrice\n    otherPrice\n    salesStatus\n    landTransportCosts {\n      cost\n      prefectureCode\n    }\n  }\n": types.CarFooter_CarStockFragmentDoc,
    "\n  fragment CarImages_carStock on CarStock {\n    id\n    images {\n      id\n      url\n      comment\n    }\n    video {\n      id\n      url\n      thumbnailUrl\n    }\n    ...CarMoviePopup_carStock\n  }\n": types.CarImages_CarStockFragmentDoc,
    "\n  fragment CarScore_carStock on CarStock {\n    id\n    vihicleConditionReportUrl\n    carStockDetail {\n      evaluationScore\n      interiorEvaluationScore\n    }\n    promotion {\n      id\n      staffComment\n      scores {\n        axis\n        score\n      }\n    }\n  }\n": types.CarScore_CarStockFragmentDoc,
    "\n  fragment CarSpec_carStock on CarStock {\n    id\n    slideDoorSpecText\n    carStockDetail {\n      seatingCapacity\n      displaySystematicColor\n      transmissionTypeGroup\n      engineDisplacement\n      fuelTypeText\n    }\n  }\n": types.CarSpec_CarStockFragmentDoc,
    "\n  fragment CarStaff_carStock on CarStock {\n    id\n    promotion {\n      id\n      postedBy {\n        id\n        lastName\n        imageUrl\n        comment\n        slug\n      }\n    }\n  }\n": types.CarStaff_CarStockFragmentDoc,
    "\n  query CarsListComponent(\n    $prefectureCode: Int\n    $first: Int\n    $after: String\n    $salesStatus: CarSalesStatusEnum\n    $bodyType: CarStockBodyTypeEnum\n  ) {\n    carStocks(first: $first, after: $after, salesStatus: $salesStatus, bodyType: $bodyType) {\n      edges {\n        node {\n          id\n          landTransportCost(prefectureCode: $prefectureCode)\n          ...CarCardGroup_carStock\n        }\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n": types.CarsListComponentDocument,
    "\n  fragment CarsShow_carStock on CarStock {\n    id\n    ulid\n    salesStatus\n    makeName\n    carModelName\n    carModelYear\n    bodyType\n    oneClickContractCounts\n    ...CarFeature_carStock\n    ...CarFooter_carStock\n    ...CarImages_carStock\n    ...CarScore_carStock\n    ...CarEquipment_carStock\n    ...CarStaff_carStock\n    ...CarSpec_carStock\n  }\n": types.CarsShow_CarStockFragmentDoc,
    "\n  mutation ViewCarStock($input: ViewCarStockInput!) {\n    viewCarStock(input: $input) {\n      errors {\n        message\n      }\n    }\n  }\n": types.ViewCarStockDocument,
    "\n  query InquiresNewComponent($ulids: [String!], $prefectureCode: Int) {\n    carStocks(ulids: $ulids) {\n      nodes {\n        id\n        landTransportCost(prefectureCode: $prefectureCode)\n        ...SmallCarCard_carStock\n      }\n    }\n  }\n": types.InquiresNewComponentDocument,
    "\n  mutation InquiresNewInquireCarStock($input: InquireCarStocksInput!) {\n    inquireCarStocks(input: $input) {\n      inquiry {\n        id\n        user {\n          id\n          firstName\n          lastName\n          zipCode\n          inquiries {\n            id\n            carStocks {\n              id\n            }\n          }\n        }\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n": types.InquiresNewInquireCarStockDocument,
    "\n  query ContentLinkShow($key: String!) {\n    contentLink(key: $key) {\n      id\n      contentable {\n        urlToRedirect\n        id\n      }\n    }\n  }\n": types.ContentLinkShowDocument,
    "\n  query MypageCallbackPage {\n    viewer {\n      id\n      lastName\n      firstName\n      publicDeals {\n        id\n      }\n    }\n  }\n": types.MypageCallbackPageDocument,
    "\n  mutation UpdateUserName_UserEdit($input: UpdateUserNameInput!) {\n    updateUserName(input: $input) {\n      user {\n        id\n        lastName\n        firstName\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n": types.UpdateUserName_UserEditDocument,
    "\n  fragment DealStatus_deal on Deal {\n    id\n    dealTasks {\n      id\n      taskType\n      completed\n    }\n    mypageDealTasks {\n      type\n      group\n      status\n    }\n    ...TaskList_deal\n  }\n": types.DealStatus_DealFragmentDoc,
    "\n  query MypageDealShowPage($dealId: ID!) {\n    deal(id: $dealId) {\n      id\n      user {\n        id\n        fullName\n      }\n      salesStaff {\n        id\n        fullName\n        imageUrl\n      }\n      ...NotificationCard_deal\n      ...OrderCar_deal\n      ...DealStatus_deal\n      ...InspectionCertificate_deal\n      ...PaymentCard_deal\n    }\n  }\n": types.MypageDealShowPageDocument,
    "\n  fragment InspectionCertificate_deal on Deal {\n    id\n    dealAttachments {\n      id\n      attachmentType\n      url\n    }\n  }\n": types.InspectionCertificate_DealFragmentDoc,
    "\n  fragment NotificationCard_deal on Deal {\n    id\n    mypageDealNotice\n  }\n": types.NotificationCard_DealFragmentDoc,
    "\n  fragment OrderCar_deal on Deal {\n    id\n    carStock {\n      id\n      carModelYear\n      mileageKm\n      carModelName\n      gradeName\n      makeName\n      images {\n        id\n        url\n      }\n    }\n    dealDetail {\n      id\n      deliveryDate\n      deliveryTimeRangeText\n    }\n  }\n": types.OrderCar_DealFragmentDoc,
    "\n  fragment PaymentCard_deal on Deal {\n    id\n    payment {\n      id\n      deadline\n      paymentMethod\n      amount\n      status\n    }\n    salesStaff {\n      id\n      dealerBranch {\n        id\n        bank {\n          bankName\n          accountType\n          accountNumber\n          accountName\n          branchCode\n          branchName\n        }\n      }\n    }\n  }\n": types.PaymentCard_DealFragmentDoc,
    "\n  fragment TaskList_deal on Deal {\n    id\n    dealContractDeadline\n    dealContractSentEmail\n    dealTasks {\n      id\n      taskType\n      completed\n    }\n    dealDetail {\n      id\n      expectedCarRegistrationCompletionDate\n      deliveryDate\n      deliveryTimeRangeText\n    }\n    mypageDealTasks {\n      type\n      group\n      status\n      title\n    }\n    dealContract {\n      id\n      userAuthenticationMethod\n      deadline\n      email\n      smsTel\n    }\n  }\n": types.TaskList_DealFragmentDoc,
    "\n  fragment OwnedCarEdit_ownedCar on OwnedCar {\n    id\n    color\n    odorStatus\n    odorStatusComment\n    isDirty\n    accidentStatus\n    accidentComment\n    hasMendHistory\n    mendComment\n    comment\n    inspectionCertificateImage {\n      id\n      url\n    }\n    options {\n      id\n      optionType\n    }\n  }\n": types.OwnedCarEdit_OwnedCarFragmentDoc,
    "\n  query OwnedCarEditQuery($id: ID!) {\n    ownedCar(id: $id) {\n      id\n      ...OwnedCarEdit_ownedCar\n    }\n  }\n": types.OwnedCarEditQueryDocument,
    "\n  query OwnedCarEdit_OwnedCarOptionOptions {\n    ownedCarOptionOptions {\n      optionType\n      optionTypeText\n    }\n  }\n": types.OwnedCarEdit_OwnedCarOptionOptionsDocument,
    "\n  mutation OwnedCarEdit_UpdateOwnedCar($input: UpdateOwnedCarInput!) {\n    updateOwnedCar(input: $input) {\n      ownedCar {\n        id\n        ...OwnedCarEdit_ownedCar\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n": types.OwnedCarEdit_UpdateOwnedCarDocument,
    "\n  fragment OwnedCarEditBase_ownedCar on OwnedCar {\n    id\n    makeName\n    carModelName\n    firstRegistrationYear\n    gradeName\n    carOrigin\n    driveSystem\n    latestMileageKm\n  }\n": types.OwnedCarEditBase_OwnedCarFragmentDoc,
    "\n  query OwnedCarEditBaseQuery($id: ID!) {\n    ownedCar(id: $id) {\n      id\n      ...OwnedCarEditBase_ownedCar\n    }\n  }\n": types.OwnedCarEditBaseQueryDocument,
    "\n  mutation OwnedCarEditBase_UpdateOwnedCar($input: UpdateOwnedCarInput!) {\n    updateOwnedCar(input: $input) {\n      ownedCar {\n        id\n        ...OwnedCarEditBase_ownedCar\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n": types.OwnedCarEditBase_UpdateOwnedCarDocument,
    "\n  fragment OwnedCarShow_CarBaseTable_ownedCar on OwnedCar {\n    id\n    makeName\n    carModelName\n    firstRegistrationYear\n    gradeName\n    editDisabled\n    carOriginText\n    driveSystemText\n    isProhibitUpdateFromUser\n    latestMileageKm\n  }\n": types.OwnedCarShow_CarBaseTable_OwnedCarFragmentDoc,
    "\n  fragment OwnedCarShow_CarCard_ownedCar on OwnedCar {\n    id\n    color\n    odorStatus\n    odorStatusText\n    odorStatusComment\n    isDirty\n    accidentStatus\n    accidentStatusText\n    accidentComment\n    hasMendHistory\n    mendComment\n    comment\n    editDisabled\n    isProhibitUpdateFromUser\n    inspectionCertificateImage {\n      id\n      url\n    }\n    options {\n      id\n      optionTypeText\n    }\n  }\n": types.OwnedCarShow_CarCard_OwnedCarFragmentDoc,
    "\n  fragment OwnedCarShow_CarImagesCard_ownedCar on OwnedCar {\n    id\n    editDisabled\n    isProhibitUpdateFromUser\n    imagesForIndex {\n      id\n      url\n      imageType\n    }\n  }\n": types.OwnedCarShow_CarImagesCard_OwnedCarFragmentDoc,
    "\n  query OwnedCarShow_CarImagesCard_OwnedCarImageTypes {\n    ownedCarImageTypes {\n      imageType\n      imageTypeText\n    }\n  }\n": types.OwnedCarShow_CarImagesCard_OwnedCarImageTypesDocument,
    "\n  query OwnedCarShow_CarImagesCard_OwnedCar($id: ID!) {\n    ownedCar(id: $id) {\n      id\n      imagesForIndex {\n        id\n        url\n        imageType\n      }\n    }\n  }\n": types.OwnedCarShow_CarImagesCard_OwnedCarDocument,
    "\n  mutation DeleteOwnedCar($input: DeleteOwnedCarInput!) {\n    deleteOwnedCar(input: $input) {\n      isSuccess\n    }\n  }\n": types.DeleteOwnedCarDocument,
    "\n  fragment OwnedCarShow_CurrentCarValuationButton_ownedCar on OwnedCar {\n    id\n    carEstimateValuations {\n      id\n      ulid\n      status\n      createdAt\n    }\n    carOfficialValuations {\n      id\n      ulid\n      status\n      createdAt\n    }\n  }\n": types.OwnedCarShow_CurrentCarValuationButton_OwnedCarFragmentDoc,
    "\n  query OwnedCarShow($id: ID!) {\n    ownedCar(id: $id) {\n      id\n      ...OwnedCarShow_CarBaseTable_ownedCar\n      ...CarDetailTable_ownedCar\n      ...OwnedCarShow_CarCard_ownedCar\n      ...OwnedCarShow_CarImagesCard_ownedCar\n      ...OwnedCarShow_CurrentCarValuationButton_ownedCar\n    }\n  }\n": types.OwnedCarShowDocument,
    "\n  query Viewer {\n    viewer {\n      id\n      fullName\n    }\n  }\n": types.ViewerDocument,
    "\n  query OwnedCars {\n    ownedCars {\n      id\n      makeName\n      carModelName\n      gradeName\n      firstRegistrationYear\n      images {\n        id\n        url\n        imageType\n      }\n      carEstimateValuations {\n        id\n        __typename\n        ulid\n        status\n        createdAt\n        expiredPriceValidDeadline\n      }\n      carOfficialValuations {\n        id\n        __typename\n        ulid\n        status\n        createdAt\n        expiredPriceValidDeadline\n        isAccepted\n        offers {\n          id\n          isAccepted\n        }\n      }\n    }\n  }\n": types.OwnedCarsDocument,
    "\n  fragment CarEstimateValuationEdit_BaseEdit_ownedCar on OwnedCar {\n    id\n    makeName\n    carModelName\n    gradeName\n    firstRegistrationYear\n  }\n": types.CarEstimateValuationEdit_BaseEdit_OwnedCarFragmentDoc,
    "\n  fragment CarEstimateValuationEdit_BaseEdit_carEstimateValuation on CarEstimateValuation {\n    id\n    ulid\n    ownedCar {\n      id\n      ...CarEstimateValuationEdit_BaseEdit_ownedCar\n    }\n  }\n": types.CarEstimateValuationEdit_BaseEdit_CarEstimateValuationFragmentDoc,
    "\n  mutation CarEstimateValuationEdit_BaseEdit_UpdateOwnedCar($input: UpdateOwnedCarInput!) {\n    updateOwnedCar(input: $input) {\n      ownedCar {\n        id\n        ...CarEstimateValuationEdit_BaseEdit_ownedCar\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n": types.CarEstimateValuationEdit_BaseEdit_UpdateOwnedCarDocument,
    "\n  query CarEstimateValuationEdit_BaseEditQuery($ownedCarId: ID!, $ulid: String!) {\n    carEstimateValuation(ownedCarId: $ownedCarId, ulid: $ulid) {\n      id\n      ...CarEstimateValuationEdit_BaseEdit_carEstimateValuation\n    }\n  }\n": types.CarEstimateValuationEdit_BaseEditQueryDocument,
    "\n  fragment CarEstimateValuationEdit_CarEdit_ownedCar on OwnedCar {\n    id\n    odorStatus\n    odorStatusText\n    odorStatusComment\n    accidentStatus\n    accidentStatusText\n    accidentComment\n    latestMileageKm\n    inspectionCertificateImage {\n      id\n      url\n    }\n  }\n": types.CarEstimateValuationEdit_CarEdit_OwnedCarFragmentDoc,
    "\n  fragment CarEstimateValuationEdit_CarEdit_carEstimateValuation on CarEstimateValuation {\n    id\n    ulid\n    ownedCar {\n      id\n      ...CarEstimateValuationEdit_CarEdit_ownedCar\n    }\n  }\n": types.CarEstimateValuationEdit_CarEdit_CarEstimateValuationFragmentDoc,
    "\n  mutation CarEstimateValuationEdit_CarEdit_UpdateOwnedCar($input: UpdateOwnedCarInput!) {\n    updateOwnedCar(input: $input) {\n      ownedCar {\n        id\n        ...CarEstimateValuationEdit_CarEdit_ownedCar\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n": types.CarEstimateValuationEdit_CarEdit_UpdateOwnedCarDocument,
    "\n  query CarEstimateValuationEdit_CarEditQuery($ownedCarId: ID!, $ulid: String!) {\n    carEstimateValuation(ownedCarId: $ownedCarId, ulid: $ulid) {\n      id\n      ...CarEstimateValuationEdit_CarEdit_carEstimateValuation\n    }\n  }\n": types.CarEstimateValuationEdit_CarEditQueryDocument,
    "\n  fragment CarEstimateValuationShow_AutoCompleteView_AishameterButton_carLog on CarLog {\n    id\n    makeName\n    carModelName\n    gradeName\n    firstRegistrationYear\n    minMileageKm\n    maxMileageKm\n    ownedCarId\n  }\n": types.CarEstimateValuationShow_AutoCompleteView_AishameterButton_CarLogFragmentDoc,
    "\n  fragment CarEstimateValuationShow_AutoCompleteView_carLog on CarLog {\n    id\n    makeName\n    carModelName\n    gradeName\n    firstRegistrationYear\n    minMileageKm\n    maxMileageKm\n  }\n": types.CarEstimateValuationShow_AutoCompleteView_CarLogFragmentDoc,
    "\n  fragment CarEstimateValuationShow_AutoCompleteView_OwnedCarEditModal_carEstimateValuation on CarEstimateValuation {\n    id\n    ownedCar {\n      id\n      makeName\n      carModelName\n      gradeName\n      firstRegistrationYear\n      editDisabled\n    }\n    carLog {\n      id\n      makeName\n      carModelName\n      gradeName\n      firstRegistrationYear\n    }\n  }\n": types.CarEstimateValuationShow_AutoCompleteView_OwnedCarEditModal_CarEstimateValuationFragmentDoc,
    "\n  fragment CarEstimateValuationShow_AutoCompleteView_carEstimateValuation on CarEstimateValuation {\n    id\n    ownedCarId\n    ...CardHeader_carEstimateValuation\n    ...CarEstimateValuationShow_AutoCompleteView_OwnedCarEditModal_carEstimateValuation\n    ...PriceView_carEstimateValuation\n    carLog {\n      id\n      ...CarEstimateValuationShow_AutoCompleteView_carLog\n      ...CarEstimateValuationShow_AutoCompleteView_AishameterButton_carLog\n    }\n    ownedCar {\n      id\n      isProhibitCreateCarOfficialValuation\n      ...OfficialValuationGuideView_ownedCar\n    }\n  }\n": types.CarEstimateValuationShow_AutoCompleteView_CarEstimateValuationFragmentDoc,
    "\n  mutation AutoCompleteView_OwnedCarEdit_UpdateOwnedCar($input: UpdateOwnedCarInput!) {\n    updateOwnedCar(input: $input) {\n      ownedCar {\n        id\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n": types.AutoCompleteView_OwnedCarEdit_UpdateOwnedCarDocument,
    "\n  fragment CarEstimateValuationShow_Body_carEstimateValuation on CarEstimateValuation {\n    id\n    ownedCarId\n    status\n    ...CarEstimateValuationShow_InProgressCard_carEstimateValuation\n    ...CarEstimateValuationShow_CarBaseCard_carEstimateValuation\n    ...CarEstimateValuationShow_CarCard_carEstimateValuation\n    ...CarEstimateValuationShow_CarFavoriteImageCard_carEstimateValuation\n    ...CarEstimateValuationShow_OtherCard_carEstimateValuation\n    ...CarEstimateValuationShow_CompleteCard_carEstimateValuation\n    ...CarEstimateValuationShow_CancelCard_carEstimateValuation\n    ownedCar {\n      id\n      makeName\n      carModelName\n      firstRegistrationYear\n      gradeName\n      ...OfficialValuationGuideView_ownedCar\n    }\n  }\n": types.CarEstimateValuationShow_Body_CarEstimateValuationFragmentDoc,
    "\n  fragment CarEstimateValuationShow_CancelCard_carEstimateValuation on CarEstimateValuation {\n    id\n    ownedCar {\n      id\n      ...OfficialValuationGuideView_ownedCar\n    }\n  }\n": types.CarEstimateValuationShow_CancelCard_CarEstimateValuationFragmentDoc,
    "\n  fragment CarEstimateValuationShow_CarBaseCard_carEstimateValuation on CarEstimateValuation {\n    id\n    ulid\n    ownedCar {\n      id\n      makeName\n      carModelName\n      firstRegistrationYear\n      gradeName\n      mainImage {\n        id\n        url\n      }\n    }\n  }\n": types.CarEstimateValuationShow_CarBaseCard_CarEstimateValuationFragmentDoc,
    "\n  fragment CarEstimateValuationShow_CarCard_carEstimateValuation on CarEstimateValuation {\n    id\n    ulid\n    ownedCar {\n      id\n      odorStatusText\n      odorStatusComment\n      accidentStatusText\n      accidentComment\n      latestMileageKm\n      inspectionCertificateImage {\n        id\n        url\n      }\n    }\n  }\n": types.CarEstimateValuationShow_CarCard_CarEstimateValuationFragmentDoc,
    "\n  query CarEstimateValuationShow($ownedCarId: ID!, $ulid: String!) {\n    carEstimateValuation(ownedCarId: $ownedCarId, ulid: $ulid) {\n      id\n      status\n      completedAuto\n      ...CarEstimateValuationShow_Body_carEstimateValuation\n      ...CarEstimateValuationShow_AutoCompleteView_carEstimateValuation\n    }\n  }\n": types.CarEstimateValuationShowDocument,
    "\n  fragment CarEstimateValuationShow_CarFavoriteImageCard_carEstimateValuation on CarEstimateValuation {\n    id\n    ownedCar {\n      id\n      favoriteImage {\n        id\n        url\n      }\n    }\n  }\n": types.CarEstimateValuationShow_CarFavoriteImageCard_CarEstimateValuationFragmentDoc,
    "\n  mutation CarEstimateValuationShow_CarFavoriteImageCard_UpdateOwnedCar($input: UpdateOwnedCarInput!) {\n    updateOwnedCar(input: $input) {\n      ownedCar {\n        id\n        favoriteImage {\n          id\n          url\n        }\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n": types.CarEstimateValuationShow_CarFavoriteImageCard_UpdateOwnedCarDocument,
    "\n  fragment CardHeader_carEstimateValuation on CarEstimateValuation {\n    id\n    createdAt\n    completedAt\n  }\n": types.CardHeader_CarEstimateValuationFragmentDoc,
    "\n  fragment CarEstimateValuationShow_CompleteCard_carEstimateValuation on CarEstimateValuation {\n    id\n    createdAt\n    ...PriceView_carEstimateValuation\n    ownedCar {\n      id\n      ...OfficialValuationGuideView_ownedCar\n    }\n  }\n": types.CarEstimateValuationShow_CompleteCard_CarEstimateValuationFragmentDoc,
    "\n  fragment CarEstimateValuationShow_InProgressCard_carEstimateValuation on CarEstimateValuation {\n    id\n    createdAt\n    ownedCar {\n      id\n      ...OfficialValuationGuideView_ownedCar\n    }\n  }\n": types.CarEstimateValuationShow_InProgressCard_CarEstimateValuationFragmentDoc,
    "\n  fragment OfficialValuationGuideView_ownedCar on OwnedCar {\n    id\n    isProhibitCreateCarOfficialValuation\n  }\n": types.OfficialValuationGuideView_OwnedCarFragmentDoc,
    "\n  fragment CarEstimateValuationShow_OtherCard_carEstimateValuation on CarEstimateValuation {\n    id\n    ownedCar {\n      id\n      carOriginText\n      driveSystemText\n      color\n      comment\n    }\n    detail {\n      id\n      reasonText\n      reasonComment\n      preferredPrice\n    }\n  }\n": types.CarEstimateValuationShow_OtherCard_CarEstimateValuationFragmentDoc,
    "\n  fragment PriceView_carEstimateValuation on CarEstimateValuation {\n    id\n    detail {\n      id\n      comment\n    }\n    prices {\n      id\n      minPrice\n      maxPrice\n      comment\n    }\n  }\n": types.PriceView_CarEstimateValuationFragmentDoc,
    "\n  fragment CarOfficialValuationEdit_BaseEdit_carOfficialValuation on CarOfficialValuation {\n    id\n    ulid\n    ownedCar {\n      id\n      ...CarOfficialValuationEdit_BaseEdit_useBaseEdit_ownedCar\n    }\n  }\n": types.CarOfficialValuationEdit_BaseEdit_CarOfficialValuationFragmentDoc,
    "\n  query CarOfficialValuationEdit_BaseEditQuery($ownedCarId: ID!, $ulid: String!) {\n    carOfficialValuation(ownedCarId: $ownedCarId, ulid: $ulid) {\n      id\n      ...CarOfficialValuationEdit_BaseEdit_carOfficialValuation\n    }\n  }\n": types.CarOfficialValuationEdit_BaseEditQueryDocument,
    "\n  fragment CarOfficialValuationEdit_BaseEdit_useBaseEdit_ownedCar on OwnedCar {\n    id\n    makeName\n    carModelName\n    gradeName\n    firstRegistrationYear\n  }\n": types.CarOfficialValuationEdit_BaseEdit_UseBaseEdit_OwnedCarFragmentDoc,
    "\n  mutation CarOfficialValuationEdit_BaseEdit_UpdateOwnedCar($input: UpdateOwnedCarInput!) {\n    updateOwnedCar(input: $input) {\n      ownedCar {\n        id\n        ...CarOfficialValuationEdit_BaseEdit_useBaseEdit_ownedCar\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n": types.CarOfficialValuationEdit_BaseEdit_UpdateOwnedCarDocument,
    "\n  fragment CarOfficialValuationEdit_Edit_carOfficialValuation on CarOfficialValuation {\n    id\n    ulid\n    ...CarOfficialValuationShared_carOfficialValuation\n  }\n": types.CarOfficialValuationEdit_Edit_CarOfficialValuationFragmentDoc,
    "\n  query CarOfficialValuationEdit_EditQuery($ownedCarId: ID!, $ulid: String!) {\n    carOfficialValuation(ownedCarId: $ownedCarId, ulid: $ulid) {\n      id\n      ...CarOfficialValuationEdit_Edit_carOfficialValuation\n    }\n  }\n": types.CarOfficialValuationEdit_EditQueryDocument,
    "\n  mutation CarOfficialValuationEdit_Edit_useEdit_EditCarOfficialValuation($input: EditCarOfficialValuationInput!) {\n    editCarOfficialValuation(input: $input) {\n      carOfficialValuation {\n        id\n        ...CarOfficialValuationShared_carOfficialValuation\n      }\n    }\n  }\n": types.CarOfficialValuationEdit_Edit_UseEdit_EditCarOfficialValuationDocument,
    "\n  mutation FindOrCreateCarOfficialValuation($input: FindOrCreateCarOfficialValuationInput!) {\n    findOrCreateCarOfficialValuation(input: $input) {\n      carOfficialValuation {\n        id\n        ulid\n      }\n      isCreate\n    }\n  }\n": types.FindOrCreateCarOfficialValuationDocument,
    "\n  fragment CarOfficialValuationImagesBody_carOfficialValuation on CarOfficialValuation {\n    id\n    ulid\n    ownedCar {\n      id\n      restRequiredImagesCount\n      fulfilledForCarOfficialValuationByImages\n    }\n  }\n": types.CarOfficialValuationImagesBody_CarOfficialValuationFragmentDoc,
    "\n  fragment CarOfficialValuationImages_DefectView_ImageTypesCard_ownedCarDefect on OwnedCarDefect {\n    id\n    defectType\n    ownedCarImageGroup {\n      groupKey\n      label\n      imageTypes {\n        imageType\n        imageTypeText\n        optional\n      }\n    }\n    ownedCarImages {\n      id\n      url\n      imageType\n      comment\n    }\n  }\n": types.CarOfficialValuationImages_DefectView_ImageTypesCard_OwnedCarDefectFragmentDoc,
    "\n  query CarOfficialValuationImages_DefectView_useImageTypesCard_OwnedCar($id: ID!) {\n    ownedCar(id: $id) {\n      id\n      restRequiredImagesCount\n      fulfilledForCarOfficialValuationByImages\n      imagesForIndex {\n        id\n        url\n        imageType\n        comment\n      }\n    }\n  }\n": types.CarOfficialValuationImages_DefectView_UseImageTypesCard_OwnedCarDocument,
    "\n  query CarOfficialValuationImages_DefectView_useMultipleCard_OwnedCar($id: ID!) {\n    ownedCar(id: $id) {\n      id\n      restRequiredImagesCount\n      fulfilledForCarOfficialValuationByImages\n      ownedCarDefects {\n        id\n        defectType\n        defectTypeText\n        ownedCarImages {\n          id\n          url\n          imageType\n          comment\n        }\n      }\n    }\n  }\n": types.CarOfficialValuationImages_DefectView_UseMultipleCard_OwnedCarDocument,
    "\n  fragment CarOfficialValuationImages_DefectView_ImagesView_ownedCar on OwnedCar {\n    id\n    ownedCarDefects {\n      id\n      defectType\n      defectTypeText\n      ...CarOfficialValuationImages_DefectView_ImageTypesCard_ownedCarDefect\n      ownedCarImageGroup {\n        label\n        imageTypes {\n          imageType\n        }\n      }\n    }\n  }\n": types.CarOfficialValuationImages_DefectView_ImagesView_OwnedCarFragmentDoc,
    "\n  query CarOfficialValuationImages_DefectView_ownedCar($id: ID!) {\n    ownedCar(id: $id) {\n      id\n      restRequiredImagesCount\n      fulfilledForCarOfficialValuationByImages\n      ...CarOfficialValuationImages_DefectView_ImagesView_ownedCar\n      ownedCarDefects {\n        id\n        defectType\n      }\n    }\n  }\n": types.CarOfficialValuationImages_DefectView_OwnedCarDocument,
    "\n  mutation CarOfficialValuationImages_DefectView_useOwnedCarDefect_EditOwnedCarDefects(\n    $input: EditOwnedCarDefectsInput!\n  ) {\n    editOwnedCarDefects(input: $input) {\n      ownedCar {\n        id\n      }\n    }\n  }\n": types.CarOfficialValuationImages_DefectView_UseOwnedCarDefect_EditOwnedCarDefectsDocument,
    "\n  query CarOfficialValuationImages_DefectView_RefetchQuery($ownedCarId: ID!) {\n    ownedCar(id: $ownedCarId) {\n      id\n      ownedCarDefects {\n        id\n        defectType\n        ownedCarImageGroup {\n          label\n          imageTypes {\n            imageType\n            imageTypeText\n            optional\n          }\n        }\n      }\n    }\n  }\n": types.CarOfficialValuationImages_DefectView_RefetchQueryDocument,
    "\n  mutation CommentEditModal_UpdateOwnedCarImage($input: UpdateOwnedCarImageInput!) {\n    updateOwnedCarImage(input: $input) {\n      ownedCarImage {\n        id\n        comment\n      }\n    }\n  }\n": types.CommentEditModal_UpdateOwnedCarImageDocument,
    "\n  mutation CarOfficialValuationImages_ImagePreviewDrawer_useImagePreviewDrawer_DestroyOwnedCarImage(\n    $input: DestroyOwnedCarImageInput!\n  ) {\n    destroyOwnedCarImage(input: $input) {\n      id\n      isDestroyed\n      errors {\n        message\n        path\n      }\n    }\n  }\n": types.CarOfficialValuationImages_ImagePreviewDrawer_UseImagePreviewDrawer_DestroyOwnedCarImageDocument,
    "\n  fragment CarOfficialValuationImages_ImageTypeImageUploadModal_Camera_Footer_OwnedCarImageType on OwnedCarImageType {\n    imageType\n    groupKey\n  }\n": types.CarOfficialValuationImages_ImageTypeImageUploadModal_Camera_Footer_OwnedCarImageTypeFragmentDoc,
    "\n  query CarOfficialValuationImages_CameraModal_Camera_Footer_OwnedCar($id: ID!) {\n    ownedCar(id: $id) {\n      id\n      imageGroupCounts {\n        id\n        groupKey\n        label\n        restCount\n      }\n    }\n  }\n": types.CarOfficialValuationImages_CameraModal_Camera_Footer_OwnedCarDocument,
    "\n  fragment CarOfficialValuationImages_ImageTypeImageUploadModal_Camera_OwnedCarImageType on OwnedCarImageType {\n    ...CarOfficialValuationImages_ImageTypeImageUploadModal_Camera_Footer_OwnedCarImageType\n  }\n": types.CarOfficialValuationImages_ImageTypeImageUploadModal_Camera_OwnedCarImageTypeFragmentDoc,
    "\n  fragment CarOfficialValuationImages_ImageTypeImageUploadModal_OwnedCarImageType on OwnedCarImageType {\n    imageType\n    optional\n  }\n": types.CarOfficialValuationImages_ImageTypeImageUploadModal_OwnedCarImageTypeFragmentDoc,
    "\n  query CarOfficialValuationImages_ImageTypeImageUploadModal_OwnedCarImageTypes {\n    ownedCarImageTypes {\n      ...CarOfficialValuationImages_ImageTypeImageUploadModal_OwnedCarImageType\n      ...CarOfficialValuationImages_ImageTypeImageUploadModal_Camera_OwnedCarImageType\n    }\n  }\n": types.CarOfficialValuationImages_ImageTypeImageUploadModal_OwnedCarImageTypesDocument,
    "\n  mutation CarOfficialValuationImage_useOwnedCarImage_EditOwnedCarImage($input: EditOwnedCarImageInput!) {\n    editOwnedCarImage(input: $input) {\n      ownedCarImage {\n        id\n        url\n        imageType\n        nextImageType\n        ownedCar {\n          id\n          restRequiredImagesCount\n          fulfilledForCarOfficialValuationByImages\n          imageGroupCounts {\n            id\n            restCount\n          }\n        }\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n": types.CarOfficialValuationImage_UseOwnedCarImage_EditOwnedCarImageDocument,
    "\n  mutation CarOfficialValuationImages_MultipleUploadModal_createOwnedCarImages($input: CreateOwnedCarImagesInput!) {\n    createOwnedCarImages(input: $input) {\n      ownedCarImages {\n        id\n        imageType\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n": types.CarOfficialValuationImages_MultipleUploadModal_CreateOwnedCarImagesDocument,
    "\n  mutation CarOfficialValuationImages_MultipleUploadModal_createOwnedCarImage($input: CreateOwnedCarImageInput!) {\n    createOwnedCarImage(input: $input) {\n      ownedCarImage {\n        id\n        imageType\n      }\n    }\n  }\n": types.CarOfficialValuationImages_MultipleUploadModal_CreateOwnedCarImageDocument,
    "\n  query CarOfficialValuationImages_useNormalView_ImageTypes($groupKey: OwnedCarImageGroupEnum!) {\n    ownedCarImageGroup(groupKey: $groupKey) {\n      groupKey\n      label\n      imageTypes {\n        imageTypeText\n        imageType\n      }\n    }\n  }\n": types.CarOfficialValuationImages_UseNormalView_ImageTypesDocument,
    "\n  query CarOfficialValuationImages_useNormalView_OwnedCar($id: ID!) {\n    ownedCar(id: $id) {\n      id\n      restRequiredImagesCount\n      fulfilledForCarOfficialValuationByImages\n      imagesForIndex {\n        id\n        url\n        imageType\n        comment\n      }\n    }\n  }\n": types.CarOfficialValuationImages_UseNormalView_OwnedCarDocument,
    "\n  query CarOfficialValuationImages_OptionalView_useImageTypesView_ImageTypes($groupKey: OwnedCarImageGroupEnum!) {\n    ownedCarImageGroup(groupKey: $groupKey) {\n      groupKey\n      label\n      imageTypes {\n        imageTypeText\n        imageType\n      }\n    }\n  }\n": types.CarOfficialValuationImages_OptionalView_UseImageTypesView_ImageTypesDocument,
    "\n  query CarOfficialValuationImages_OptionalView_useImageTypesView_OwnedCar($id: ID!) {\n    ownedCar(id: $id) {\n      id\n      imagesForIndex {\n        id\n        url\n        imageType\n        comment\n      }\n    }\n  }\n": types.CarOfficialValuationImages_OptionalView_UseImageTypesView_OwnedCarDocument,
    "\n  query CarOfficialValuationImages_OptionalView_useOtherView_OwnedCar($id: ID!) {\n    ownedCar(id: $id) {\n      id\n      otherImages {\n        id\n        url\n        imageType\n        comment\n      }\n    }\n  }\n": types.CarOfficialValuationImages_OptionalView_UseOtherView_OwnedCarDocument,
    "\n  query CarOfficialValuationImages_CarOfficialValuation($ownedCarId: ID!, $ulid: String!) {\n    carOfficialValuation(ownedCarId: $ownedCarId, ulid: $ulid) {\n      id\n      ownedCar {\n        id\n      }\n      ...CarOfficialValuationImagesBody_carOfficialValuation\n    }\n  }\n": types.CarOfficialValuationImages_CarOfficialValuationDocument,
    "\n  query CarOfficialValuationShow($ownedCarId: ID!, $ulid: String!) {\n    carOfficialValuation(ownedCarId: $ownedCarId, ulid: $ulid) {\n      id\n      status\n      ...CarOfficialValuationShow_PreparingView_carOfficialValuation\n      ...CarOfficialValuationShow_NomalView_carOfficialValuation\n    }\n  }\n": types.CarOfficialValuationShowDocument,
    "\n  fragment CarOfficialValuationShow_CancelView_CarOfficialValuation on CarOfficialValuation {\n    id\n    ...OfferList_carOfficialValuation\n    ownedCar {\n      id\n      isProhibitCreateCarOfficialValuation\n    }\n  }\n": types.CarOfficialValuationShow_CancelView_CarOfficialValuationFragmentDoc,
    "\n  fragment CarOfficialValuationShow_NomalView_CarBaseContent_carOfficialValuation on CarOfficialValuation {\n    id\n    ulid\n    ownedCar {\n      id\n      makeName\n      carModelName\n      firstRegistrationYear\n      gradeName\n      mainImage {\n        id\n        url\n      }\n    }\n  }\n": types.CarOfficialValuationShow_NomalView_CarBaseContent_CarOfficialValuationFragmentDoc,
    "\n  fragment CarOfficialValuationShow_NomalView_CommentContent_carOfficialValuation on CarOfficialValuation {\n    id\n    ownedCar {\n      id\n      comment\n    }\n  }\n": types.CarOfficialValuationShow_NomalView_CommentContent_CarOfficialValuationFragmentDoc,
    "\n  fragment CarOfficialValuationShow_CompletedView_CarOfficialValuation on CarOfficialValuation {\n    id\n    expiredPriceValidDeadline\n    valuationType\n    ...CarOfficialValuationShow_CancelButton_CarOfficialValuation\n    ...OfferList_carOfficialValuation\n  }\n": types.CarOfficialValuationShow_CompletedView_CarOfficialValuationFragmentDoc,
    "\n  fragment CarOfficialValuationShow_ConfirmingView_CarOfficialValuation on CarOfficialValuation {\n    id\n    ...CarOfficialValuationShow_CancelButton_CarOfficialValuation\n  }\n": types.CarOfficialValuationShow_ConfirmingView_CarOfficialValuationFragmentDoc,
    "\n  fragment CarOfficialValuation_InProgressView_carOfficialValuation on CarOfficialValuation {\n    id\n    ...CarOfficialValuationShow_CancelButton_CarOfficialValuation\n  }\n": types.CarOfficialValuation_InProgressView_CarOfficialValuationFragmentDoc,
    "\n  fragment CarOfficialValuationShow_NomalView_carOfficialValuation on CarOfficialValuation {\n    id\n    ulid\n    status\n    requestedAt\n    ...CarOfficialValuationShow_NomalView_CarBaseContent_carOfficialValuation\n    ...CarOfficialValuationShow_CarContent_carOfficialValuation\n    ...CarOfficialValuationShow_CancelView_CarOfficialValuation\n    ...CarOfficialValuation_InProgressView_carOfficialValuation\n    ...CarOfficialValuationShow_ConfirmingView_CarOfficialValuation\n    ...CarOfficialValuationShow_CompletedView_CarOfficialValuation\n    ...CarOfficialValuationShow_NomalView_CommentContent_carOfficialValuation\n    ownedCar {\n      id\n      ...CarOfficialValuationShow_CarImagesContent_ownedCar\n    }\n  }\n": types.CarOfficialValuationShow_NomalView_CarOfficialValuationFragmentDoc,
    "\n  fragment CarOfficialValuationShow_CancelButton_CarOfficialValuation on CarOfficialValuation {\n    id\n    status\n  }\n": types.CarOfficialValuationShow_CancelButton_CarOfficialValuationFragmentDoc,
    "\n  mutation CarOfficialValuationShow_CancelButton_CancelCarOfficialValuation($input: CancelCarOfficialValuationInput!) {\n    cancelCarOfficialValuation(input: $input) {\n      carOfficialValuation {\n        id\n        status\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n": types.CarOfficialValuationShow_CancelButton_CancelCarOfficialValuationDocument,
    "\n  mutation CarOfficialValuationShow_CreateOfficialValuationButton_CreateCarOfficialValuation(\n    $input: CreateCarOfficialValuationInput!\n  ) {\n    createCarOfficialValuation(input: $input) {\n      carOfficialValuation {\n        id\n        ulid\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n": types.CarOfficialValuationShow_CreateOfficialValuationButton_CreateCarOfficialValuationDocument,
    "\n  fragment OfferList_carOfficialValuation on CarOfficialValuation {\n    id\n    ulid\n    isOfferAcceptable\n    createOfferDeadline\n    priceValidDeadline\n    status\n    offers {\n      id\n      proposedPrice\n      bringInProposedPrice\n      acceptPriceType\n      createdAt\n      comment\n      deliveryAcceptDeadline\n      expiredDeliveryAcceptDeadline\n      bringInAcceptDeadline\n      expiredBringInAcceptDeadline\n      dealerBranch {\n        id\n        nameForValuation\n      }\n    }\n  }\n": types.OfferList_CarOfficialValuationFragmentDoc,
    "\n  fragment CarOfficialValuationShow_PreparingView_BaseCard_ownedCar on OwnedCar {\n    id\n    makeName\n    carModelName\n    gradeName\n    firstRegistrationYear\n  }\n": types.CarOfficialValuationShow_PreparingView_BaseCard_OwnedCarFragmentDoc,
    "\n  fragment CarOfficialValuationShow_PreparingView_CarCard_carOfficialValuation on CarOfficialValuation {\n    id\n    ulid\n    ownedCarId\n    isFulfilledByOtherImages\n    ...CarOfficialValuationShow_CarContent_carOfficialValuation\n  }\n": types.CarOfficialValuationShow_PreparingView_CarCard_CarOfficialValuationFragmentDoc,
    "\n  fragment CarOfficialValuationShow_PreparingView_CarImagesCard_carOfficialValuation on CarOfficialValuation {\n    id\n    ulid\n    ownedCarId\n    isFulfilledByImages\n    ownedCar {\n      id\n      imageGroupCounts {\n        id\n        groupKey\n        restCount\n      }\n      ...CarOfficialValuationShow_CarImagesContent_ownedCar\n    }\n  }\n": types.CarOfficialValuationShow_PreparingView_CarImagesCard_CarOfficialValuationFragmentDoc,
    "\n  mutation CarOfficialValuationShow_PreparingView_updateOwnedCar($input: UpdateOwnedCarInput!) {\n    updateOwnedCar(input: $input) {\n      ownedCar {\n        id\n        ...CarOfficialValuationShow_PreparingView_usePreparingViewForm_ownedCar\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n": types.CarOfficialValuationShow_PreparingView_UpdateOwnedCarDocument,
    "\n  fragment CarOfficialValuationShow_PreparingView_carOfficialValuation on CarOfficialValuation {\n    id\n    ulid\n    isFulfilled\n    ...CarOfficialValuationShow_PreparingView_CarCard_carOfficialValuation\n    ...CarOfficialValuationShow_PreparingView_CarImagesCard_carOfficialValuation\n    ...CarOfficialValuationShow_PreparingView_useCarOfficialValuation_carOfficialValuation\n    ownedCar {\n      id\n      ...CarOfficialValuationShow_PreparingView_BaseCard_ownedCar\n      ...CarOfficialValuationShow_PreparingView_usePreparingViewForm_ownedCar\n    }\n  }\n": types.CarOfficialValuationShow_PreparingView_CarOfficialValuationFragmentDoc,
    "\n  fragment CarOfficialValuationShow_PreparingView_useCarOfficialValuation_carOfficialValuation on CarOfficialValuation {\n    id\n    ulid\n  }\n": types.CarOfficialValuationShow_PreparingView_UseCarOfficialValuation_CarOfficialValuationFragmentDoc,
    "\n  mutation CarOfficialValuationShow_PreparingView_useCarOfficialValuation_requestCarOfficialValuation(\n    $input: RequestCarOfficialValuationInput!\n  ) {\n    requestCarOfficialValuation(input: $input) {\n      carOfficialValuation {\n        id\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n": types.CarOfficialValuationShow_PreparingView_UseCarOfficialValuation_RequestCarOfficialValuationDocument,
    "\n  fragment CarOfficialValuationShow_PreparingView_usePreparingViewForm_ownedCar on OwnedCar {\n    id\n    comment\n  }\n": types.CarOfficialValuationShow_PreparingView_UsePreparingViewForm_OwnedCarFragmentDoc,
    "\n  fragment CarOfficialValuationShow_CarContent_carOfficialValuation on CarOfficialValuation {\n    id\n    pickupZipCode\n    pickupZipCodeObject {\n      prefecture\n      city\n      town\n    }\n    ownedCar {\n      id\n      odorStatusText\n      odorStatusComment\n      accidentStatusText\n      accidentComment\n      latestMileageKm\n      inspectionCertificateImage {\n        id\n        url\n      }\n      ownedCarRepairFiles {\n        id\n        fileUrl\n        fileName\n        isPdf\n      }\n    }\n  }\n": types.CarOfficialValuationShow_CarContent_CarOfficialValuationFragmentDoc,
    "\n  fragment CarOfficialValuationShow_CarImagesContent_ownedCar on OwnedCar {\n    id\n    images {\n      id\n      url\n    }\n  }\n": types.CarOfficialValuationShow_CarImagesContent_OwnedCarFragmentDoc,
    "\n  query CarValuationOffer_NoticeCard_CarOfficialValuation($ownedCarId: ID!, $ulid: String!) {\n    carOfficialValuation(ownedCarId: $ownedCarId, ulid: $ulid) {\n      id\n      status\n    }\n  }\n": types.CarValuationOffer_NoticeCard_CarOfficialValuationDocument,
    "\n  query CarValuationOffer_Term_CarOfficialValuation($ownedCarId: ID!, $ulid: String!) {\n    carOfficialValuation(ownedCarId: $ownedCarId, ulid: $ulid) {\n      id\n      status\n    }\n  }\n": types.CarValuationOffer_Term_CarOfficialValuationDocument,
    "\n  mutation CarValuationOffer_Term_AcceptCarValuationOffer($input: AcceptCarValuationOfferInput!) {\n    acceptCarValuationOffer(input: $input) {\n      carValuationOffer {\n        id\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n": types.CarValuationOffer_Term_AcceptCarValuationOfferDocument,
    "\n  query CarValuationList($id: ID!) {\n    ownedCar(id: $id) {\n      id\n      carEstimateValuations {\n        id\n        status\n        ulid\n        expiredPriceValidDeadline\n        priceValidDeadline\n        createdAt\n        completedAt\n        completedAuto\n        carLog {\n          id\n          mileageKm\n        }\n        prices {\n          id\n          minPrice\n          maxPrice\n          sortOrder\n        }\n      }\n      carOfficialValuations {\n        id\n        status\n        ulid\n        proposedPrice\n        expiredPriceValidDeadline\n        priceValidDeadline\n        createdAt\n        completedAt\n        offers {\n          id\n          proposedPrice\n          acceptedPrice\n          isAccepted\n        }\n      }\n    }\n  }\n": types.CarValuationListDocument,
    "\n  fragment CarBaseTable_ownedCar on OwnedCar {\n    id\n    makeName\n    carModelName\n    firstRegistrationYear\n    gradeName\n    carOriginText\n    driveSystemText\n    latestMileageKm\n  }\n": types.CarBaseTable_OwnedCarFragmentDoc,
    "\n  fragment CarDetailTable_ownedCar on OwnedCar {\n    id\n    color\n    odorStatus\n    odorStatusText\n    odorStatusComment\n    isDirty\n    accidentStatus\n    accidentStatusText\n    accidentComment\n    hasMendHistory\n    mendComment\n    comment\n    latestMileageKm\n    inspectionCertificateImage {\n      id\n      url\n    }\n    options {\n      id\n      optionTypeText\n    }\n  }\n": types.CarDetailTable_OwnedCarFragmentDoc,
    "\n  fragment CarDetailTable_carLog on CarLog {\n    id\n    color\n    odorStatus\n    odorStatusText\n    odorStatusComment\n    isDirty\n    accidentStatus\n    accidentStatusText\n    accidentComment\n    hasMendHistory\n    mendComment\n    comment\n    mileageKm\n    inspectionCertificateImage {\n      id\n      url\n    }\n    options {\n      id\n      optionTypeText\n    }\n  }\n": types.CarDetailTable_CarLogFragmentDoc,
    "\n  query CarDetailTable_Viewer {\n    viewer {\n      id\n      fullName\n      zipCode\n      zipCodeAddress {\n        id\n        prefecture\n        city\n      }\n    }\n  }\n": types.CarDetailTable_ViewerDocument,
    "\n  query useImageTake_OwnedCarImageTypes {\n    ownedCarImageTypes {\n      imageType\n      imageTypeText\n      optional\n    }\n  }\n": types.UseImageTake_OwnedCarImageTypesDocument,
    "\n  query useImageTake_OwnedCar($id: ID!) {\n    ownedCar(id: $id) {\n      id\n      imagesForIndex {\n        id\n        url\n        imageType\n      }\n    }\n  }\n": types.UseImageTake_OwnedCarDocument,
    "\n  mutation useImageTake_EditOwnedCarImage($input: EditOwnedCarImageInput!) {\n    editOwnedCarImage(input: $input) {\n      ownedCarImage {\n        id\n        url\n        imageType\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n": types.UseImageTake_EditOwnedCarImageDocument,
    "\n  query OwnedCarImages_OwnedCarImageTypes {\n    ownedCarImageTypes {\n      imageType\n      imageTypeText\n    }\n  }\n": types.OwnedCarImages_OwnedCarImageTypesDocument,
    "\n  query OwnedCarImages_OwnedCar($id: ID!) {\n    ownedCar(id: $id) {\n      id\n      imagesForIndex {\n        id\n        url\n        imageType\n      }\n    }\n  }\n": types.OwnedCarImages_OwnedCarDocument,
    "\n  mutation OwnedCarImages_createOwnedCarImages($input: CreateOwnedCarImagesInput!) {\n    createOwnedCarImages(input: $input) {\n      ownedCarImages {\n        id\n        imageType\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n": types.OwnedCarImages_CreateOwnedCarImagesDocument,
    "\n  query OwnedCarSelectCarEstimateValuation_OwnedCarsQuery {\n    ownedCars {\n      id\n      ...OwnedCarSelect_OwnedCarCard_ownedCar\n    }\n  }\n": types.OwnedCarSelectCarEstimateValuation_OwnedCarsQueryDocument,
    "\n  mutation OwnedCarSelectCarEstimateValuation_CompleteAutoCarEstimateValuation(\n    $input: CompleteAutoCarEstimateValuationInput!\n  ) {\n    completeAutoCarEstimateValuation(input: $input) {\n      carEstimateValuation {\n        id\n        ulid\n        ownedCarId\n      }\n    }\n  }\n": types.OwnedCarSelectCarEstimateValuation_CompleteAutoCarEstimateValuationDocument,
    "\n  fragment OwnedCarSelect_OwnedCarCard_ownedCar on OwnedCar {\n    id\n    makeName\n    carModelName\n    firstRegistrationYear\n    gradeName\n    latestMileageKm\n    imagesForIndex {\n      id\n      url\n      imageType\n    }\n  }\n": types.OwnedCarSelect_OwnedCarCard_OwnedCarFragmentDoc,
    "\n  fragment SellingDealReservationComplete_BringInBody_SellingDealReservation on SellingDealReservation {\n    id\n    bringInDealerBranchOptions {\n      label\n      value\n    }\n    bringInReservationData {\n      lastName\n      firstName\n      lastNameKana\n      firstNameKana\n      phoneNumber\n      documentMailZipCode\n      documentMailFirstHalfAddress\n      documentMailSecondHalfAddress\n      dealerBranch\n      firstPreferredDate\n      firstPreferredMinute\n      secondPreferredDate\n      secondPreferredMinute\n    }\n  }\n": types.SellingDealReservationComplete_BringInBody_SellingDealReservationFragmentDoc,
    "\n  fragment SellingDealReservationComplete_SellingDealReservation on SellingDealReservation {\n    id\n    deliveryReservationData {\n      lastName\n      firstName\n      lastNameKana\n      firstNameKana\n      phoneNumber\n      deliveryZipCode\n      deliveryFirstHalfAddress\n      deliverySecondHalfAddress\n      isSameDocumentMailAddress\n      documentMailZipCode\n      documentMailFirstHalfAddress\n      documentMailSecondHalfAddress\n      firstPreferredDate\n      displayFirstPreferredTime\n      secondPreferredDate\n      displaySecondPreferredTime\n      thirdPreferredDate\n      displayThirdPreferredTime\n    }\n  }\n": types.SellingDealReservationComplete_SellingDealReservationFragmentDoc,
    "\n  fragment SellingDealReservationComplete_DismantlingBody_SellingDealReservation on SellingDealReservation {\n    id\n    dismantlingReservationData {\n      lastName\n      firstName\n      lastNameKana\n      firstNameKana\n      phoneNumber\n      email\n      deliveryZipCode\n      deliveryFirstHalfAddress\n      deliverySecondHalfAddress\n      isSameDocumentMailAddress\n      documentMailZipCode\n      documentMailFirstHalfAddress\n      documentMailSecondHalfAddress\n      bankName\n      branchName\n      displayAccountType\n      accountNumber\n      accountHolder\n    }\n  }\n": types.SellingDealReservationComplete_DismantlingBody_SellingDealReservationFragmentDoc,
    "\n  query SellingDealReservationComplete_SellingDealReservation($ownedCarId: ID!, $sellingDealReservationId: ID!) {\n    sellingDealReservation(ownedCarId: $ownedCarId, sellingDealReservationId: $sellingDealReservationId) {\n      id\n      reservationType\n      isCompleted\n      ...SellingDealReservationComplete_SellingDealReservation\n      ...SellingDealReservationComplete_BringInBody_SellingDealReservation\n      ...SellingDealReservationComplete_DismantlingBody_SellingDealReservation\n    }\n  }\n": types.SellingDealReservationComplete_SellingDealReservationDocument,
    "\n  fragment SellingDealReservationEdit_BringInConfirm_SellingDealReservation on SellingDealReservation {\n    id\n    bringInDealerBranchOptions {\n      label\n      value\n    }\n  }\n": types.SellingDealReservationEdit_BringInConfirm_SellingDealReservationFragmentDoc,
    "\n  mutation UpdateSellingDealReservationBringIn($input: UpdateSellingDealReservationBringInInput!) {\n    updateSellingDealReservationBringIn(input: $input) {\n      sellingDealReservation {\n        id\n        displayReservationData\n      }\n    }\n  }\n": types.UpdateSellingDealReservationBringInDocument,
    "\n  fragment SellingDealReservationEdit_DealerBranchSelect_SellingDealReservation on SellingDealReservation {\n    id\n    bringInDealerBranchOptions {\n      label\n      value\n    }\n  }\n": types.SellingDealReservationEdit_DealerBranchSelect_SellingDealReservationFragmentDoc,
    "\n  fragment SellingDealReservationEdit_BringInForm_FormView_SellingDealReservation on SellingDealReservation {\n    id\n    ...SellingDealReservationEdit_DealerBranchSelect_SellingDealReservation\n    ...SellingDealReservationEdit_BringInForm_PreferredDateInput_SellingDealReservation\n  }\n": types.SellingDealReservationEdit_BringInForm_FormView_SellingDealReservationFragmentDoc,
    "\n  fragment SellingDealReservationEdit_BringInForm_PreferredDateInput_SellingDealReservation on SellingDealReservation {\n    id\n    carOfficialValuation {\n      id\n      priceValidDeadline\n    }\n    ...SellingDealReservationEdit_TimeSelect_SellingDealReservation\n  }\n": types.SellingDealReservationEdit_BringInForm_PreferredDateInput_SellingDealReservationFragmentDoc,
    "\n  fragment SellingDealReservationEdit_BringInForm_SellingDealReservation on SellingDealReservation {\n    id\n    carOfficialValuation {\n      id\n      pickupZipCode\n      pickupZipCodeObject {\n        prefecture\n        city\n        town\n      }\n    }\n    ...SellingDealReservationEdit_BringInForm_FormView_SellingDealReservation\n    ...SellingDealReservationEdit_BringInConfirm_SellingDealReservation\n  }\n": types.SellingDealReservationEdit_BringInForm_SellingDealReservationFragmentDoc,
    "\n  fragment SellingDealReservationEdit_DeliveryConfirm_SellingDealReservation on SellingDealReservation {\n    id\n    deliveryPreferredTimeOptions {\n      label\n      value\n    }\n  }\n": types.SellingDealReservationEdit_DeliveryConfirm_SellingDealReservationFragmentDoc,
    "\n  mutation UpdateSellingDealReservationDelivery($input: UpdateSellingDealReservationDeliveryInput!) {\n    updateSellingDealReservationDelivery(input: $input) {\n      sellingDealReservation {\n        id\n        displayReservationData\n      }\n    }\n  }\n": types.UpdateSellingDealReservationDeliveryDocument,
    "\n  fragment SellingDealReservationEdit_DeliveryForm_FormView_SellingDealReservation on SellingDealReservation {\n    id\n    ...SellingDealReservationEdit_PreferredDateInput_SellingDealReservation\n  }\n": types.SellingDealReservationEdit_DeliveryForm_FormView_SellingDealReservationFragmentDoc,
    "\n  fragment SellingDealReservationEdit_PreferredDateInput_SellingDealReservation on SellingDealReservation {\n    id\n    carOfficialValuation {\n      id\n      priceValidDeadline\n    }\n    ...SellingDealReservationEdit_TimeSelect_SellingDealReservation\n  }\n": types.SellingDealReservationEdit_PreferredDateInput_SellingDealReservationFragmentDoc,
    "\n  fragment SellingDealReservationEdit_TimeSelect_SellingDealReservation on SellingDealReservation {\n    id\n    deliveryPreferredTimeOptions {\n      label\n      value\n    }\n  }\n": types.SellingDealReservationEdit_TimeSelect_SellingDealReservationFragmentDoc,
    "\n  fragment SellingDealReservationEdit_index_SellingDealReservation on SellingDealReservation {\n    id\n    carOfficialValuation {\n      id\n      pickupZipCode\n      pickupZipCodeObject {\n        prefecture\n        city\n        town\n      }\n    }\n    ...SellingDealReservationEdit_DeliveryForm_FormView_SellingDealReservation\n    ...SellingDealReservationEdit_DeliveryConfirm_SellingDealReservation\n  }\n": types.SellingDealReservationEdit_Index_SellingDealReservationFragmentDoc,
    "\n  mutation UpdateSellingDealReservationDismantling($input: UpdateSellingDealReservationDismantlingInput!) {\n    updateSellingDealReservationDismantling(input: $input) {\n      sellingDealReservation {\n        id\n        displayReservationData\n      }\n    }\n  }\n": types.UpdateSellingDealReservationDismantlingDocument,
    "\n  fragment SellingDealReservationEdit_DismantlingForm_FirstForm_SellingDealReservation on SellingDealReservation {\n    id\n    carOfficialValuation {\n      id\n      pickupZipCode\n      pickupZipCodeObject {\n        prefecture\n        city\n        town\n      }\n    }\n  }\n": types.SellingDealReservationEdit_DismantlingForm_FirstForm_SellingDealReservationFragmentDoc,
    "\n  fragment SellingDealReservationEdit_DismantlingForm_SellingDealReservation on SellingDealReservation {\n    id\n    ...SellingDealReservationEdit_DismantlingForm_FirstForm_SellingDealReservation\n  }\n": types.SellingDealReservationEdit_DismantlingForm_SellingDealReservationFragmentDoc,
    "\n  query SellingDealReservatinoEdit_SellingDealReservation($ownedCarId: ID!, $sellingDealReservationId: ID!) {\n    sellingDealReservation(ownedCarId: $ownedCarId, sellingDealReservationId: $sellingDealReservationId) {\n      id\n      reservationType\n      isCompleted\n      ...SellingDealReservationEdit_index_SellingDealReservation\n      ...SellingDealReservationEdit_BringInForm_SellingDealReservation\n      ...SellingDealReservationEdit_DismantlingForm_SellingDealReservation\n    }\n  }\n": types.SellingDealReservatinoEdit_SellingDealReservationDocument,
    "\n  mutation SellingNeedDocumentConfirm_ConfirmSellingNeedDocument($input: ConfirmSellingNeedDocumentInput!) {\n    confirmSellingNeedDocument(input: $input) {\n      sellingNeedDocument {\n        id\n        nextStepNumber\n        displaySelectedData\n      }\n    }\n  }\n": types.SellingNeedDocumentConfirm_ConfirmSellingNeedDocumentDocument,
    "\n  fragment SellingNeedDocumentConfirmBody_sellingNeedDocument on SellingNeedDocument {\n    id\n    isLoanRemaining\n    ...SellingNeedDocumentConfirmCard_sellingNeedDocument\n  }\n": types.SellingNeedDocumentConfirmBody_SellingNeedDocumentFragmentDoc,
    "\n  query SellingNeedDocumentConfirm_SellingNeedDocument($ownedCarId: ID!, $sellingNeedDocumentId: ID!) {\n    sellingNeedDocument(ownedCarId: $ownedCarId, sellingNeedDocumentId: $sellingNeedDocumentId) {\n      id\n      isAllStepAnswered\n      isUserConfirmed\n      ...SellingNeedDocumentConfirmBody_sellingNeedDocument\n    }\n  }\n": types.SellingNeedDocumentConfirm_SellingNeedDocumentDocument,
    "\n  fragment SellingNeedDocumentConfirmCard_sellingNeedDocument on SellingNeedDocument {\n    id\n    selectedData {\n      stepId\n      stepNumber\n      question {\n        shortText\n      }\n      choice {\n        text\n      }\n    }\n  }\n": types.SellingNeedDocumentConfirmCard_SellingNeedDocumentFragmentDoc,
    "\n  fragment SellingNeedDocumentDocumentsBody_sellingNeedDocument on SellingNeedDocument {\n    id\n    loanCompanyName\n    selectedData {\n      stepId\n      question {\n        shortText\n      }\n      choice {\n        text\n      }\n    }\n  }\n": types.SellingNeedDocumentDocumentsBody_SellingNeedDocumentFragmentDoc,
    "\n  query SellingNeedDocumentDocuments_SellingNeedDocument($ownedCarId: ID!, $sellingNeedDocumentId: ID!) {\n    sellingNeedDocument(ownedCarId: $ownedCarId, sellingNeedDocumentId: $sellingNeedDocumentId) {\n      id\n      ...SellingNeedDocumentDocumentsBody_sellingNeedDocument\n    }\n  }\n": types.SellingNeedDocumentDocuments_SellingNeedDocumentDocument,
    "\n  fragment SellingNeedDocumentEditAnswerHistory_sellingNeedDocument on SellingNeedDocument {\n    id\n    selectedData {\n      stepId\n      question {\n        shortText\n      }\n      choice {\n        text\n      }\n    }\n  }\n": types.SellingNeedDocumentEditAnswerHistory_SellingNeedDocumentFragmentDoc,
    "\n  fragment SellingNeedDocumentEditBody_sellingNeedDocumentStep on SellingNeedDocumentStep {\n    id\n    question {\n      shortText\n      text\n    }\n    choices {\n      text\n      value\n    }\n  }\n": types.SellingNeedDocumentEditBody_SellingNeedDocumentStepFragmentDoc,
    "\n  fragment SellingNeedDocumentEditBody_sellingNeedDocument on SellingNeedDocument {\n    id\n    ...SellingNeedDocumentEditAnswerHistory_sellingNeedDocument\n  }\n": types.SellingNeedDocumentEditBody_SellingNeedDocumentFragmentDoc,
    "\n  query SellingNeedDocumentStepWrapper_SellingNeedDocumentStep(\n    $ownedCarId: ID!\n    $sellingNeedDocumentId: ID!\n    $step: Int!\n  ) {\n    sellingNeedDocumentStep(ownedCarId: $ownedCarId, sellingNeedDocumentId: $sellingNeedDocumentId, step: $step) {\n      ...SellingNeedDocumentEditBody_sellingNeedDocumentStep\n    }\n  }\n": types.SellingNeedDocumentStepWrapper_SellingNeedDocumentStepDocument,
    "\n  fragment SellingNeedDocumentStepWrapper_sellingNeedDocument on SellingNeedDocument {\n    id\n    ...SellingNeedDocumentEditBody_sellingNeedDocument\n  }\n": types.SellingNeedDocumentStepWrapper_SellingNeedDocumentFragmentDoc,
    "\n  query SellingNeedDocumentEdit_SellingNeedDocument($ownedCarId: ID!, $sellingNeedDocumentId: ID!) {\n    sellingNeedDocument(ownedCarId: $ownedCarId, sellingNeedDocumentId: $sellingNeedDocumentId) {\n      id\n      nextStepNumber\n      isAllStepAnswered\n      isUserConfirmed\n      isRequireInspectionCertificateImage\n      ...SellingNeedDocumentStepWrapper_sellingNeedDocument\n    }\n  }\n": types.SellingNeedDocumentEdit_SellingNeedDocumentDocument,
    "\n  mutation useSellingNeedDocumentEdit_SelectSellingNeedDocument($input: SelectSellingNeedDocumentInput!) {\n    selectSellingNeedDocument(input: $input) {\n      sellingNeedDocument {\n        id\n        isAllStepAnswered\n        ...SellingNeedDocumentEditAnswerHistory_sellingNeedDocument\n      }\n    }\n  }\n": types.UseSellingNeedDocumentEdit_SelectSellingNeedDocumentDocument,
    "\n  query SellingNeedDocumentUpload_SellingNeedDocument($ownedCarId: ID!, $sellingNeedDocumentId: ID!) {\n    sellingNeedDocument(ownedCarId: $ownedCarId, sellingNeedDocumentId: $sellingNeedDocumentId) {\n      id\n      isRequireInspectionCertificateImage\n    }\n  }\n": types.SellingNeedDocumentUpload_SellingNeedDocumentDocument,
    "\n  mutation useSellingNeedDocumentUpload_UploadSellingNeedDocumentInspectionCertificateImage(\n    $input: UploadSellingNeedDocumentInspectionCertificateImageInput!\n  ) {\n    uploadSellingNeedDocumentInspectionCertificateImage(input: $input) {\n      sellingNeedDocument {\n        id\n        isRequireInspectionCertificateImage\n      }\n    }\n  }\n": types.UseSellingNeedDocumentUpload_UploadSellingNeedDocumentInspectionCertificateImageDocument,
    "\n  mutation useRevertSellingNeedDocumentSelectedData_RevertSellingNeedDocumentSelectedData(\n    $input: RevertSellingNeedDocumentSelectedDataInput!\n  ) {\n    revertSellingNeedDocumentSelectedData(input: $input) {\n      sellingNeedDocument {\n        id\n        nextStepNumber\n        isAllStepAnswered\n        isUserConfirmed\n        ...SellingNeedDocumentConfirmCard_sellingNeedDocument\n        ...SellingNeedDocumentEditAnswerHistory_sellingNeedDocument\n      }\n    }\n  }\n": types.UseRevertSellingNeedDocumentSelectedData_RevertSellingNeedDocumentSelectedDataDocument,
    "\n  mutation UpdateUser_UserEdit($input: UpdateUserInput!) {\n    updateUser(input: $input) {\n      user {\n        id\n        lastName\n        firstName\n        fullName\n        zipCode\n        zipCodeAddress {\n          id\n          prefecture\n          city\n        }\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n": types.UpdateUser_UserEditDocument,
    "\n  fragment ReviewCard_userReview on UserReview {\n    id\n    reviewType\n    satisfactionRate\n    closedMonth\n    makeName\n    carModelName\n    title\n    conveniencePoint\n    staffComment\n    staff {\n      id\n      fullName\n    }\n  }\n": types.ReviewCard_UserReviewFragmentDoc,
    "\n  fragment ReviewsIndex_userReview on UserReview {\n    id\n    ...ReviewCard_userReview\n    ...ReviewModal_userReview\n  }\n": types.ReviewsIndex_UserReviewFragmentDoc,
    "\n  fragment ReviewModal_userReview on UserReview {\n    id\n    reviewType\n    satisfactionRate\n    closedMonth\n    makeName\n    carModelName\n    title\n    conveniencePoint\n    worryPoint\n    reason\n    staffComment\n    staff {\n      id\n      fullName\n      imageUrl\n    }\n  }\n": types.ReviewModal_UserReviewFragmentDoc,
    "\n  fragment CarCardGroup_carStock on CarStock {\n    id\n    ...LargeCarCard_carStock\n    ...SmallCarCard_carStock\n    ...CarIndex_CarMovies_carStock\n  }\n": types.CarCardGroup_CarStockFragmentDoc,
    "\n  fragment CarMoviePopup_carStock on CarStock {\n    id\n    ulid\n    carModelYear\n    mileageKm\n    carModelName\n    gradeName\n    totalPaymentAmount\n    tags {\n      id\n      name\n    }\n    promotion {\n      id\n      catchCopy\n      staffComment\n      postedBy {\n        id\n        imageUrl\n        fullName\n      }\n    }\n    videos {\n      id\n      url\n      thumbnailUrl\n    }\n  }\n": types.CarMoviePopup_CarStockFragmentDoc,
    "\n  fragment CarOfficialValuationShared_carOfficialValuation on CarOfficialValuation {\n    id\n    ...CarOfficialValuationShared_useCarOfficialValuationForm_carOfficialValuation\n  }\n": types.CarOfficialValuationShared_CarOfficialValuationFragmentDoc,
    "\n  fragment CarOfficialValuationShared_ownedCar on OwnedCar {\n    id\n    ...CarOfficialValuationShared_useCarOfficialValuationForm_ownedCar\n  }\n": types.CarOfficialValuationShared_OwnedCarFragmentDoc,
    "\n  fragment CarOfficialValuationShared_useCarOfficialValuationForm_ownedCar on OwnedCar {\n    id\n    makeName\n    carModelName\n    gradeName\n    firstRegistrationYear\n    odorStatus\n    odorStatusComment\n    accidentStatus\n    accidentComment\n    latestMileageKm\n    inspectionCertificateImage {\n      id\n      url\n    }\n    favoriteImage {\n      id\n      url\n    }\n    ownedCarRepairFiles {\n      id\n      fileUrl\n      fileName\n      isPdf\n    }\n  }\n": types.CarOfficialValuationShared_UseCarOfficialValuationForm_OwnedCarFragmentDoc,
    "\n  fragment CarOfficialValuationShared_useCarOfficialValuationForm_carOfficialValuation on CarOfficialValuation {\n    id\n    pickupZipCode\n    ownedCar {\n      id\n      ...CarOfficialValuationShared_useCarOfficialValuationForm_ownedCar\n    }\n  }\n": types.CarOfficialValuationShared_UseCarOfficialValuationForm_CarOfficialValuationFragmentDoc,
    "\n  fragment CarValuationFormParts_CarSelectModal_CarCard_ownedCar on OwnedCar {\n    id\n    makeName\n    carModelName\n    firstRegistrationYear\n    gradeName\n    odorStatus\n    odorStatusText\n    odorStatusComment\n    accidentStatus\n    accidentStatusText\n    accidentComment\n    latestMileageKm\n    inspectionCertificateImage {\n      id\n      url\n    }\n    favoriteImage {\n      id\n      url\n    }\n    mainImage {\n      id\n      url\n    }\n  }\n": types.CarValuationFormParts_CarSelectModal_CarCard_OwnedCarFragmentDoc,
    "\n  fragment CarValuationFormParts_CarSelectModal_ownedCar on OwnedCar {\n    id\n    ...CarValuationFormParts_CarSelectModal_CarCard_ownedCar\n  }\n": types.CarValuationFormParts_CarSelectModal_OwnedCarFragmentDoc,
    "\n  fragment LargeCarCard_carStock on CarStock {\n    id\n    ulid\n    carModelYear\n    mileageKm\n    carModelName\n    gradeName\n    totalPaymentAmount\n    salesStatus\n    publishedAt\n    tags {\n      id\n      name\n    }\n    promotion {\n      id\n      catchCopy\n      staffComment\n      postedBy {\n        id\n        imageUrl\n      }\n    }\n    dealerBranch {\n      id\n      landTransportArea\n    }\n    imageUrls\n  }\n": types.LargeCarCard_CarStockFragmentDoc,
    "\n  query formCarStockQuery($makerId: ID!) {\n    carNameOption(makerId: $makerId) {\n      id\n      carNameOptions {\n        id\n        value\n        label\n      }\n    }\n  }\n": types.FormCarStockQueryDocument,
    "\n  query formOptionCarStockSearch {\n    formOption {\n      id\n      carStockSearchMakerOption {\n        id\n        value\n        label\n      }\n      carStockSearchBodyTypeOption {\n        id\n        value\n        label\n      }\n    }\n  }\n": types.FormOptionCarStockSearchDocument,
    "\n  fragment SmallCarCard_carStock on CarStock {\n    id\n    ulid\n    carModelYear\n    mileageKm\n    carModelName\n    gradeName\n    totalPaymentAmount\n    salesStatus\n    publishedAt\n    tags {\n      id\n      name\n    }\n    promotion {\n      id\n      catchCopy\n      staffComment\n      postedBy {\n        id\n        imageUrl\n      }\n    }\n    dealerBranch {\n      id\n      landTransportArea\n    }\n    imageUrls\n  }\n": types.SmallCarCard_CarStockFragmentDoc,
    "\n  mutation useOwnedCarImage_DestroyOwnedCarImage($input: DestroyOwnedCarImageInput!) {\n    destroyOwnedCarImage(input: $input) {\n      id\n      isDestroyed\n      errors {\n        message\n        path\n      }\n    }\n  }\n": types.UseOwnedCarImage_DestroyOwnedCarImageDocument,
    "\n  mutation createCarStockFavorite($input: CreateCarStockFavoriteInput!) {\n    createCarStockFavorite(input: $input) {\n      carStockFavorite {\n        id\n        carStock {\n          id\n          ...SmallCarCard_carStock\n        }\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n": types.CreateCarStockFavoriteDocument,
    "\n  mutation destroyCarStockFavorite($input: DestroyCarStockFavoriteInput!) {\n    destroyCarStockFavorite(input: $input) {\n      isSuccess\n      errors {\n        message\n        path\n      }\n    }\n  }\n": types.DestroyCarStockFavoriteDocument,
    "\n  mutation login($input: LoginInput!) {\n    login(input: $input) {\n      user {\n        id\n        lastName\n        firstName\n        zipCode\n        lineAccount {\n          id\n          name\n        }\n        favoritedCarStocks {\n          id\n        }\n        inquiries {\n          id\n          carStocks {\n            id\n          }\n        }\n      }\n      isCreatedUser\n      errors {\n        path\n        message\n      }\n    }\n  }\n": types.LoginDocument,
    "\n  mutation updateLineAccountName($input: UpdateLineAccountNameInput!) {\n    updateLineAccountName(input: $input) {\n      lineAccount {\n        id\n        name\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n": types.UpdateLineAccountNameDocument,
    "\n  query convertLatlng($longitude: Float!, $latitude: Float!) {\n    convertLatlng(longitude: $longitude, latitude: $latitude) {\n      zipCode\n      address\n      prefectureCode\n      errors {\n        message\n      }\n    }\n  }\n": types.ConvertLatlngDocument,
    "\n  query convertZipCode($zipCode: String!) {\n    convertZipCode(zipCode: $zipCode) {\n      zipCode\n      address\n      prefectureCode\n      prefectureName\n      cityName\n      errors {\n        message\n      }\n    }\n  }\n": types.ConvertZipCodeDocument,
    "\n  query user {\n    viewer {\n      id\n      lastName\n      firstName\n      zipCode\n      tracking\n      lineAccount {\n        id\n        name\n      }\n      favoritedCarStocks {\n        id\n      }\n      inquiries {\n        id\n        carStocks {\n          id\n        }\n      }\n      activeOneClickContracts {\n        id\n        carStock {\n          id\n        }\n      }\n    }\n  }\n": types.UserDocument,
    "\n  query fetchDirectUploadParams($requests: [DirectUploadRequest!]!) {\n    s3DirectUploadParams(requests: $requests) {\n      url\n      contentToken\n      fields\n    }\n  }\n": types.FetchDirectUploadParamsDocument,
    "\n  query CarStocksIndexPage($first: Int, $salesStatus: CarSalesStatusEnum) {\n    carStocks(first: $first, salesStatus: $salesStatus) {\n      edges {\n        node {\n          id\n          landTransportCosts {\n            cost\n            prefectureCode\n          }\n          ...CarsIndex_carStock\n        }\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n": types.CarStocksIndexPageDocument,
    "\n  query SpecialShowPage($first: Int, $after: String, $salesStatus: CarSalesStatusEnum, $tagGroup: TagGroupEnum) {\n    carStocks(first: $first, after: $after, salesStatus: $salesStatus, tagGroup: $tagGroup) {\n      edges {\n        node {\n          id\n          landTransportCosts {\n            cost\n            prefectureCode\n          }\n          ...SpecialShow_carStock\n        }\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n": types.SpecialShowPageDocument,
    "\n  query CarStockShowPage($ulid: String!) {\n    carStock(ulid: $ulid) {\n      id\n      makeName\n      carModelName\n      carModelYear\n      gradeName\n      mileageKm\n      inspectionExpiryDate\n      images {\n        id\n        url\n      }\n      promotion {\n        id\n        description\n        catchCopy\n        scores {\n          score\n        }\n      }\n      carStockDetail {\n        id\n        repairHistoryCategory\n        interiorEvaluationScore\n      }\n      ...CarsShow_carStock\n      oneClickContractCounts\n    }\n  }\n": types.CarStockShowPageDocument,
    "\n  query CarsMoviesPage($first: Int, $after: String, $hasVideo: Boolean, $salesStatus: CarSalesStatusEnum) {\n    carStocks(first: $first, after: $after, hasVideo: $hasVideo, salesStatus: $salesStatus) {\n      edges {\n        node {\n          id\n          landTransportCosts {\n            cost\n            prefectureCode\n          }\n          ...CarMovies_carStock\n        }\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n": types.CarsMoviesPageDocument,
    "\n  query userInterviewCarShow($ulid: String!) {\n    carStock(ulid: $ulid) {\n      id\n      ulid\n      makeName\n      carModelName\n      carModelYear\n      gradeName\n      mileageKm\n      imageUrls\n      makeCode\n      carModelCode\n    }\n  }\n": types.UserInterviewCarShowDocument,
    "\n  mutation Logout($input: LogoutInput!) {\n    logout(input: $input) {\n      errors {\n        message\n      }\n    }\n  }\n": types.LogoutDocument,
    "\n  query formOptionOneClickNew {\n    formOption {\n      id\n      requestTypeOption {\n        id\n        value\n        label\n      }\n      prefectureOption {\n        id\n        value\n        label\n      }\n    }\n  }\n": types.FormOptionOneClickNewDocument,
    "\n  query ReviewsPage($reviewType: UserReviewReviewTypeEnum!, $first: Int, $after: String) {\n    userReviews(reviewType: $reviewType, first: $first, after: $after) {\n      nodes {\n        ...ReviewsIndex_userReview\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n": types.ReviewsPageDocument,
    "\n  query CarStocksSearchPage(\n    $first: Int\n    $after: String\n    $salesStatus: CarSalesStatusEnum\n    $bodyType: CarStockBodyTypeEnum\n    $makeCode: String\n    $carModelCode: String\n    $shift: String\n    $yearFrom: String\n    $yearTo: String\n    $freeText: String\n    $sortType: CarStockSortTypeEnum\n    $hasVideo: Boolean\n    $includeTotalCount: Boolean!\n  ) {\n    carStocks(\n      first: $first\n      after: $after\n      salesStatus: $salesStatus\n      bodyType: $bodyType\n      makeCode: $makeCode\n      carModelCode: $carModelCode\n      shift: $shift\n      yearFrom: $yearFrom\n      yearTo: $yearTo\n      freeText: $freeText\n      sortType: $sortType\n      hasVideo: $hasVideo\n    ) {\n      edges {\n        node {\n          ...CarsSearch_carStock\n        }\n      }\n      totalCount @include(if: $includeTotalCount)\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n": types.CarStocksSearchPageDocument,
    "\n  query makerAndBodyTypeOption {\n    formOption {\n      id\n      carStockSearchMakerOption {\n        id\n        value\n        label\n      }\n      carStockSearchBodyTypeOption {\n        id\n        value\n        label\n      }\n    }\n  }\n": types.MakerAndBodyTypeOptionDocument,
    "\n  query UserReviewFormOptionQuery {\n    formOption {\n      id\n      userReviewTypeOption {\n        id\n        value\n        label\n      }\n    }\n  }\n": types.UserReviewFormOptionQueryDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation useAishameterCushion_CreateCarPriceEstimationLog($input: CreateCarPriceEstimationLogInput!) {\n    createCarPriceEstimationLog(input: $input) {\n      isSuccess\n    }\n  }\n"): (typeof documents)["\n  mutation useAishameterCushion_CreateCarPriceEstimationLog($input: CreateCarPriceEstimationLogInput!) {\n    createCarPriceEstimationLog(input: $input) {\n      isSuccess\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query CarEstimateValuationNewQuery($ownedCarId: ID!) {\n    ownedCar(id: $ownedCarId) {\n      id\n      makeName\n      carModelName\n      gradeName\n      firstRegistrationYear\n      ...CarEstimateValuationNew_useCarEstimateValuationNewForm_ownedCar\n    }\n  }\n"): (typeof documents)["\n  query CarEstimateValuationNewQuery($ownedCarId: ID!) {\n    ownedCar(id: $ownedCarId) {\n      id\n      makeName\n      carModelName\n      gradeName\n      firstRegistrationYear\n      ...CarEstimateValuationNew_useCarEstimateValuationNewForm_ownedCar\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation useCarEstimateValuationNew_CreateCarEstimateValuationV2($input: CreateCarEstimateValuationV2Input!) {\n    createCarEstimateValuationV2(input: $input) {\n      carEstimateValuation {\n        id\n        ulid\n        ownedCarId\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation useCarEstimateValuationNew_CreateCarEstimateValuationV2($input: CreateCarEstimateValuationV2Input!) {\n    createCarEstimateValuationV2(input: $input) {\n      carEstimateValuation {\n        id\n        ulid\n        ownedCarId\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment CarEstimateValuationNew_useCarEstimateValuationNewForm_ownedCar on OwnedCar {\n    id\n    makeName\n    carModelName\n    gradeName\n    firstRegistrationYear\n    odorStatus\n    odorStatusComment\n    accidentStatus\n    accidentComment\n    latestMileageKm\n    inspectionCertificateImage {\n      id\n      url\n    }\n    favoriteImage {\n      id\n      url\n    }\n  }\n"): (typeof documents)["\n  fragment CarEstimateValuationNew_useCarEstimateValuationNewForm_ownedCar on OwnedCar {\n    id\n    makeName\n    carModelName\n    gradeName\n    firstRegistrationYear\n    odorStatus\n    odorStatusComment\n    accidentStatus\n    accidentComment\n    latestMileageKm\n    inspectionCertificateImage {\n      id\n      url\n    }\n    favoriteImage {\n      id\n      url\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CarOfficialValuationNew_useCarOfficialValuationNewMutation_EditCarOfficialValuation(\n    $input: EditCarOfficialValuationInput!\n  ) {\n    editCarOfficialValuation(input: $input) {\n      carOfficialValuation {\n        id\n        ulid\n        ownedCarId\n        ...CarOfficialValuationShared_carOfficialValuation\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation CarOfficialValuationNew_useCarOfficialValuationNewMutation_EditCarOfficialValuation(\n    $input: EditCarOfficialValuationInput!\n  ) {\n    editCarOfficialValuation(input: $input) {\n      carOfficialValuation {\n        id\n        ulid\n        ownedCarId\n        ...CarOfficialValuationShared_carOfficialValuation\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query CarOfficialValuationNew_useCarValuationNewQuery_CarOfficialValuation($ownedCarId: ID!, $ulid: String!) {\n    carOfficialValuation(ownedCarId: $ownedCarId, ulid: $ulid) {\n      id\n      ...CarOfficialValuationShared_carOfficialValuation\n      ownedCar {\n        id\n        ...CarOfficialValuationShared_ownedCar\n      }\n    }\n  }\n"): (typeof documents)["\n  query CarOfficialValuationNew_useCarValuationNewQuery_CarOfficialValuation($ownedCarId: ID!, $ulid: String!) {\n    carOfficialValuation(ownedCarId: $ownedCarId, ulid: $ulid) {\n      id\n      ...CarOfficialValuationShared_carOfficialValuation\n      ownedCar {\n        id\n        ...CarOfficialValuationShared_ownedCar\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query CarOfficialValuationNew_useCarOfficialValuationQuery_OwnedCar($ownedCarId: ID!) {\n    ownedCar(id: $ownedCarId) {\n      id\n      ...CarOfficialValuationShared_ownedCar\n    }\n  }\n"): (typeof documents)["\n  query CarOfficialValuationNew_useCarOfficialValuationQuery_OwnedCar($ownedCarId: ID!) {\n    ownedCar(id: $ownedCarId) {\n      id\n      ...CarOfficialValuationShared_ownedCar\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CarValuationNew_CarEstimateValuationForm_useCarEstimateValuationForm_CreateCarEstimateValuationV2(\n    $input: CreateCarEstimateValuationV2Input!\n  ) {\n    createCarEstimateValuationV2(input: $input) {\n      carEstimateValuation {\n        id\n        ulid\n        ownedCarId\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation CarValuationNew_CarEstimateValuationForm_useCarEstimateValuationForm_CreateCarEstimateValuationV2(\n    $input: CreateCarEstimateValuationV2Input!\n  ) {\n    createCarEstimateValuationV2(input: $input) {\n      carEstimateValuation {\n        id\n        ulid\n        ownedCarId\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CarValuationNew_CarOfficialValuationForm_useCarOfficialValuationMutation_EditCarOfficialValuation(\n    $input: EditCarOfficialValuationInput!\n  ) {\n    editCarOfficialValuation(input: $input) {\n      carOfficialValuation {\n        id\n        ulid\n        ownedCarId\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation CarValuationNew_CarOfficialValuationForm_useCarOfficialValuationMutation_EditCarOfficialValuation(\n    $input: EditCarOfficialValuationInput!\n  ) {\n    editCarOfficialValuation(input: $input) {\n      carOfficialValuation {\n        id\n        ulid\n        ownedCarId\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment CarValuationNew_useCarValuationNewForm_carOfficialValuation on CarOfficialValuation {\n    id\n    pickupZipCode\n  }\n"): (typeof documents)["\n  fragment CarValuationNew_useCarValuationNewForm_carOfficialValuation on CarOfficialValuation {\n    id\n    pickupZipCode\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment CarValuationNew_useCarValuationNewForm_ownedCar on OwnedCar {\n    id\n    makeName\n    carModelName\n    gradeName\n    firstRegistrationYear\n    odorStatus\n    odorStatusComment\n    accidentStatus\n    accidentComment\n    latestMileageKm\n    inspectionCertificateImage {\n      id\n      url\n    }\n    favoriteImage {\n      id\n      url\n    }\n    ownedCarRepairFiles {\n      id\n      fileUrl\n      fileName\n      isPdf\n    }\n  }\n"): (typeof documents)["\n  fragment CarValuationNew_useCarValuationNewForm_ownedCar on OwnedCar {\n    id\n    makeName\n    carModelName\n    gradeName\n    firstRegistrationYear\n    odorStatus\n    odorStatusComment\n    accidentStatus\n    accidentComment\n    latestMileageKm\n    inspectionCertificateImage {\n      id\n      url\n    }\n    favoriteImage {\n      id\n      url\n    }\n    ownedCarRepairFiles {\n      id\n      fileUrl\n      fileName\n      isPdf\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query CarValuationNew_useCarValuationNewQuery_CarOfficialValuation($ownedCarId: ID!, $ulid: String!) {\n    carOfficialValuation(ownedCarId: $ownedCarId, ulid: $ulid) {\n      id\n      ...CarValuationNew_useCarValuationNewForm_carOfficialValuation\n      ownedCar {\n        id\n        makeName\n        carModelName\n        gradeName\n        firstRegistrationYear\n        ...CarValuationNew_useCarValuationNewForm_ownedCar\n      }\n    }\n  }\n"): (typeof documents)["\n  query CarValuationNew_useCarValuationNewQuery_CarOfficialValuation($ownedCarId: ID!, $ulid: String!) {\n    carOfficialValuation(ownedCarId: $ownedCarId, ulid: $ulid) {\n      id\n      ...CarValuationNew_useCarValuationNewForm_carOfficialValuation\n      ownedCar {\n        id\n        makeName\n        carModelName\n        gradeName\n        firstRegistrationYear\n        ...CarValuationNew_useCarValuationNewForm_ownedCar\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query CarValuationNew_useCarValuationNewQuery_OwnedCar($ownedCarId: ID!) {\n    ownedCar(id: $ownedCarId) {\n      id\n      makeName\n      carModelName\n      gradeName\n      firstRegistrationYear\n      ...CarValuationNew_useCarValuationNewForm_ownedCar\n    }\n  }\n"): (typeof documents)["\n  query CarValuationNew_useCarValuationNewQuery_OwnedCar($ownedCarId: ID!) {\n    ownedCar(id: $ownedCarId) {\n      id\n      makeName\n      carModelName\n      gradeName\n      firstRegistrationYear\n      ...CarValuationNew_useCarValuationNewForm_ownedCar\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation RequestDocument($input: RequestDocumentInput!) {\n    requestDocument(input: $input) {\n      isSuccess\n      errors {\n        message\n        path\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation RequestDocument($input: RequestDocumentInput!) {\n    requestDocument(input: $input) {\n      isSuccess\n      errors {\n        message\n        path\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateUser($input: UpdateUserInput!) {\n    updateUser(input: $input) {\n      user {\n        id\n        zipCode\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateUser($input: UpdateUserInput!) {\n    updateUser(input: $input) {\n      user {\n        id\n        zipCode\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query FavoritesIndexComponent($prefectureCode: Int) {\n    viewer {\n      id\n      favoritedCarStocks {\n        id\n        ulid\n        landTransportCost(prefectureCode: $prefectureCode)\n        ...SmallCarCard_carStock\n      }\n    }\n  }\n"): (typeof documents)["\n  query FavoritesIndexComponent($prefectureCode: Int) {\n    viewer {\n      id\n      favoritedCarStocks {\n        id\n        ulid\n        landTransportCost(prefectureCode: $prefectureCode)\n        ...SmallCarCard_carStock\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreateInflowSource($input: CreateInflowSourceInput!) {\n    createInflowSource(input: $input) {\n      isSuccess\n      errors {\n        message\n        path\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation CreateInflowSource($input: CreateInflowSourceInput!) {\n    createInflowSource(input: $input) {\n      isSuccess\n      errors {\n        message\n        path\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation InquireBuyingNewInquireBuying($input: InquireBuyingInput!) {\n    inquireBuying(input: $input) {\n      inquiry {\n        id\n        user {\n          id\n          lastName\n          zipCode\n          firstName\n        }\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation InquireBuyingNewInquireBuying($input: InquireBuyingInput!) {\n    inquireBuying(input: $input) {\n      inquiry {\n        id\n        user {\n          id\n          lastName\n          zipCode\n          firstName\n        }\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation oneClickContract($input: CreateOneClickContractInput!) {\n    createOneClickContract(input: $input) {\n      errors {\n        message\n        path\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation oneClickContract($input: CreateOneClickContractInput!) {\n    createOneClickContract(input: $input) {\n      errors {\n        message\n        path\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query SearchConditionShow {\n    viewer {\n      id\n      lineAccount {\n        id\n        carStockSearchCondition {\n          id\n          minAmount\n          maxAmount\n          maxMileageKm\n          minCarModeYearBeforeYear\n          bodyTypeGroups {\n            id\n            bodyTypeGroup\n          }\n          searchConditionCarNames {\n            id\n            carModelName\n            makeName\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query SearchConditionShow {\n    viewer {\n      id\n      lineAccount {\n        id\n        carStockSearchCondition {\n          id\n          minAmount\n          maxAmount\n          maxMileageKm\n          minCarModeYearBeforeYear\n          bodyTypeGroups {\n            id\n            bodyTypeGroup\n          }\n          searchConditionCarNames {\n            id\n            carModelName\n            makeName\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query SearchConditionShow_CarStockNames($carModelNameKeyword: String, $makeNameKeyword: String) {\n    carStockNames(carModelNameKeyword: $carModelNameKeyword, makeNameKeyword: $makeNameKeyword) {\n      carModelNames\n      makeNames\n    }\n  }\n"): (typeof documents)["\n  query SearchConditionShow_CarStockNames($carModelNameKeyword: String, $makeNameKeyword: String) {\n    carStockNames(carModelNameKeyword: $carModelNameKeyword, makeNameKeyword: $makeNameKeyword) {\n      carModelNames\n      makeNames\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateCarStockSearchCondition($input: UpdateCarStockSearchConditionInput!) {\n    updateCarStockSearchCondition(input: $input) {\n      carStockSearchCondition {\n        id\n        minAmount\n        maxAmount\n        maxMileageKm\n        minCarModeYearBeforeYear\n        bodyTypeGroups {\n          id\n          bodyTypeGroup\n        }\n        searchConditionCarNames {\n          id\n          carModelName\n          makeName\n        }\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateCarStockSearchCondition($input: UpdateCarStockSearchConditionInput!) {\n    updateCarStockSearchCondition(input: $input) {\n      carStockSearchCondition {\n        id\n        minAmount\n        maxAmount\n        maxMileageKm\n        minCarModeYearBeforeYear\n        bodyTypeGroups {\n          id\n          bodyTypeGroup\n        }\n        searchConditionCarNames {\n          id\n          carModelName\n          makeName\n        }\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation DestroyCarStockSearchCondition($input: DestroyCarStockSearchConditionInput!) {\n    destroyCarStockSearchCondition(input: $input) {\n      isSuccess\n      errors {\n        message\n        path\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation DestroyCarStockSearchCondition($input: DestroyCarStockSearchConditionInput!) {\n    destroyCarStockSearchCondition(input: $input) {\n      isSuccess\n      errors {\n        message\n        path\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment SpecialShow_carStock on CarStock {\n    id\n    ...CarCardGroup_carStock\n  }\n"): (typeof documents)["\n  fragment SpecialShow_carStock on CarStock {\n    id\n    ...CarCardGroup_carStock\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query SpecialShowComponent(\n    $first: Int\n    $after: String\n    $salesStatus: CarSalesStatusEnum\n    $tagGroup: TagGroupEnum\n    $prefectureCode: Int\n  ) {\n    carStocks(first: $first, after: $after, salesStatus: $salesStatus, tagGroup: $tagGroup) {\n      edges {\n        node {\n          id\n          landTransportCost(prefectureCode: $prefectureCode)\n          ...SpecialShow_carStock\n        }\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n"): (typeof documents)["\n  query SpecialShowComponent(\n    $first: Int\n    $after: String\n    $salesStatus: CarSalesStatusEnum\n    $tagGroup: TagGroupEnum\n    $prefectureCode: Int\n  ) {\n    carStocks(first: $first, after: $after, salesStatus: $salesStatus, tagGroup: $tagGroup) {\n      edges {\n        node {\n          id\n          landTransportCost(prefectureCode: $prefectureCode)\n          ...SpecialShow_carStock\n        }\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation createUserReviewBuyingAfterSale($input: CreateUserReviewBuyingAfterSaleInput!) {\n    createUserReviewBuyingAfterSale(input: $input) {\n      success\n    }\n  }\n"): (typeof documents)["\n  mutation createUserReviewBuyingAfterSale($input: CreateUserReviewBuyingAfterSaleInput!) {\n    createUserReviewBuyingAfterSale(input: $input) {\n      success\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation createUserReviewBuyingContract($input: CreateUserReviewBuyingContractInput!) {\n    createUserReviewBuyingContract(input: $input) {\n      success\n    }\n  }\n"): (typeof documents)["\n  mutation createUserReviewBuyingContract($input: CreateUserReviewBuyingContractInput!) {\n    createUserReviewBuyingContract(input: $input) {\n      success\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation createUserReview($input: CreateUserReviewInput!) {\n    createUserReview(input: $input) {\n      errors {\n        message\n        path\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation createUserReview($input: CreateUserReviewInput!) {\n    createUserReview(input: $input) {\n      errors {\n        message\n        path\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment CarIndex_CarMovies_carStock on CarStock {\n    id\n    video {\n      id\n      url\n      thumbnailUrl\n    }\n    ...CarMoviePopup_carStock\n  }\n"): (typeof documents)["\n  fragment CarIndex_CarMovies_carStock on CarStock {\n    id\n    video {\n      id\n      url\n      thumbnailUrl\n    }\n    ...CarMoviePopup_carStock\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ClosedCarStocksIndexComponent($first: Int, $salesStatus: CarSalesStatusEnum, $prefectureCode: Int) {\n    carStocks(first: $first, salesStatus: $salesStatus) {\n      edges {\n        node {\n          id\n          landTransportCost(prefectureCode: $prefectureCode)\n          ...CarsIndex_carStock\n        }\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n"): (typeof documents)["\n  query ClosedCarStocksIndexComponent($first: Int, $salesStatus: CarSalesStatusEnum, $prefectureCode: Int) {\n    carStocks(first: $first, salesStatus: $salesStatus) {\n      edges {\n        node {\n          id\n          landTransportCost(prefectureCode: $prefectureCode)\n          ...CarsIndex_carStock\n        }\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query HasVideoCarStocksIndexComponent($first: Int, $salesStatus: CarSalesStatusEnum, $hasVideo: Boolean) {\n    carStocks(first: $first, salesStatus: $salesStatus, hasVideo: $hasVideo) {\n      edges {\n        node {\n          id\n          ...CarsIndex_carStock\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query HasVideoCarStocksIndexComponent($first: Int, $salesStatus: CarSalesStatusEnum, $hasVideo: Boolean) {\n    carStocks(first: $first, salesStatus: $salesStatus, hasVideo: $hasVideo) {\n      edges {\n        node {\n          id\n          ...CarsIndex_carStock\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment CarsIndex_carStock on CarStock {\n    id\n    ...CarCardGroup_carStock\n    ...CarIndex_CarMovies_carStock\n    ...CarsSection_carStock\n  }\n"): (typeof documents)["\n  fragment CarsIndex_carStock on CarStock {\n    id\n    ...CarCardGroup_carStock\n    ...CarIndex_CarMovies_carStock\n    ...CarsSection_carStock\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment CarsSection_carStock on CarStock {\n    id\n    ...SmallCarCard_carStock\n  }\n"): (typeof documents)["\n  fragment CarsSection_carStock on CarStock {\n    id\n    ...SmallCarCard_carStock\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment CarCard_carStock on CarStock {\n    id\n    ulid\n    mileageKm\n    carModelName\n    gradeName\n    salesStatus\n    tags {\n      id\n      name\n    }\n    promotion {\n      id\n      catchCopy\n      staffComment\n      postedBy {\n        id\n        imageUrl\n      }\n    }\n    video {\n      id\n      url\n      thumbnailUrl\n    }\n  }\n"): (typeof documents)["\n  fragment CarCard_carStock on CarStock {\n    id\n    ulid\n    mileageKm\n    carModelName\n    gradeName\n    salesStatus\n    tags {\n      id\n      name\n    }\n    promotion {\n      id\n      catchCopy\n      staffComment\n      postedBy {\n        id\n        imageUrl\n      }\n    }\n    video {\n      id\n      url\n      thumbnailUrl\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment CarMovies_carStock on CarStock {\n    id\n    ...CarCard_carStock\n    ...CarMoviePopup_carStock\n  }\n"): (typeof documents)["\n  fragment CarMovies_carStock on CarStock {\n    id\n    ...CarCard_carStock\n    ...CarMoviePopup_carStock\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query CarsMoviesComponent(\n    $first: Int\n    $after: String\n    $hasVideo: Boolean\n    $salesStatus: CarSalesStatusEnum\n    $prefectureCode: Int\n  ) {\n    carStocks(first: $first, after: $after, hasVideo: $hasVideo, salesStatus: $salesStatus) {\n      edges {\n        node {\n          id\n          landTransportCost(prefectureCode: $prefectureCode)\n          ...CarMovies_carStock\n        }\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n"): (typeof documents)["\n  query CarsMoviesComponent(\n    $first: Int\n    $after: String\n    $hasVideo: Boolean\n    $salesStatus: CarSalesStatusEnum\n    $prefectureCode: Int\n  ) {\n    carStocks(first: $first, after: $after, hasVideo: $hasVideo, salesStatus: $salesStatus) {\n      edges {\n        node {\n          id\n          landTransportCost(prefectureCode: $prefectureCode)\n          ...CarMovies_carStock\n        }\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment CarsSearch_carStock on CarStock {\n    id\n    ...OneColumnCarCard_carStock\n    ...TwoColumnCarCard_carStock\n  }\n"): (typeof documents)["\n  fragment CarsSearch_carStock on CarStock {\n    id\n    ...OneColumnCarCard_carStock\n    ...TwoColumnCarCard_carStock\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment OneColumnCarCard_carStock on CarStock {\n    id\n    ulid\n    carModelYear\n    mileageKm\n    makeName\n    carModelName\n    gradeName\n    totalPaymentAmount\n    salesStatus\n    publishedAt\n    imageUrls\n    oneClickContractCounts\n    landTransportCosts {\n      cost\n      prefectureCode\n    }\n    promotion {\n      id\n      catchCopy\n      staffComment\n      postedBy {\n        id\n        imageUrl\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment OneColumnCarCard_carStock on CarStock {\n    id\n    ulid\n    carModelYear\n    mileageKm\n    makeName\n    carModelName\n    gradeName\n    totalPaymentAmount\n    salesStatus\n    publishedAt\n    imageUrls\n    oneClickContractCounts\n    landTransportCosts {\n      cost\n      prefectureCode\n    }\n    promotion {\n      id\n      catchCopy\n      staffComment\n      postedBy {\n        id\n        imageUrl\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment TwoColumnCarCard_carStock on CarStock {\n    id\n    ulid\n    carModelYear\n    mileageKm\n    makeName\n    carModelName\n    gradeName\n    totalPaymentAmount\n    salesStatus\n    publishedAt\n    imageUrls\n    oneClickContractCounts\n    tags {\n      id\n      name\n    }\n    landTransportCosts {\n      cost\n      prefectureCode\n    }\n  }\n"): (typeof documents)["\n  fragment TwoColumnCarCard_carStock on CarStock {\n    id\n    ulid\n    carModelYear\n    mileageKm\n    makeName\n    carModelName\n    gradeName\n    totalPaymentAmount\n    salesStatus\n    publishedAt\n    imageUrls\n    oneClickContractCounts\n    tags {\n      id\n      name\n    }\n    landTransportCosts {\n      cost\n      prefectureCode\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment CarEquipment_carStock on CarStock {\n    id\n    mainCarStockEquipments {\n      key\n      value\n      text\n    }\n    subCarStockEquipments {\n      active\n      text\n      group\n    }\n  }\n"): (typeof documents)["\n  fragment CarEquipment_carStock on CarStock {\n    id\n    mainCarStockEquipments {\n      key\n      value\n      text\n    }\n    subCarStockEquipments {\n      active\n      text\n      group\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment CarFeature_carStock on CarStock {\n    id\n    carModelYear\n    mileageKm\n    inspectionExpiryDate\n    carStockDetail {\n      id\n      repairHistoryCategory\n    }\n    promotion {\n      id\n      catchCopy\n      description\n    }\n    dealerBranch {\n      id\n      prefectureName\n    }\n  }\n"): (typeof documents)["\n  fragment CarFeature_carStock on CarStock {\n    id\n    carModelYear\n    mileageKm\n    inspectionExpiryDate\n    carStockDetail {\n      id\n      repairHistoryCategory\n    }\n    promotion {\n      id\n      catchCopy\n      description\n    }\n    dealerBranch {\n      id\n      prefectureName\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment CarFooter_carStock on CarStock {\n    id\n    ulid\n    makeName\n    carModelName\n    gradeName\n    totalPaymentAmount\n    salesPrice\n    otherPrice\n    salesStatus\n    landTransportCosts {\n      cost\n      prefectureCode\n    }\n  }\n"): (typeof documents)["\n  fragment CarFooter_carStock on CarStock {\n    id\n    ulid\n    makeName\n    carModelName\n    gradeName\n    totalPaymentAmount\n    salesPrice\n    otherPrice\n    salesStatus\n    landTransportCosts {\n      cost\n      prefectureCode\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment CarImages_carStock on CarStock {\n    id\n    images {\n      id\n      url\n      comment\n    }\n    video {\n      id\n      url\n      thumbnailUrl\n    }\n    ...CarMoviePopup_carStock\n  }\n"): (typeof documents)["\n  fragment CarImages_carStock on CarStock {\n    id\n    images {\n      id\n      url\n      comment\n    }\n    video {\n      id\n      url\n      thumbnailUrl\n    }\n    ...CarMoviePopup_carStock\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment CarScore_carStock on CarStock {\n    id\n    vihicleConditionReportUrl\n    carStockDetail {\n      evaluationScore\n      interiorEvaluationScore\n    }\n    promotion {\n      id\n      staffComment\n      scores {\n        axis\n        score\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment CarScore_carStock on CarStock {\n    id\n    vihicleConditionReportUrl\n    carStockDetail {\n      evaluationScore\n      interiorEvaluationScore\n    }\n    promotion {\n      id\n      staffComment\n      scores {\n        axis\n        score\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment CarSpec_carStock on CarStock {\n    id\n    slideDoorSpecText\n    carStockDetail {\n      seatingCapacity\n      displaySystematicColor\n      transmissionTypeGroup\n      engineDisplacement\n      fuelTypeText\n    }\n  }\n"): (typeof documents)["\n  fragment CarSpec_carStock on CarStock {\n    id\n    slideDoorSpecText\n    carStockDetail {\n      seatingCapacity\n      displaySystematicColor\n      transmissionTypeGroup\n      engineDisplacement\n      fuelTypeText\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment CarStaff_carStock on CarStock {\n    id\n    promotion {\n      id\n      postedBy {\n        id\n        lastName\n        imageUrl\n        comment\n        slug\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment CarStaff_carStock on CarStock {\n    id\n    promotion {\n      id\n      postedBy {\n        id\n        lastName\n        imageUrl\n        comment\n        slug\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query CarsListComponent(\n    $prefectureCode: Int\n    $first: Int\n    $after: String\n    $salesStatus: CarSalesStatusEnum\n    $bodyType: CarStockBodyTypeEnum\n  ) {\n    carStocks(first: $first, after: $after, salesStatus: $salesStatus, bodyType: $bodyType) {\n      edges {\n        node {\n          id\n          landTransportCost(prefectureCode: $prefectureCode)\n          ...CarCardGroup_carStock\n        }\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n"): (typeof documents)["\n  query CarsListComponent(\n    $prefectureCode: Int\n    $first: Int\n    $after: String\n    $salesStatus: CarSalesStatusEnum\n    $bodyType: CarStockBodyTypeEnum\n  ) {\n    carStocks(first: $first, after: $after, salesStatus: $salesStatus, bodyType: $bodyType) {\n      edges {\n        node {\n          id\n          landTransportCost(prefectureCode: $prefectureCode)\n          ...CarCardGroup_carStock\n        }\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment CarsShow_carStock on CarStock {\n    id\n    ulid\n    salesStatus\n    makeName\n    carModelName\n    carModelYear\n    bodyType\n    oneClickContractCounts\n    ...CarFeature_carStock\n    ...CarFooter_carStock\n    ...CarImages_carStock\n    ...CarScore_carStock\n    ...CarEquipment_carStock\n    ...CarStaff_carStock\n    ...CarSpec_carStock\n  }\n"): (typeof documents)["\n  fragment CarsShow_carStock on CarStock {\n    id\n    ulid\n    salesStatus\n    makeName\n    carModelName\n    carModelYear\n    bodyType\n    oneClickContractCounts\n    ...CarFeature_carStock\n    ...CarFooter_carStock\n    ...CarImages_carStock\n    ...CarScore_carStock\n    ...CarEquipment_carStock\n    ...CarStaff_carStock\n    ...CarSpec_carStock\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation ViewCarStock($input: ViewCarStockInput!) {\n    viewCarStock(input: $input) {\n      errors {\n        message\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation ViewCarStock($input: ViewCarStockInput!) {\n    viewCarStock(input: $input) {\n      errors {\n        message\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query InquiresNewComponent($ulids: [String!], $prefectureCode: Int) {\n    carStocks(ulids: $ulids) {\n      nodes {\n        id\n        landTransportCost(prefectureCode: $prefectureCode)\n        ...SmallCarCard_carStock\n      }\n    }\n  }\n"): (typeof documents)["\n  query InquiresNewComponent($ulids: [String!], $prefectureCode: Int) {\n    carStocks(ulids: $ulids) {\n      nodes {\n        id\n        landTransportCost(prefectureCode: $prefectureCode)\n        ...SmallCarCard_carStock\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation InquiresNewInquireCarStock($input: InquireCarStocksInput!) {\n    inquireCarStocks(input: $input) {\n      inquiry {\n        id\n        user {\n          id\n          firstName\n          lastName\n          zipCode\n          inquiries {\n            id\n            carStocks {\n              id\n            }\n          }\n        }\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation InquiresNewInquireCarStock($input: InquireCarStocksInput!) {\n    inquireCarStocks(input: $input) {\n      inquiry {\n        id\n        user {\n          id\n          firstName\n          lastName\n          zipCode\n          inquiries {\n            id\n            carStocks {\n              id\n            }\n          }\n        }\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ContentLinkShow($key: String!) {\n    contentLink(key: $key) {\n      id\n      contentable {\n        urlToRedirect\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  query ContentLinkShow($key: String!) {\n    contentLink(key: $key) {\n      id\n      contentable {\n        urlToRedirect\n        id\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query MypageCallbackPage {\n    viewer {\n      id\n      lastName\n      firstName\n      publicDeals {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  query MypageCallbackPage {\n    viewer {\n      id\n      lastName\n      firstName\n      publicDeals {\n        id\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateUserName_UserEdit($input: UpdateUserNameInput!) {\n    updateUserName(input: $input) {\n      user {\n        id\n        lastName\n        firstName\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateUserName_UserEdit($input: UpdateUserNameInput!) {\n    updateUserName(input: $input) {\n      user {\n        id\n        lastName\n        firstName\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment DealStatus_deal on Deal {\n    id\n    dealTasks {\n      id\n      taskType\n      completed\n    }\n    mypageDealTasks {\n      type\n      group\n      status\n    }\n    ...TaskList_deal\n  }\n"): (typeof documents)["\n  fragment DealStatus_deal on Deal {\n    id\n    dealTasks {\n      id\n      taskType\n      completed\n    }\n    mypageDealTasks {\n      type\n      group\n      status\n    }\n    ...TaskList_deal\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query MypageDealShowPage($dealId: ID!) {\n    deal(id: $dealId) {\n      id\n      user {\n        id\n        fullName\n      }\n      salesStaff {\n        id\n        fullName\n        imageUrl\n      }\n      ...NotificationCard_deal\n      ...OrderCar_deal\n      ...DealStatus_deal\n      ...InspectionCertificate_deal\n      ...PaymentCard_deal\n    }\n  }\n"): (typeof documents)["\n  query MypageDealShowPage($dealId: ID!) {\n    deal(id: $dealId) {\n      id\n      user {\n        id\n        fullName\n      }\n      salesStaff {\n        id\n        fullName\n        imageUrl\n      }\n      ...NotificationCard_deal\n      ...OrderCar_deal\n      ...DealStatus_deal\n      ...InspectionCertificate_deal\n      ...PaymentCard_deal\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment InspectionCertificate_deal on Deal {\n    id\n    dealAttachments {\n      id\n      attachmentType\n      url\n    }\n  }\n"): (typeof documents)["\n  fragment InspectionCertificate_deal on Deal {\n    id\n    dealAttachments {\n      id\n      attachmentType\n      url\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment NotificationCard_deal on Deal {\n    id\n    mypageDealNotice\n  }\n"): (typeof documents)["\n  fragment NotificationCard_deal on Deal {\n    id\n    mypageDealNotice\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment OrderCar_deal on Deal {\n    id\n    carStock {\n      id\n      carModelYear\n      mileageKm\n      carModelName\n      gradeName\n      makeName\n      images {\n        id\n        url\n      }\n    }\n    dealDetail {\n      id\n      deliveryDate\n      deliveryTimeRangeText\n    }\n  }\n"): (typeof documents)["\n  fragment OrderCar_deal on Deal {\n    id\n    carStock {\n      id\n      carModelYear\n      mileageKm\n      carModelName\n      gradeName\n      makeName\n      images {\n        id\n        url\n      }\n    }\n    dealDetail {\n      id\n      deliveryDate\n      deliveryTimeRangeText\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment PaymentCard_deal on Deal {\n    id\n    payment {\n      id\n      deadline\n      paymentMethod\n      amount\n      status\n    }\n    salesStaff {\n      id\n      dealerBranch {\n        id\n        bank {\n          bankName\n          accountType\n          accountNumber\n          accountName\n          branchCode\n          branchName\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment PaymentCard_deal on Deal {\n    id\n    payment {\n      id\n      deadline\n      paymentMethod\n      amount\n      status\n    }\n    salesStaff {\n      id\n      dealerBranch {\n        id\n        bank {\n          bankName\n          accountType\n          accountNumber\n          accountName\n          branchCode\n          branchName\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment TaskList_deal on Deal {\n    id\n    dealContractDeadline\n    dealContractSentEmail\n    dealTasks {\n      id\n      taskType\n      completed\n    }\n    dealDetail {\n      id\n      expectedCarRegistrationCompletionDate\n      deliveryDate\n      deliveryTimeRangeText\n    }\n    mypageDealTasks {\n      type\n      group\n      status\n      title\n    }\n    dealContract {\n      id\n      userAuthenticationMethod\n      deadline\n      email\n      smsTel\n    }\n  }\n"): (typeof documents)["\n  fragment TaskList_deal on Deal {\n    id\n    dealContractDeadline\n    dealContractSentEmail\n    dealTasks {\n      id\n      taskType\n      completed\n    }\n    dealDetail {\n      id\n      expectedCarRegistrationCompletionDate\n      deliveryDate\n      deliveryTimeRangeText\n    }\n    mypageDealTasks {\n      type\n      group\n      status\n      title\n    }\n    dealContract {\n      id\n      userAuthenticationMethod\n      deadline\n      email\n      smsTel\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment OwnedCarEdit_ownedCar on OwnedCar {\n    id\n    color\n    odorStatus\n    odorStatusComment\n    isDirty\n    accidentStatus\n    accidentComment\n    hasMendHistory\n    mendComment\n    comment\n    inspectionCertificateImage {\n      id\n      url\n    }\n    options {\n      id\n      optionType\n    }\n  }\n"): (typeof documents)["\n  fragment OwnedCarEdit_ownedCar on OwnedCar {\n    id\n    color\n    odorStatus\n    odorStatusComment\n    isDirty\n    accidentStatus\n    accidentComment\n    hasMendHistory\n    mendComment\n    comment\n    inspectionCertificateImage {\n      id\n      url\n    }\n    options {\n      id\n      optionType\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query OwnedCarEditQuery($id: ID!) {\n    ownedCar(id: $id) {\n      id\n      ...OwnedCarEdit_ownedCar\n    }\n  }\n"): (typeof documents)["\n  query OwnedCarEditQuery($id: ID!) {\n    ownedCar(id: $id) {\n      id\n      ...OwnedCarEdit_ownedCar\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query OwnedCarEdit_OwnedCarOptionOptions {\n    ownedCarOptionOptions {\n      optionType\n      optionTypeText\n    }\n  }\n"): (typeof documents)["\n  query OwnedCarEdit_OwnedCarOptionOptions {\n    ownedCarOptionOptions {\n      optionType\n      optionTypeText\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation OwnedCarEdit_UpdateOwnedCar($input: UpdateOwnedCarInput!) {\n    updateOwnedCar(input: $input) {\n      ownedCar {\n        id\n        ...OwnedCarEdit_ownedCar\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation OwnedCarEdit_UpdateOwnedCar($input: UpdateOwnedCarInput!) {\n    updateOwnedCar(input: $input) {\n      ownedCar {\n        id\n        ...OwnedCarEdit_ownedCar\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment OwnedCarEditBase_ownedCar on OwnedCar {\n    id\n    makeName\n    carModelName\n    firstRegistrationYear\n    gradeName\n    carOrigin\n    driveSystem\n    latestMileageKm\n  }\n"): (typeof documents)["\n  fragment OwnedCarEditBase_ownedCar on OwnedCar {\n    id\n    makeName\n    carModelName\n    firstRegistrationYear\n    gradeName\n    carOrigin\n    driveSystem\n    latestMileageKm\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query OwnedCarEditBaseQuery($id: ID!) {\n    ownedCar(id: $id) {\n      id\n      ...OwnedCarEditBase_ownedCar\n    }\n  }\n"): (typeof documents)["\n  query OwnedCarEditBaseQuery($id: ID!) {\n    ownedCar(id: $id) {\n      id\n      ...OwnedCarEditBase_ownedCar\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation OwnedCarEditBase_UpdateOwnedCar($input: UpdateOwnedCarInput!) {\n    updateOwnedCar(input: $input) {\n      ownedCar {\n        id\n        ...OwnedCarEditBase_ownedCar\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation OwnedCarEditBase_UpdateOwnedCar($input: UpdateOwnedCarInput!) {\n    updateOwnedCar(input: $input) {\n      ownedCar {\n        id\n        ...OwnedCarEditBase_ownedCar\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment OwnedCarShow_CarBaseTable_ownedCar on OwnedCar {\n    id\n    makeName\n    carModelName\n    firstRegistrationYear\n    gradeName\n    editDisabled\n    carOriginText\n    driveSystemText\n    isProhibitUpdateFromUser\n    latestMileageKm\n  }\n"): (typeof documents)["\n  fragment OwnedCarShow_CarBaseTable_ownedCar on OwnedCar {\n    id\n    makeName\n    carModelName\n    firstRegistrationYear\n    gradeName\n    editDisabled\n    carOriginText\n    driveSystemText\n    isProhibitUpdateFromUser\n    latestMileageKm\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment OwnedCarShow_CarCard_ownedCar on OwnedCar {\n    id\n    color\n    odorStatus\n    odorStatusText\n    odorStatusComment\n    isDirty\n    accidentStatus\n    accidentStatusText\n    accidentComment\n    hasMendHistory\n    mendComment\n    comment\n    editDisabled\n    isProhibitUpdateFromUser\n    inspectionCertificateImage {\n      id\n      url\n    }\n    options {\n      id\n      optionTypeText\n    }\n  }\n"): (typeof documents)["\n  fragment OwnedCarShow_CarCard_ownedCar on OwnedCar {\n    id\n    color\n    odorStatus\n    odorStatusText\n    odorStatusComment\n    isDirty\n    accidentStatus\n    accidentStatusText\n    accidentComment\n    hasMendHistory\n    mendComment\n    comment\n    editDisabled\n    isProhibitUpdateFromUser\n    inspectionCertificateImage {\n      id\n      url\n    }\n    options {\n      id\n      optionTypeText\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment OwnedCarShow_CarImagesCard_ownedCar on OwnedCar {\n    id\n    editDisabled\n    isProhibitUpdateFromUser\n    imagesForIndex {\n      id\n      url\n      imageType\n    }\n  }\n"): (typeof documents)["\n  fragment OwnedCarShow_CarImagesCard_ownedCar on OwnedCar {\n    id\n    editDisabled\n    isProhibitUpdateFromUser\n    imagesForIndex {\n      id\n      url\n      imageType\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query OwnedCarShow_CarImagesCard_OwnedCarImageTypes {\n    ownedCarImageTypes {\n      imageType\n      imageTypeText\n    }\n  }\n"): (typeof documents)["\n  query OwnedCarShow_CarImagesCard_OwnedCarImageTypes {\n    ownedCarImageTypes {\n      imageType\n      imageTypeText\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query OwnedCarShow_CarImagesCard_OwnedCar($id: ID!) {\n    ownedCar(id: $id) {\n      id\n      imagesForIndex {\n        id\n        url\n        imageType\n      }\n    }\n  }\n"): (typeof documents)["\n  query OwnedCarShow_CarImagesCard_OwnedCar($id: ID!) {\n    ownedCar(id: $id) {\n      id\n      imagesForIndex {\n        id\n        url\n        imageType\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation DeleteOwnedCar($input: DeleteOwnedCarInput!) {\n    deleteOwnedCar(input: $input) {\n      isSuccess\n    }\n  }\n"): (typeof documents)["\n  mutation DeleteOwnedCar($input: DeleteOwnedCarInput!) {\n    deleteOwnedCar(input: $input) {\n      isSuccess\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment OwnedCarShow_CurrentCarValuationButton_ownedCar on OwnedCar {\n    id\n    carEstimateValuations {\n      id\n      ulid\n      status\n      createdAt\n    }\n    carOfficialValuations {\n      id\n      ulid\n      status\n      createdAt\n    }\n  }\n"): (typeof documents)["\n  fragment OwnedCarShow_CurrentCarValuationButton_ownedCar on OwnedCar {\n    id\n    carEstimateValuations {\n      id\n      ulid\n      status\n      createdAt\n    }\n    carOfficialValuations {\n      id\n      ulid\n      status\n      createdAt\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query OwnedCarShow($id: ID!) {\n    ownedCar(id: $id) {\n      id\n      ...OwnedCarShow_CarBaseTable_ownedCar\n      ...CarDetailTable_ownedCar\n      ...OwnedCarShow_CarCard_ownedCar\n      ...OwnedCarShow_CarImagesCard_ownedCar\n      ...OwnedCarShow_CurrentCarValuationButton_ownedCar\n    }\n  }\n"): (typeof documents)["\n  query OwnedCarShow($id: ID!) {\n    ownedCar(id: $id) {\n      id\n      ...OwnedCarShow_CarBaseTable_ownedCar\n      ...CarDetailTable_ownedCar\n      ...OwnedCarShow_CarCard_ownedCar\n      ...OwnedCarShow_CarImagesCard_ownedCar\n      ...OwnedCarShow_CurrentCarValuationButton_ownedCar\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query Viewer {\n    viewer {\n      id\n      fullName\n    }\n  }\n"): (typeof documents)["\n  query Viewer {\n    viewer {\n      id\n      fullName\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query OwnedCars {\n    ownedCars {\n      id\n      makeName\n      carModelName\n      gradeName\n      firstRegistrationYear\n      images {\n        id\n        url\n        imageType\n      }\n      carEstimateValuations {\n        id\n        __typename\n        ulid\n        status\n        createdAt\n        expiredPriceValidDeadline\n      }\n      carOfficialValuations {\n        id\n        __typename\n        ulid\n        status\n        createdAt\n        expiredPriceValidDeadline\n        isAccepted\n        offers {\n          id\n          isAccepted\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query OwnedCars {\n    ownedCars {\n      id\n      makeName\n      carModelName\n      gradeName\n      firstRegistrationYear\n      images {\n        id\n        url\n        imageType\n      }\n      carEstimateValuations {\n        id\n        __typename\n        ulid\n        status\n        createdAt\n        expiredPriceValidDeadline\n      }\n      carOfficialValuations {\n        id\n        __typename\n        ulid\n        status\n        createdAt\n        expiredPriceValidDeadline\n        isAccepted\n        offers {\n          id\n          isAccepted\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment CarEstimateValuationEdit_BaseEdit_ownedCar on OwnedCar {\n    id\n    makeName\n    carModelName\n    gradeName\n    firstRegistrationYear\n  }\n"): (typeof documents)["\n  fragment CarEstimateValuationEdit_BaseEdit_ownedCar on OwnedCar {\n    id\n    makeName\n    carModelName\n    gradeName\n    firstRegistrationYear\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment CarEstimateValuationEdit_BaseEdit_carEstimateValuation on CarEstimateValuation {\n    id\n    ulid\n    ownedCar {\n      id\n      ...CarEstimateValuationEdit_BaseEdit_ownedCar\n    }\n  }\n"): (typeof documents)["\n  fragment CarEstimateValuationEdit_BaseEdit_carEstimateValuation on CarEstimateValuation {\n    id\n    ulid\n    ownedCar {\n      id\n      ...CarEstimateValuationEdit_BaseEdit_ownedCar\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CarEstimateValuationEdit_BaseEdit_UpdateOwnedCar($input: UpdateOwnedCarInput!) {\n    updateOwnedCar(input: $input) {\n      ownedCar {\n        id\n        ...CarEstimateValuationEdit_BaseEdit_ownedCar\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation CarEstimateValuationEdit_BaseEdit_UpdateOwnedCar($input: UpdateOwnedCarInput!) {\n    updateOwnedCar(input: $input) {\n      ownedCar {\n        id\n        ...CarEstimateValuationEdit_BaseEdit_ownedCar\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query CarEstimateValuationEdit_BaseEditQuery($ownedCarId: ID!, $ulid: String!) {\n    carEstimateValuation(ownedCarId: $ownedCarId, ulid: $ulid) {\n      id\n      ...CarEstimateValuationEdit_BaseEdit_carEstimateValuation\n    }\n  }\n"): (typeof documents)["\n  query CarEstimateValuationEdit_BaseEditQuery($ownedCarId: ID!, $ulid: String!) {\n    carEstimateValuation(ownedCarId: $ownedCarId, ulid: $ulid) {\n      id\n      ...CarEstimateValuationEdit_BaseEdit_carEstimateValuation\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment CarEstimateValuationEdit_CarEdit_ownedCar on OwnedCar {\n    id\n    odorStatus\n    odorStatusText\n    odorStatusComment\n    accidentStatus\n    accidentStatusText\n    accidentComment\n    latestMileageKm\n    inspectionCertificateImage {\n      id\n      url\n    }\n  }\n"): (typeof documents)["\n  fragment CarEstimateValuationEdit_CarEdit_ownedCar on OwnedCar {\n    id\n    odorStatus\n    odorStatusText\n    odorStatusComment\n    accidentStatus\n    accidentStatusText\n    accidentComment\n    latestMileageKm\n    inspectionCertificateImage {\n      id\n      url\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment CarEstimateValuationEdit_CarEdit_carEstimateValuation on CarEstimateValuation {\n    id\n    ulid\n    ownedCar {\n      id\n      ...CarEstimateValuationEdit_CarEdit_ownedCar\n    }\n  }\n"): (typeof documents)["\n  fragment CarEstimateValuationEdit_CarEdit_carEstimateValuation on CarEstimateValuation {\n    id\n    ulid\n    ownedCar {\n      id\n      ...CarEstimateValuationEdit_CarEdit_ownedCar\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CarEstimateValuationEdit_CarEdit_UpdateOwnedCar($input: UpdateOwnedCarInput!) {\n    updateOwnedCar(input: $input) {\n      ownedCar {\n        id\n        ...CarEstimateValuationEdit_CarEdit_ownedCar\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation CarEstimateValuationEdit_CarEdit_UpdateOwnedCar($input: UpdateOwnedCarInput!) {\n    updateOwnedCar(input: $input) {\n      ownedCar {\n        id\n        ...CarEstimateValuationEdit_CarEdit_ownedCar\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query CarEstimateValuationEdit_CarEditQuery($ownedCarId: ID!, $ulid: String!) {\n    carEstimateValuation(ownedCarId: $ownedCarId, ulid: $ulid) {\n      id\n      ...CarEstimateValuationEdit_CarEdit_carEstimateValuation\n    }\n  }\n"): (typeof documents)["\n  query CarEstimateValuationEdit_CarEditQuery($ownedCarId: ID!, $ulid: String!) {\n    carEstimateValuation(ownedCarId: $ownedCarId, ulid: $ulid) {\n      id\n      ...CarEstimateValuationEdit_CarEdit_carEstimateValuation\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment CarEstimateValuationShow_AutoCompleteView_AishameterButton_carLog on CarLog {\n    id\n    makeName\n    carModelName\n    gradeName\n    firstRegistrationYear\n    minMileageKm\n    maxMileageKm\n    ownedCarId\n  }\n"): (typeof documents)["\n  fragment CarEstimateValuationShow_AutoCompleteView_AishameterButton_carLog on CarLog {\n    id\n    makeName\n    carModelName\n    gradeName\n    firstRegistrationYear\n    minMileageKm\n    maxMileageKm\n    ownedCarId\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment CarEstimateValuationShow_AutoCompleteView_carLog on CarLog {\n    id\n    makeName\n    carModelName\n    gradeName\n    firstRegistrationYear\n    minMileageKm\n    maxMileageKm\n  }\n"): (typeof documents)["\n  fragment CarEstimateValuationShow_AutoCompleteView_carLog on CarLog {\n    id\n    makeName\n    carModelName\n    gradeName\n    firstRegistrationYear\n    minMileageKm\n    maxMileageKm\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment CarEstimateValuationShow_AutoCompleteView_OwnedCarEditModal_carEstimateValuation on CarEstimateValuation {\n    id\n    ownedCar {\n      id\n      makeName\n      carModelName\n      gradeName\n      firstRegistrationYear\n      editDisabled\n    }\n    carLog {\n      id\n      makeName\n      carModelName\n      gradeName\n      firstRegistrationYear\n    }\n  }\n"): (typeof documents)["\n  fragment CarEstimateValuationShow_AutoCompleteView_OwnedCarEditModal_carEstimateValuation on CarEstimateValuation {\n    id\n    ownedCar {\n      id\n      makeName\n      carModelName\n      gradeName\n      firstRegistrationYear\n      editDisabled\n    }\n    carLog {\n      id\n      makeName\n      carModelName\n      gradeName\n      firstRegistrationYear\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment CarEstimateValuationShow_AutoCompleteView_carEstimateValuation on CarEstimateValuation {\n    id\n    ownedCarId\n    ...CardHeader_carEstimateValuation\n    ...CarEstimateValuationShow_AutoCompleteView_OwnedCarEditModal_carEstimateValuation\n    ...PriceView_carEstimateValuation\n    carLog {\n      id\n      ...CarEstimateValuationShow_AutoCompleteView_carLog\n      ...CarEstimateValuationShow_AutoCompleteView_AishameterButton_carLog\n    }\n    ownedCar {\n      id\n      isProhibitCreateCarOfficialValuation\n      ...OfficialValuationGuideView_ownedCar\n    }\n  }\n"): (typeof documents)["\n  fragment CarEstimateValuationShow_AutoCompleteView_carEstimateValuation on CarEstimateValuation {\n    id\n    ownedCarId\n    ...CardHeader_carEstimateValuation\n    ...CarEstimateValuationShow_AutoCompleteView_OwnedCarEditModal_carEstimateValuation\n    ...PriceView_carEstimateValuation\n    carLog {\n      id\n      ...CarEstimateValuationShow_AutoCompleteView_carLog\n      ...CarEstimateValuationShow_AutoCompleteView_AishameterButton_carLog\n    }\n    ownedCar {\n      id\n      isProhibitCreateCarOfficialValuation\n      ...OfficialValuationGuideView_ownedCar\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation AutoCompleteView_OwnedCarEdit_UpdateOwnedCar($input: UpdateOwnedCarInput!) {\n    updateOwnedCar(input: $input) {\n      ownedCar {\n        id\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation AutoCompleteView_OwnedCarEdit_UpdateOwnedCar($input: UpdateOwnedCarInput!) {\n    updateOwnedCar(input: $input) {\n      ownedCar {\n        id\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment CarEstimateValuationShow_Body_carEstimateValuation on CarEstimateValuation {\n    id\n    ownedCarId\n    status\n    ...CarEstimateValuationShow_InProgressCard_carEstimateValuation\n    ...CarEstimateValuationShow_CarBaseCard_carEstimateValuation\n    ...CarEstimateValuationShow_CarCard_carEstimateValuation\n    ...CarEstimateValuationShow_CarFavoriteImageCard_carEstimateValuation\n    ...CarEstimateValuationShow_OtherCard_carEstimateValuation\n    ...CarEstimateValuationShow_CompleteCard_carEstimateValuation\n    ...CarEstimateValuationShow_CancelCard_carEstimateValuation\n    ownedCar {\n      id\n      makeName\n      carModelName\n      firstRegistrationYear\n      gradeName\n      ...OfficialValuationGuideView_ownedCar\n    }\n  }\n"): (typeof documents)["\n  fragment CarEstimateValuationShow_Body_carEstimateValuation on CarEstimateValuation {\n    id\n    ownedCarId\n    status\n    ...CarEstimateValuationShow_InProgressCard_carEstimateValuation\n    ...CarEstimateValuationShow_CarBaseCard_carEstimateValuation\n    ...CarEstimateValuationShow_CarCard_carEstimateValuation\n    ...CarEstimateValuationShow_CarFavoriteImageCard_carEstimateValuation\n    ...CarEstimateValuationShow_OtherCard_carEstimateValuation\n    ...CarEstimateValuationShow_CompleteCard_carEstimateValuation\n    ...CarEstimateValuationShow_CancelCard_carEstimateValuation\n    ownedCar {\n      id\n      makeName\n      carModelName\n      firstRegistrationYear\n      gradeName\n      ...OfficialValuationGuideView_ownedCar\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment CarEstimateValuationShow_CancelCard_carEstimateValuation on CarEstimateValuation {\n    id\n    ownedCar {\n      id\n      ...OfficialValuationGuideView_ownedCar\n    }\n  }\n"): (typeof documents)["\n  fragment CarEstimateValuationShow_CancelCard_carEstimateValuation on CarEstimateValuation {\n    id\n    ownedCar {\n      id\n      ...OfficialValuationGuideView_ownedCar\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment CarEstimateValuationShow_CarBaseCard_carEstimateValuation on CarEstimateValuation {\n    id\n    ulid\n    ownedCar {\n      id\n      makeName\n      carModelName\n      firstRegistrationYear\n      gradeName\n      mainImage {\n        id\n        url\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment CarEstimateValuationShow_CarBaseCard_carEstimateValuation on CarEstimateValuation {\n    id\n    ulid\n    ownedCar {\n      id\n      makeName\n      carModelName\n      firstRegistrationYear\n      gradeName\n      mainImage {\n        id\n        url\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment CarEstimateValuationShow_CarCard_carEstimateValuation on CarEstimateValuation {\n    id\n    ulid\n    ownedCar {\n      id\n      odorStatusText\n      odorStatusComment\n      accidentStatusText\n      accidentComment\n      latestMileageKm\n      inspectionCertificateImage {\n        id\n        url\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment CarEstimateValuationShow_CarCard_carEstimateValuation on CarEstimateValuation {\n    id\n    ulid\n    ownedCar {\n      id\n      odorStatusText\n      odorStatusComment\n      accidentStatusText\n      accidentComment\n      latestMileageKm\n      inspectionCertificateImage {\n        id\n        url\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query CarEstimateValuationShow($ownedCarId: ID!, $ulid: String!) {\n    carEstimateValuation(ownedCarId: $ownedCarId, ulid: $ulid) {\n      id\n      status\n      completedAuto\n      ...CarEstimateValuationShow_Body_carEstimateValuation\n      ...CarEstimateValuationShow_AutoCompleteView_carEstimateValuation\n    }\n  }\n"): (typeof documents)["\n  query CarEstimateValuationShow($ownedCarId: ID!, $ulid: String!) {\n    carEstimateValuation(ownedCarId: $ownedCarId, ulid: $ulid) {\n      id\n      status\n      completedAuto\n      ...CarEstimateValuationShow_Body_carEstimateValuation\n      ...CarEstimateValuationShow_AutoCompleteView_carEstimateValuation\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment CarEstimateValuationShow_CarFavoriteImageCard_carEstimateValuation on CarEstimateValuation {\n    id\n    ownedCar {\n      id\n      favoriteImage {\n        id\n        url\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment CarEstimateValuationShow_CarFavoriteImageCard_carEstimateValuation on CarEstimateValuation {\n    id\n    ownedCar {\n      id\n      favoriteImage {\n        id\n        url\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CarEstimateValuationShow_CarFavoriteImageCard_UpdateOwnedCar($input: UpdateOwnedCarInput!) {\n    updateOwnedCar(input: $input) {\n      ownedCar {\n        id\n        favoriteImage {\n          id\n          url\n        }\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation CarEstimateValuationShow_CarFavoriteImageCard_UpdateOwnedCar($input: UpdateOwnedCarInput!) {\n    updateOwnedCar(input: $input) {\n      ownedCar {\n        id\n        favoriteImage {\n          id\n          url\n        }\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment CardHeader_carEstimateValuation on CarEstimateValuation {\n    id\n    createdAt\n    completedAt\n  }\n"): (typeof documents)["\n  fragment CardHeader_carEstimateValuation on CarEstimateValuation {\n    id\n    createdAt\n    completedAt\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment CarEstimateValuationShow_CompleteCard_carEstimateValuation on CarEstimateValuation {\n    id\n    createdAt\n    ...PriceView_carEstimateValuation\n    ownedCar {\n      id\n      ...OfficialValuationGuideView_ownedCar\n    }\n  }\n"): (typeof documents)["\n  fragment CarEstimateValuationShow_CompleteCard_carEstimateValuation on CarEstimateValuation {\n    id\n    createdAt\n    ...PriceView_carEstimateValuation\n    ownedCar {\n      id\n      ...OfficialValuationGuideView_ownedCar\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment CarEstimateValuationShow_InProgressCard_carEstimateValuation on CarEstimateValuation {\n    id\n    createdAt\n    ownedCar {\n      id\n      ...OfficialValuationGuideView_ownedCar\n    }\n  }\n"): (typeof documents)["\n  fragment CarEstimateValuationShow_InProgressCard_carEstimateValuation on CarEstimateValuation {\n    id\n    createdAt\n    ownedCar {\n      id\n      ...OfficialValuationGuideView_ownedCar\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment OfficialValuationGuideView_ownedCar on OwnedCar {\n    id\n    isProhibitCreateCarOfficialValuation\n  }\n"): (typeof documents)["\n  fragment OfficialValuationGuideView_ownedCar on OwnedCar {\n    id\n    isProhibitCreateCarOfficialValuation\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment CarEstimateValuationShow_OtherCard_carEstimateValuation on CarEstimateValuation {\n    id\n    ownedCar {\n      id\n      carOriginText\n      driveSystemText\n      color\n      comment\n    }\n    detail {\n      id\n      reasonText\n      reasonComment\n      preferredPrice\n    }\n  }\n"): (typeof documents)["\n  fragment CarEstimateValuationShow_OtherCard_carEstimateValuation on CarEstimateValuation {\n    id\n    ownedCar {\n      id\n      carOriginText\n      driveSystemText\n      color\n      comment\n    }\n    detail {\n      id\n      reasonText\n      reasonComment\n      preferredPrice\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment PriceView_carEstimateValuation on CarEstimateValuation {\n    id\n    detail {\n      id\n      comment\n    }\n    prices {\n      id\n      minPrice\n      maxPrice\n      comment\n    }\n  }\n"): (typeof documents)["\n  fragment PriceView_carEstimateValuation on CarEstimateValuation {\n    id\n    detail {\n      id\n      comment\n    }\n    prices {\n      id\n      minPrice\n      maxPrice\n      comment\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment CarOfficialValuationEdit_BaseEdit_carOfficialValuation on CarOfficialValuation {\n    id\n    ulid\n    ownedCar {\n      id\n      ...CarOfficialValuationEdit_BaseEdit_useBaseEdit_ownedCar\n    }\n  }\n"): (typeof documents)["\n  fragment CarOfficialValuationEdit_BaseEdit_carOfficialValuation on CarOfficialValuation {\n    id\n    ulid\n    ownedCar {\n      id\n      ...CarOfficialValuationEdit_BaseEdit_useBaseEdit_ownedCar\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query CarOfficialValuationEdit_BaseEditQuery($ownedCarId: ID!, $ulid: String!) {\n    carOfficialValuation(ownedCarId: $ownedCarId, ulid: $ulid) {\n      id\n      ...CarOfficialValuationEdit_BaseEdit_carOfficialValuation\n    }\n  }\n"): (typeof documents)["\n  query CarOfficialValuationEdit_BaseEditQuery($ownedCarId: ID!, $ulid: String!) {\n    carOfficialValuation(ownedCarId: $ownedCarId, ulid: $ulid) {\n      id\n      ...CarOfficialValuationEdit_BaseEdit_carOfficialValuation\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment CarOfficialValuationEdit_BaseEdit_useBaseEdit_ownedCar on OwnedCar {\n    id\n    makeName\n    carModelName\n    gradeName\n    firstRegistrationYear\n  }\n"): (typeof documents)["\n  fragment CarOfficialValuationEdit_BaseEdit_useBaseEdit_ownedCar on OwnedCar {\n    id\n    makeName\n    carModelName\n    gradeName\n    firstRegistrationYear\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CarOfficialValuationEdit_BaseEdit_UpdateOwnedCar($input: UpdateOwnedCarInput!) {\n    updateOwnedCar(input: $input) {\n      ownedCar {\n        id\n        ...CarOfficialValuationEdit_BaseEdit_useBaseEdit_ownedCar\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation CarOfficialValuationEdit_BaseEdit_UpdateOwnedCar($input: UpdateOwnedCarInput!) {\n    updateOwnedCar(input: $input) {\n      ownedCar {\n        id\n        ...CarOfficialValuationEdit_BaseEdit_useBaseEdit_ownedCar\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment CarOfficialValuationEdit_Edit_carOfficialValuation on CarOfficialValuation {\n    id\n    ulid\n    ...CarOfficialValuationShared_carOfficialValuation\n  }\n"): (typeof documents)["\n  fragment CarOfficialValuationEdit_Edit_carOfficialValuation on CarOfficialValuation {\n    id\n    ulid\n    ...CarOfficialValuationShared_carOfficialValuation\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query CarOfficialValuationEdit_EditQuery($ownedCarId: ID!, $ulid: String!) {\n    carOfficialValuation(ownedCarId: $ownedCarId, ulid: $ulid) {\n      id\n      ...CarOfficialValuationEdit_Edit_carOfficialValuation\n    }\n  }\n"): (typeof documents)["\n  query CarOfficialValuationEdit_EditQuery($ownedCarId: ID!, $ulid: String!) {\n    carOfficialValuation(ownedCarId: $ownedCarId, ulid: $ulid) {\n      id\n      ...CarOfficialValuationEdit_Edit_carOfficialValuation\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CarOfficialValuationEdit_Edit_useEdit_EditCarOfficialValuation($input: EditCarOfficialValuationInput!) {\n    editCarOfficialValuation(input: $input) {\n      carOfficialValuation {\n        id\n        ...CarOfficialValuationShared_carOfficialValuation\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation CarOfficialValuationEdit_Edit_useEdit_EditCarOfficialValuation($input: EditCarOfficialValuationInput!) {\n    editCarOfficialValuation(input: $input) {\n      carOfficialValuation {\n        id\n        ...CarOfficialValuationShared_carOfficialValuation\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation FindOrCreateCarOfficialValuation($input: FindOrCreateCarOfficialValuationInput!) {\n    findOrCreateCarOfficialValuation(input: $input) {\n      carOfficialValuation {\n        id\n        ulid\n      }\n      isCreate\n    }\n  }\n"): (typeof documents)["\n  mutation FindOrCreateCarOfficialValuation($input: FindOrCreateCarOfficialValuationInput!) {\n    findOrCreateCarOfficialValuation(input: $input) {\n      carOfficialValuation {\n        id\n        ulid\n      }\n      isCreate\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment CarOfficialValuationImagesBody_carOfficialValuation on CarOfficialValuation {\n    id\n    ulid\n    ownedCar {\n      id\n      restRequiredImagesCount\n      fulfilledForCarOfficialValuationByImages\n    }\n  }\n"): (typeof documents)["\n  fragment CarOfficialValuationImagesBody_carOfficialValuation on CarOfficialValuation {\n    id\n    ulid\n    ownedCar {\n      id\n      restRequiredImagesCount\n      fulfilledForCarOfficialValuationByImages\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment CarOfficialValuationImages_DefectView_ImageTypesCard_ownedCarDefect on OwnedCarDefect {\n    id\n    defectType\n    ownedCarImageGroup {\n      groupKey\n      label\n      imageTypes {\n        imageType\n        imageTypeText\n        optional\n      }\n    }\n    ownedCarImages {\n      id\n      url\n      imageType\n      comment\n    }\n  }\n"): (typeof documents)["\n  fragment CarOfficialValuationImages_DefectView_ImageTypesCard_ownedCarDefect on OwnedCarDefect {\n    id\n    defectType\n    ownedCarImageGroup {\n      groupKey\n      label\n      imageTypes {\n        imageType\n        imageTypeText\n        optional\n      }\n    }\n    ownedCarImages {\n      id\n      url\n      imageType\n      comment\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query CarOfficialValuationImages_DefectView_useImageTypesCard_OwnedCar($id: ID!) {\n    ownedCar(id: $id) {\n      id\n      restRequiredImagesCount\n      fulfilledForCarOfficialValuationByImages\n      imagesForIndex {\n        id\n        url\n        imageType\n        comment\n      }\n    }\n  }\n"): (typeof documents)["\n  query CarOfficialValuationImages_DefectView_useImageTypesCard_OwnedCar($id: ID!) {\n    ownedCar(id: $id) {\n      id\n      restRequiredImagesCount\n      fulfilledForCarOfficialValuationByImages\n      imagesForIndex {\n        id\n        url\n        imageType\n        comment\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query CarOfficialValuationImages_DefectView_useMultipleCard_OwnedCar($id: ID!) {\n    ownedCar(id: $id) {\n      id\n      restRequiredImagesCount\n      fulfilledForCarOfficialValuationByImages\n      ownedCarDefects {\n        id\n        defectType\n        defectTypeText\n        ownedCarImages {\n          id\n          url\n          imageType\n          comment\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query CarOfficialValuationImages_DefectView_useMultipleCard_OwnedCar($id: ID!) {\n    ownedCar(id: $id) {\n      id\n      restRequiredImagesCount\n      fulfilledForCarOfficialValuationByImages\n      ownedCarDefects {\n        id\n        defectType\n        defectTypeText\n        ownedCarImages {\n          id\n          url\n          imageType\n          comment\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment CarOfficialValuationImages_DefectView_ImagesView_ownedCar on OwnedCar {\n    id\n    ownedCarDefects {\n      id\n      defectType\n      defectTypeText\n      ...CarOfficialValuationImages_DefectView_ImageTypesCard_ownedCarDefect\n      ownedCarImageGroup {\n        label\n        imageTypes {\n          imageType\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment CarOfficialValuationImages_DefectView_ImagesView_ownedCar on OwnedCar {\n    id\n    ownedCarDefects {\n      id\n      defectType\n      defectTypeText\n      ...CarOfficialValuationImages_DefectView_ImageTypesCard_ownedCarDefect\n      ownedCarImageGroup {\n        label\n        imageTypes {\n          imageType\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query CarOfficialValuationImages_DefectView_ownedCar($id: ID!) {\n    ownedCar(id: $id) {\n      id\n      restRequiredImagesCount\n      fulfilledForCarOfficialValuationByImages\n      ...CarOfficialValuationImages_DefectView_ImagesView_ownedCar\n      ownedCarDefects {\n        id\n        defectType\n      }\n    }\n  }\n"): (typeof documents)["\n  query CarOfficialValuationImages_DefectView_ownedCar($id: ID!) {\n    ownedCar(id: $id) {\n      id\n      restRequiredImagesCount\n      fulfilledForCarOfficialValuationByImages\n      ...CarOfficialValuationImages_DefectView_ImagesView_ownedCar\n      ownedCarDefects {\n        id\n        defectType\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CarOfficialValuationImages_DefectView_useOwnedCarDefect_EditOwnedCarDefects(\n    $input: EditOwnedCarDefectsInput!\n  ) {\n    editOwnedCarDefects(input: $input) {\n      ownedCar {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation CarOfficialValuationImages_DefectView_useOwnedCarDefect_EditOwnedCarDefects(\n    $input: EditOwnedCarDefectsInput!\n  ) {\n    editOwnedCarDefects(input: $input) {\n      ownedCar {\n        id\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query CarOfficialValuationImages_DefectView_RefetchQuery($ownedCarId: ID!) {\n    ownedCar(id: $ownedCarId) {\n      id\n      ownedCarDefects {\n        id\n        defectType\n        ownedCarImageGroup {\n          label\n          imageTypes {\n            imageType\n            imageTypeText\n            optional\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query CarOfficialValuationImages_DefectView_RefetchQuery($ownedCarId: ID!) {\n    ownedCar(id: $ownedCarId) {\n      id\n      ownedCarDefects {\n        id\n        defectType\n        ownedCarImageGroup {\n          label\n          imageTypes {\n            imageType\n            imageTypeText\n            optional\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CommentEditModal_UpdateOwnedCarImage($input: UpdateOwnedCarImageInput!) {\n    updateOwnedCarImage(input: $input) {\n      ownedCarImage {\n        id\n        comment\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation CommentEditModal_UpdateOwnedCarImage($input: UpdateOwnedCarImageInput!) {\n    updateOwnedCarImage(input: $input) {\n      ownedCarImage {\n        id\n        comment\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CarOfficialValuationImages_ImagePreviewDrawer_useImagePreviewDrawer_DestroyOwnedCarImage(\n    $input: DestroyOwnedCarImageInput!\n  ) {\n    destroyOwnedCarImage(input: $input) {\n      id\n      isDestroyed\n      errors {\n        message\n        path\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation CarOfficialValuationImages_ImagePreviewDrawer_useImagePreviewDrawer_DestroyOwnedCarImage(\n    $input: DestroyOwnedCarImageInput!\n  ) {\n    destroyOwnedCarImage(input: $input) {\n      id\n      isDestroyed\n      errors {\n        message\n        path\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment CarOfficialValuationImages_ImageTypeImageUploadModal_Camera_Footer_OwnedCarImageType on OwnedCarImageType {\n    imageType\n    groupKey\n  }\n"): (typeof documents)["\n  fragment CarOfficialValuationImages_ImageTypeImageUploadModal_Camera_Footer_OwnedCarImageType on OwnedCarImageType {\n    imageType\n    groupKey\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query CarOfficialValuationImages_CameraModal_Camera_Footer_OwnedCar($id: ID!) {\n    ownedCar(id: $id) {\n      id\n      imageGroupCounts {\n        id\n        groupKey\n        label\n        restCount\n      }\n    }\n  }\n"): (typeof documents)["\n  query CarOfficialValuationImages_CameraModal_Camera_Footer_OwnedCar($id: ID!) {\n    ownedCar(id: $id) {\n      id\n      imageGroupCounts {\n        id\n        groupKey\n        label\n        restCount\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment CarOfficialValuationImages_ImageTypeImageUploadModal_Camera_OwnedCarImageType on OwnedCarImageType {\n    ...CarOfficialValuationImages_ImageTypeImageUploadModal_Camera_Footer_OwnedCarImageType\n  }\n"): (typeof documents)["\n  fragment CarOfficialValuationImages_ImageTypeImageUploadModal_Camera_OwnedCarImageType on OwnedCarImageType {\n    ...CarOfficialValuationImages_ImageTypeImageUploadModal_Camera_Footer_OwnedCarImageType\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment CarOfficialValuationImages_ImageTypeImageUploadModal_OwnedCarImageType on OwnedCarImageType {\n    imageType\n    optional\n  }\n"): (typeof documents)["\n  fragment CarOfficialValuationImages_ImageTypeImageUploadModal_OwnedCarImageType on OwnedCarImageType {\n    imageType\n    optional\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query CarOfficialValuationImages_ImageTypeImageUploadModal_OwnedCarImageTypes {\n    ownedCarImageTypes {\n      ...CarOfficialValuationImages_ImageTypeImageUploadModal_OwnedCarImageType\n      ...CarOfficialValuationImages_ImageTypeImageUploadModal_Camera_OwnedCarImageType\n    }\n  }\n"): (typeof documents)["\n  query CarOfficialValuationImages_ImageTypeImageUploadModal_OwnedCarImageTypes {\n    ownedCarImageTypes {\n      ...CarOfficialValuationImages_ImageTypeImageUploadModal_OwnedCarImageType\n      ...CarOfficialValuationImages_ImageTypeImageUploadModal_Camera_OwnedCarImageType\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CarOfficialValuationImage_useOwnedCarImage_EditOwnedCarImage($input: EditOwnedCarImageInput!) {\n    editOwnedCarImage(input: $input) {\n      ownedCarImage {\n        id\n        url\n        imageType\n        nextImageType\n        ownedCar {\n          id\n          restRequiredImagesCount\n          fulfilledForCarOfficialValuationByImages\n          imageGroupCounts {\n            id\n            restCount\n          }\n        }\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation CarOfficialValuationImage_useOwnedCarImage_EditOwnedCarImage($input: EditOwnedCarImageInput!) {\n    editOwnedCarImage(input: $input) {\n      ownedCarImage {\n        id\n        url\n        imageType\n        nextImageType\n        ownedCar {\n          id\n          restRequiredImagesCount\n          fulfilledForCarOfficialValuationByImages\n          imageGroupCounts {\n            id\n            restCount\n          }\n        }\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CarOfficialValuationImages_MultipleUploadModal_createOwnedCarImages($input: CreateOwnedCarImagesInput!) {\n    createOwnedCarImages(input: $input) {\n      ownedCarImages {\n        id\n        imageType\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation CarOfficialValuationImages_MultipleUploadModal_createOwnedCarImages($input: CreateOwnedCarImagesInput!) {\n    createOwnedCarImages(input: $input) {\n      ownedCarImages {\n        id\n        imageType\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CarOfficialValuationImages_MultipleUploadModal_createOwnedCarImage($input: CreateOwnedCarImageInput!) {\n    createOwnedCarImage(input: $input) {\n      ownedCarImage {\n        id\n        imageType\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation CarOfficialValuationImages_MultipleUploadModal_createOwnedCarImage($input: CreateOwnedCarImageInput!) {\n    createOwnedCarImage(input: $input) {\n      ownedCarImage {\n        id\n        imageType\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query CarOfficialValuationImages_useNormalView_ImageTypes($groupKey: OwnedCarImageGroupEnum!) {\n    ownedCarImageGroup(groupKey: $groupKey) {\n      groupKey\n      label\n      imageTypes {\n        imageTypeText\n        imageType\n      }\n    }\n  }\n"): (typeof documents)["\n  query CarOfficialValuationImages_useNormalView_ImageTypes($groupKey: OwnedCarImageGroupEnum!) {\n    ownedCarImageGroup(groupKey: $groupKey) {\n      groupKey\n      label\n      imageTypes {\n        imageTypeText\n        imageType\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query CarOfficialValuationImages_useNormalView_OwnedCar($id: ID!) {\n    ownedCar(id: $id) {\n      id\n      restRequiredImagesCount\n      fulfilledForCarOfficialValuationByImages\n      imagesForIndex {\n        id\n        url\n        imageType\n        comment\n      }\n    }\n  }\n"): (typeof documents)["\n  query CarOfficialValuationImages_useNormalView_OwnedCar($id: ID!) {\n    ownedCar(id: $id) {\n      id\n      restRequiredImagesCount\n      fulfilledForCarOfficialValuationByImages\n      imagesForIndex {\n        id\n        url\n        imageType\n        comment\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query CarOfficialValuationImages_OptionalView_useImageTypesView_ImageTypes($groupKey: OwnedCarImageGroupEnum!) {\n    ownedCarImageGroup(groupKey: $groupKey) {\n      groupKey\n      label\n      imageTypes {\n        imageTypeText\n        imageType\n      }\n    }\n  }\n"): (typeof documents)["\n  query CarOfficialValuationImages_OptionalView_useImageTypesView_ImageTypes($groupKey: OwnedCarImageGroupEnum!) {\n    ownedCarImageGroup(groupKey: $groupKey) {\n      groupKey\n      label\n      imageTypes {\n        imageTypeText\n        imageType\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query CarOfficialValuationImages_OptionalView_useImageTypesView_OwnedCar($id: ID!) {\n    ownedCar(id: $id) {\n      id\n      imagesForIndex {\n        id\n        url\n        imageType\n        comment\n      }\n    }\n  }\n"): (typeof documents)["\n  query CarOfficialValuationImages_OptionalView_useImageTypesView_OwnedCar($id: ID!) {\n    ownedCar(id: $id) {\n      id\n      imagesForIndex {\n        id\n        url\n        imageType\n        comment\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query CarOfficialValuationImages_OptionalView_useOtherView_OwnedCar($id: ID!) {\n    ownedCar(id: $id) {\n      id\n      otherImages {\n        id\n        url\n        imageType\n        comment\n      }\n    }\n  }\n"): (typeof documents)["\n  query CarOfficialValuationImages_OptionalView_useOtherView_OwnedCar($id: ID!) {\n    ownedCar(id: $id) {\n      id\n      otherImages {\n        id\n        url\n        imageType\n        comment\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query CarOfficialValuationImages_CarOfficialValuation($ownedCarId: ID!, $ulid: String!) {\n    carOfficialValuation(ownedCarId: $ownedCarId, ulid: $ulid) {\n      id\n      ownedCar {\n        id\n      }\n      ...CarOfficialValuationImagesBody_carOfficialValuation\n    }\n  }\n"): (typeof documents)["\n  query CarOfficialValuationImages_CarOfficialValuation($ownedCarId: ID!, $ulid: String!) {\n    carOfficialValuation(ownedCarId: $ownedCarId, ulid: $ulid) {\n      id\n      ownedCar {\n        id\n      }\n      ...CarOfficialValuationImagesBody_carOfficialValuation\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query CarOfficialValuationShow($ownedCarId: ID!, $ulid: String!) {\n    carOfficialValuation(ownedCarId: $ownedCarId, ulid: $ulid) {\n      id\n      status\n      ...CarOfficialValuationShow_PreparingView_carOfficialValuation\n      ...CarOfficialValuationShow_NomalView_carOfficialValuation\n    }\n  }\n"): (typeof documents)["\n  query CarOfficialValuationShow($ownedCarId: ID!, $ulid: String!) {\n    carOfficialValuation(ownedCarId: $ownedCarId, ulid: $ulid) {\n      id\n      status\n      ...CarOfficialValuationShow_PreparingView_carOfficialValuation\n      ...CarOfficialValuationShow_NomalView_carOfficialValuation\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment CarOfficialValuationShow_CancelView_CarOfficialValuation on CarOfficialValuation {\n    id\n    ...OfferList_carOfficialValuation\n    ownedCar {\n      id\n      isProhibitCreateCarOfficialValuation\n    }\n  }\n"): (typeof documents)["\n  fragment CarOfficialValuationShow_CancelView_CarOfficialValuation on CarOfficialValuation {\n    id\n    ...OfferList_carOfficialValuation\n    ownedCar {\n      id\n      isProhibitCreateCarOfficialValuation\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment CarOfficialValuationShow_NomalView_CarBaseContent_carOfficialValuation on CarOfficialValuation {\n    id\n    ulid\n    ownedCar {\n      id\n      makeName\n      carModelName\n      firstRegistrationYear\n      gradeName\n      mainImage {\n        id\n        url\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment CarOfficialValuationShow_NomalView_CarBaseContent_carOfficialValuation on CarOfficialValuation {\n    id\n    ulid\n    ownedCar {\n      id\n      makeName\n      carModelName\n      firstRegistrationYear\n      gradeName\n      mainImage {\n        id\n        url\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment CarOfficialValuationShow_NomalView_CommentContent_carOfficialValuation on CarOfficialValuation {\n    id\n    ownedCar {\n      id\n      comment\n    }\n  }\n"): (typeof documents)["\n  fragment CarOfficialValuationShow_NomalView_CommentContent_carOfficialValuation on CarOfficialValuation {\n    id\n    ownedCar {\n      id\n      comment\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment CarOfficialValuationShow_CompletedView_CarOfficialValuation on CarOfficialValuation {\n    id\n    expiredPriceValidDeadline\n    valuationType\n    ...CarOfficialValuationShow_CancelButton_CarOfficialValuation\n    ...OfferList_carOfficialValuation\n  }\n"): (typeof documents)["\n  fragment CarOfficialValuationShow_CompletedView_CarOfficialValuation on CarOfficialValuation {\n    id\n    expiredPriceValidDeadline\n    valuationType\n    ...CarOfficialValuationShow_CancelButton_CarOfficialValuation\n    ...OfferList_carOfficialValuation\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment CarOfficialValuationShow_ConfirmingView_CarOfficialValuation on CarOfficialValuation {\n    id\n    ...CarOfficialValuationShow_CancelButton_CarOfficialValuation\n  }\n"): (typeof documents)["\n  fragment CarOfficialValuationShow_ConfirmingView_CarOfficialValuation on CarOfficialValuation {\n    id\n    ...CarOfficialValuationShow_CancelButton_CarOfficialValuation\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment CarOfficialValuation_InProgressView_carOfficialValuation on CarOfficialValuation {\n    id\n    ...CarOfficialValuationShow_CancelButton_CarOfficialValuation\n  }\n"): (typeof documents)["\n  fragment CarOfficialValuation_InProgressView_carOfficialValuation on CarOfficialValuation {\n    id\n    ...CarOfficialValuationShow_CancelButton_CarOfficialValuation\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment CarOfficialValuationShow_NomalView_carOfficialValuation on CarOfficialValuation {\n    id\n    ulid\n    status\n    requestedAt\n    ...CarOfficialValuationShow_NomalView_CarBaseContent_carOfficialValuation\n    ...CarOfficialValuationShow_CarContent_carOfficialValuation\n    ...CarOfficialValuationShow_CancelView_CarOfficialValuation\n    ...CarOfficialValuation_InProgressView_carOfficialValuation\n    ...CarOfficialValuationShow_ConfirmingView_CarOfficialValuation\n    ...CarOfficialValuationShow_CompletedView_CarOfficialValuation\n    ...CarOfficialValuationShow_NomalView_CommentContent_carOfficialValuation\n    ownedCar {\n      id\n      ...CarOfficialValuationShow_CarImagesContent_ownedCar\n    }\n  }\n"): (typeof documents)["\n  fragment CarOfficialValuationShow_NomalView_carOfficialValuation on CarOfficialValuation {\n    id\n    ulid\n    status\n    requestedAt\n    ...CarOfficialValuationShow_NomalView_CarBaseContent_carOfficialValuation\n    ...CarOfficialValuationShow_CarContent_carOfficialValuation\n    ...CarOfficialValuationShow_CancelView_CarOfficialValuation\n    ...CarOfficialValuation_InProgressView_carOfficialValuation\n    ...CarOfficialValuationShow_ConfirmingView_CarOfficialValuation\n    ...CarOfficialValuationShow_CompletedView_CarOfficialValuation\n    ...CarOfficialValuationShow_NomalView_CommentContent_carOfficialValuation\n    ownedCar {\n      id\n      ...CarOfficialValuationShow_CarImagesContent_ownedCar\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment CarOfficialValuationShow_CancelButton_CarOfficialValuation on CarOfficialValuation {\n    id\n    status\n  }\n"): (typeof documents)["\n  fragment CarOfficialValuationShow_CancelButton_CarOfficialValuation on CarOfficialValuation {\n    id\n    status\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CarOfficialValuationShow_CancelButton_CancelCarOfficialValuation($input: CancelCarOfficialValuationInput!) {\n    cancelCarOfficialValuation(input: $input) {\n      carOfficialValuation {\n        id\n        status\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation CarOfficialValuationShow_CancelButton_CancelCarOfficialValuation($input: CancelCarOfficialValuationInput!) {\n    cancelCarOfficialValuation(input: $input) {\n      carOfficialValuation {\n        id\n        status\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CarOfficialValuationShow_CreateOfficialValuationButton_CreateCarOfficialValuation(\n    $input: CreateCarOfficialValuationInput!\n  ) {\n    createCarOfficialValuation(input: $input) {\n      carOfficialValuation {\n        id\n        ulid\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation CarOfficialValuationShow_CreateOfficialValuationButton_CreateCarOfficialValuation(\n    $input: CreateCarOfficialValuationInput!\n  ) {\n    createCarOfficialValuation(input: $input) {\n      carOfficialValuation {\n        id\n        ulid\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment OfferList_carOfficialValuation on CarOfficialValuation {\n    id\n    ulid\n    isOfferAcceptable\n    createOfferDeadline\n    priceValidDeadline\n    status\n    offers {\n      id\n      proposedPrice\n      bringInProposedPrice\n      acceptPriceType\n      createdAt\n      comment\n      deliveryAcceptDeadline\n      expiredDeliveryAcceptDeadline\n      bringInAcceptDeadline\n      expiredBringInAcceptDeadline\n      dealerBranch {\n        id\n        nameForValuation\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment OfferList_carOfficialValuation on CarOfficialValuation {\n    id\n    ulid\n    isOfferAcceptable\n    createOfferDeadline\n    priceValidDeadline\n    status\n    offers {\n      id\n      proposedPrice\n      bringInProposedPrice\n      acceptPriceType\n      createdAt\n      comment\n      deliveryAcceptDeadline\n      expiredDeliveryAcceptDeadline\n      bringInAcceptDeadline\n      expiredBringInAcceptDeadline\n      dealerBranch {\n        id\n        nameForValuation\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment CarOfficialValuationShow_PreparingView_BaseCard_ownedCar on OwnedCar {\n    id\n    makeName\n    carModelName\n    gradeName\n    firstRegistrationYear\n  }\n"): (typeof documents)["\n  fragment CarOfficialValuationShow_PreparingView_BaseCard_ownedCar on OwnedCar {\n    id\n    makeName\n    carModelName\n    gradeName\n    firstRegistrationYear\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment CarOfficialValuationShow_PreparingView_CarCard_carOfficialValuation on CarOfficialValuation {\n    id\n    ulid\n    ownedCarId\n    isFulfilledByOtherImages\n    ...CarOfficialValuationShow_CarContent_carOfficialValuation\n  }\n"): (typeof documents)["\n  fragment CarOfficialValuationShow_PreparingView_CarCard_carOfficialValuation on CarOfficialValuation {\n    id\n    ulid\n    ownedCarId\n    isFulfilledByOtherImages\n    ...CarOfficialValuationShow_CarContent_carOfficialValuation\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment CarOfficialValuationShow_PreparingView_CarImagesCard_carOfficialValuation on CarOfficialValuation {\n    id\n    ulid\n    ownedCarId\n    isFulfilledByImages\n    ownedCar {\n      id\n      imageGroupCounts {\n        id\n        groupKey\n        restCount\n      }\n      ...CarOfficialValuationShow_CarImagesContent_ownedCar\n    }\n  }\n"): (typeof documents)["\n  fragment CarOfficialValuationShow_PreparingView_CarImagesCard_carOfficialValuation on CarOfficialValuation {\n    id\n    ulid\n    ownedCarId\n    isFulfilledByImages\n    ownedCar {\n      id\n      imageGroupCounts {\n        id\n        groupKey\n        restCount\n      }\n      ...CarOfficialValuationShow_CarImagesContent_ownedCar\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CarOfficialValuationShow_PreparingView_updateOwnedCar($input: UpdateOwnedCarInput!) {\n    updateOwnedCar(input: $input) {\n      ownedCar {\n        id\n        ...CarOfficialValuationShow_PreparingView_usePreparingViewForm_ownedCar\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation CarOfficialValuationShow_PreparingView_updateOwnedCar($input: UpdateOwnedCarInput!) {\n    updateOwnedCar(input: $input) {\n      ownedCar {\n        id\n        ...CarOfficialValuationShow_PreparingView_usePreparingViewForm_ownedCar\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment CarOfficialValuationShow_PreparingView_carOfficialValuation on CarOfficialValuation {\n    id\n    ulid\n    isFulfilled\n    ...CarOfficialValuationShow_PreparingView_CarCard_carOfficialValuation\n    ...CarOfficialValuationShow_PreparingView_CarImagesCard_carOfficialValuation\n    ...CarOfficialValuationShow_PreparingView_useCarOfficialValuation_carOfficialValuation\n    ownedCar {\n      id\n      ...CarOfficialValuationShow_PreparingView_BaseCard_ownedCar\n      ...CarOfficialValuationShow_PreparingView_usePreparingViewForm_ownedCar\n    }\n  }\n"): (typeof documents)["\n  fragment CarOfficialValuationShow_PreparingView_carOfficialValuation on CarOfficialValuation {\n    id\n    ulid\n    isFulfilled\n    ...CarOfficialValuationShow_PreparingView_CarCard_carOfficialValuation\n    ...CarOfficialValuationShow_PreparingView_CarImagesCard_carOfficialValuation\n    ...CarOfficialValuationShow_PreparingView_useCarOfficialValuation_carOfficialValuation\n    ownedCar {\n      id\n      ...CarOfficialValuationShow_PreparingView_BaseCard_ownedCar\n      ...CarOfficialValuationShow_PreparingView_usePreparingViewForm_ownedCar\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment CarOfficialValuationShow_PreparingView_useCarOfficialValuation_carOfficialValuation on CarOfficialValuation {\n    id\n    ulid\n  }\n"): (typeof documents)["\n  fragment CarOfficialValuationShow_PreparingView_useCarOfficialValuation_carOfficialValuation on CarOfficialValuation {\n    id\n    ulid\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CarOfficialValuationShow_PreparingView_useCarOfficialValuation_requestCarOfficialValuation(\n    $input: RequestCarOfficialValuationInput!\n  ) {\n    requestCarOfficialValuation(input: $input) {\n      carOfficialValuation {\n        id\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation CarOfficialValuationShow_PreparingView_useCarOfficialValuation_requestCarOfficialValuation(\n    $input: RequestCarOfficialValuationInput!\n  ) {\n    requestCarOfficialValuation(input: $input) {\n      carOfficialValuation {\n        id\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment CarOfficialValuationShow_PreparingView_usePreparingViewForm_ownedCar on OwnedCar {\n    id\n    comment\n  }\n"): (typeof documents)["\n  fragment CarOfficialValuationShow_PreparingView_usePreparingViewForm_ownedCar on OwnedCar {\n    id\n    comment\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment CarOfficialValuationShow_CarContent_carOfficialValuation on CarOfficialValuation {\n    id\n    pickupZipCode\n    pickupZipCodeObject {\n      prefecture\n      city\n      town\n    }\n    ownedCar {\n      id\n      odorStatusText\n      odorStatusComment\n      accidentStatusText\n      accidentComment\n      latestMileageKm\n      inspectionCertificateImage {\n        id\n        url\n      }\n      ownedCarRepairFiles {\n        id\n        fileUrl\n        fileName\n        isPdf\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment CarOfficialValuationShow_CarContent_carOfficialValuation on CarOfficialValuation {\n    id\n    pickupZipCode\n    pickupZipCodeObject {\n      prefecture\n      city\n      town\n    }\n    ownedCar {\n      id\n      odorStatusText\n      odorStatusComment\n      accidentStatusText\n      accidentComment\n      latestMileageKm\n      inspectionCertificateImage {\n        id\n        url\n      }\n      ownedCarRepairFiles {\n        id\n        fileUrl\n        fileName\n        isPdf\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment CarOfficialValuationShow_CarImagesContent_ownedCar on OwnedCar {\n    id\n    images {\n      id\n      url\n    }\n  }\n"): (typeof documents)["\n  fragment CarOfficialValuationShow_CarImagesContent_ownedCar on OwnedCar {\n    id\n    images {\n      id\n      url\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query CarValuationOffer_NoticeCard_CarOfficialValuation($ownedCarId: ID!, $ulid: String!) {\n    carOfficialValuation(ownedCarId: $ownedCarId, ulid: $ulid) {\n      id\n      status\n    }\n  }\n"): (typeof documents)["\n  query CarValuationOffer_NoticeCard_CarOfficialValuation($ownedCarId: ID!, $ulid: String!) {\n    carOfficialValuation(ownedCarId: $ownedCarId, ulid: $ulid) {\n      id\n      status\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query CarValuationOffer_Term_CarOfficialValuation($ownedCarId: ID!, $ulid: String!) {\n    carOfficialValuation(ownedCarId: $ownedCarId, ulid: $ulid) {\n      id\n      status\n    }\n  }\n"): (typeof documents)["\n  query CarValuationOffer_Term_CarOfficialValuation($ownedCarId: ID!, $ulid: String!) {\n    carOfficialValuation(ownedCarId: $ownedCarId, ulid: $ulid) {\n      id\n      status\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CarValuationOffer_Term_AcceptCarValuationOffer($input: AcceptCarValuationOfferInput!) {\n    acceptCarValuationOffer(input: $input) {\n      carValuationOffer {\n        id\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation CarValuationOffer_Term_AcceptCarValuationOffer($input: AcceptCarValuationOfferInput!) {\n    acceptCarValuationOffer(input: $input) {\n      carValuationOffer {\n        id\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query CarValuationList($id: ID!) {\n    ownedCar(id: $id) {\n      id\n      carEstimateValuations {\n        id\n        status\n        ulid\n        expiredPriceValidDeadline\n        priceValidDeadline\n        createdAt\n        completedAt\n        completedAuto\n        carLog {\n          id\n          mileageKm\n        }\n        prices {\n          id\n          minPrice\n          maxPrice\n          sortOrder\n        }\n      }\n      carOfficialValuations {\n        id\n        status\n        ulid\n        proposedPrice\n        expiredPriceValidDeadline\n        priceValidDeadline\n        createdAt\n        completedAt\n        offers {\n          id\n          proposedPrice\n          acceptedPrice\n          isAccepted\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query CarValuationList($id: ID!) {\n    ownedCar(id: $id) {\n      id\n      carEstimateValuations {\n        id\n        status\n        ulid\n        expiredPriceValidDeadline\n        priceValidDeadline\n        createdAt\n        completedAt\n        completedAuto\n        carLog {\n          id\n          mileageKm\n        }\n        prices {\n          id\n          minPrice\n          maxPrice\n          sortOrder\n        }\n      }\n      carOfficialValuations {\n        id\n        status\n        ulid\n        proposedPrice\n        expiredPriceValidDeadline\n        priceValidDeadline\n        createdAt\n        completedAt\n        offers {\n          id\n          proposedPrice\n          acceptedPrice\n          isAccepted\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment CarBaseTable_ownedCar on OwnedCar {\n    id\n    makeName\n    carModelName\n    firstRegistrationYear\n    gradeName\n    carOriginText\n    driveSystemText\n    latestMileageKm\n  }\n"): (typeof documents)["\n  fragment CarBaseTable_ownedCar on OwnedCar {\n    id\n    makeName\n    carModelName\n    firstRegistrationYear\n    gradeName\n    carOriginText\n    driveSystemText\n    latestMileageKm\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment CarDetailTable_ownedCar on OwnedCar {\n    id\n    color\n    odorStatus\n    odorStatusText\n    odorStatusComment\n    isDirty\n    accidentStatus\n    accidentStatusText\n    accidentComment\n    hasMendHistory\n    mendComment\n    comment\n    latestMileageKm\n    inspectionCertificateImage {\n      id\n      url\n    }\n    options {\n      id\n      optionTypeText\n    }\n  }\n"): (typeof documents)["\n  fragment CarDetailTable_ownedCar on OwnedCar {\n    id\n    color\n    odorStatus\n    odorStatusText\n    odorStatusComment\n    isDirty\n    accidentStatus\n    accidentStatusText\n    accidentComment\n    hasMendHistory\n    mendComment\n    comment\n    latestMileageKm\n    inspectionCertificateImage {\n      id\n      url\n    }\n    options {\n      id\n      optionTypeText\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment CarDetailTable_carLog on CarLog {\n    id\n    color\n    odorStatus\n    odorStatusText\n    odorStatusComment\n    isDirty\n    accidentStatus\n    accidentStatusText\n    accidentComment\n    hasMendHistory\n    mendComment\n    comment\n    mileageKm\n    inspectionCertificateImage {\n      id\n      url\n    }\n    options {\n      id\n      optionTypeText\n    }\n  }\n"): (typeof documents)["\n  fragment CarDetailTable_carLog on CarLog {\n    id\n    color\n    odorStatus\n    odorStatusText\n    odorStatusComment\n    isDirty\n    accidentStatus\n    accidentStatusText\n    accidentComment\n    hasMendHistory\n    mendComment\n    comment\n    mileageKm\n    inspectionCertificateImage {\n      id\n      url\n    }\n    options {\n      id\n      optionTypeText\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query CarDetailTable_Viewer {\n    viewer {\n      id\n      fullName\n      zipCode\n      zipCodeAddress {\n        id\n        prefecture\n        city\n      }\n    }\n  }\n"): (typeof documents)["\n  query CarDetailTable_Viewer {\n    viewer {\n      id\n      fullName\n      zipCode\n      zipCodeAddress {\n        id\n        prefecture\n        city\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query useImageTake_OwnedCarImageTypes {\n    ownedCarImageTypes {\n      imageType\n      imageTypeText\n      optional\n    }\n  }\n"): (typeof documents)["\n  query useImageTake_OwnedCarImageTypes {\n    ownedCarImageTypes {\n      imageType\n      imageTypeText\n      optional\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query useImageTake_OwnedCar($id: ID!) {\n    ownedCar(id: $id) {\n      id\n      imagesForIndex {\n        id\n        url\n        imageType\n      }\n    }\n  }\n"): (typeof documents)["\n  query useImageTake_OwnedCar($id: ID!) {\n    ownedCar(id: $id) {\n      id\n      imagesForIndex {\n        id\n        url\n        imageType\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation useImageTake_EditOwnedCarImage($input: EditOwnedCarImageInput!) {\n    editOwnedCarImage(input: $input) {\n      ownedCarImage {\n        id\n        url\n        imageType\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation useImageTake_EditOwnedCarImage($input: EditOwnedCarImageInput!) {\n    editOwnedCarImage(input: $input) {\n      ownedCarImage {\n        id\n        url\n        imageType\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query OwnedCarImages_OwnedCarImageTypes {\n    ownedCarImageTypes {\n      imageType\n      imageTypeText\n    }\n  }\n"): (typeof documents)["\n  query OwnedCarImages_OwnedCarImageTypes {\n    ownedCarImageTypes {\n      imageType\n      imageTypeText\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query OwnedCarImages_OwnedCar($id: ID!) {\n    ownedCar(id: $id) {\n      id\n      imagesForIndex {\n        id\n        url\n        imageType\n      }\n    }\n  }\n"): (typeof documents)["\n  query OwnedCarImages_OwnedCar($id: ID!) {\n    ownedCar(id: $id) {\n      id\n      imagesForIndex {\n        id\n        url\n        imageType\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation OwnedCarImages_createOwnedCarImages($input: CreateOwnedCarImagesInput!) {\n    createOwnedCarImages(input: $input) {\n      ownedCarImages {\n        id\n        imageType\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation OwnedCarImages_createOwnedCarImages($input: CreateOwnedCarImagesInput!) {\n    createOwnedCarImages(input: $input) {\n      ownedCarImages {\n        id\n        imageType\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query OwnedCarSelectCarEstimateValuation_OwnedCarsQuery {\n    ownedCars {\n      id\n      ...OwnedCarSelect_OwnedCarCard_ownedCar\n    }\n  }\n"): (typeof documents)["\n  query OwnedCarSelectCarEstimateValuation_OwnedCarsQuery {\n    ownedCars {\n      id\n      ...OwnedCarSelect_OwnedCarCard_ownedCar\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation OwnedCarSelectCarEstimateValuation_CompleteAutoCarEstimateValuation(\n    $input: CompleteAutoCarEstimateValuationInput!\n  ) {\n    completeAutoCarEstimateValuation(input: $input) {\n      carEstimateValuation {\n        id\n        ulid\n        ownedCarId\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation OwnedCarSelectCarEstimateValuation_CompleteAutoCarEstimateValuation(\n    $input: CompleteAutoCarEstimateValuationInput!\n  ) {\n    completeAutoCarEstimateValuation(input: $input) {\n      carEstimateValuation {\n        id\n        ulid\n        ownedCarId\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment OwnedCarSelect_OwnedCarCard_ownedCar on OwnedCar {\n    id\n    makeName\n    carModelName\n    firstRegistrationYear\n    gradeName\n    latestMileageKm\n    imagesForIndex {\n      id\n      url\n      imageType\n    }\n  }\n"): (typeof documents)["\n  fragment OwnedCarSelect_OwnedCarCard_ownedCar on OwnedCar {\n    id\n    makeName\n    carModelName\n    firstRegistrationYear\n    gradeName\n    latestMileageKm\n    imagesForIndex {\n      id\n      url\n      imageType\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment SellingDealReservationComplete_BringInBody_SellingDealReservation on SellingDealReservation {\n    id\n    bringInDealerBranchOptions {\n      label\n      value\n    }\n    bringInReservationData {\n      lastName\n      firstName\n      lastNameKana\n      firstNameKana\n      phoneNumber\n      documentMailZipCode\n      documentMailFirstHalfAddress\n      documentMailSecondHalfAddress\n      dealerBranch\n      firstPreferredDate\n      firstPreferredMinute\n      secondPreferredDate\n      secondPreferredMinute\n    }\n  }\n"): (typeof documents)["\n  fragment SellingDealReservationComplete_BringInBody_SellingDealReservation on SellingDealReservation {\n    id\n    bringInDealerBranchOptions {\n      label\n      value\n    }\n    bringInReservationData {\n      lastName\n      firstName\n      lastNameKana\n      firstNameKana\n      phoneNumber\n      documentMailZipCode\n      documentMailFirstHalfAddress\n      documentMailSecondHalfAddress\n      dealerBranch\n      firstPreferredDate\n      firstPreferredMinute\n      secondPreferredDate\n      secondPreferredMinute\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment SellingDealReservationComplete_SellingDealReservation on SellingDealReservation {\n    id\n    deliveryReservationData {\n      lastName\n      firstName\n      lastNameKana\n      firstNameKana\n      phoneNumber\n      deliveryZipCode\n      deliveryFirstHalfAddress\n      deliverySecondHalfAddress\n      isSameDocumentMailAddress\n      documentMailZipCode\n      documentMailFirstHalfAddress\n      documentMailSecondHalfAddress\n      firstPreferredDate\n      displayFirstPreferredTime\n      secondPreferredDate\n      displaySecondPreferredTime\n      thirdPreferredDate\n      displayThirdPreferredTime\n    }\n  }\n"): (typeof documents)["\n  fragment SellingDealReservationComplete_SellingDealReservation on SellingDealReservation {\n    id\n    deliveryReservationData {\n      lastName\n      firstName\n      lastNameKana\n      firstNameKana\n      phoneNumber\n      deliveryZipCode\n      deliveryFirstHalfAddress\n      deliverySecondHalfAddress\n      isSameDocumentMailAddress\n      documentMailZipCode\n      documentMailFirstHalfAddress\n      documentMailSecondHalfAddress\n      firstPreferredDate\n      displayFirstPreferredTime\n      secondPreferredDate\n      displaySecondPreferredTime\n      thirdPreferredDate\n      displayThirdPreferredTime\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment SellingDealReservationComplete_DismantlingBody_SellingDealReservation on SellingDealReservation {\n    id\n    dismantlingReservationData {\n      lastName\n      firstName\n      lastNameKana\n      firstNameKana\n      phoneNumber\n      email\n      deliveryZipCode\n      deliveryFirstHalfAddress\n      deliverySecondHalfAddress\n      isSameDocumentMailAddress\n      documentMailZipCode\n      documentMailFirstHalfAddress\n      documentMailSecondHalfAddress\n      bankName\n      branchName\n      displayAccountType\n      accountNumber\n      accountHolder\n    }\n  }\n"): (typeof documents)["\n  fragment SellingDealReservationComplete_DismantlingBody_SellingDealReservation on SellingDealReservation {\n    id\n    dismantlingReservationData {\n      lastName\n      firstName\n      lastNameKana\n      firstNameKana\n      phoneNumber\n      email\n      deliveryZipCode\n      deliveryFirstHalfAddress\n      deliverySecondHalfAddress\n      isSameDocumentMailAddress\n      documentMailZipCode\n      documentMailFirstHalfAddress\n      documentMailSecondHalfAddress\n      bankName\n      branchName\n      displayAccountType\n      accountNumber\n      accountHolder\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query SellingDealReservationComplete_SellingDealReservation($ownedCarId: ID!, $sellingDealReservationId: ID!) {\n    sellingDealReservation(ownedCarId: $ownedCarId, sellingDealReservationId: $sellingDealReservationId) {\n      id\n      reservationType\n      isCompleted\n      ...SellingDealReservationComplete_SellingDealReservation\n      ...SellingDealReservationComplete_BringInBody_SellingDealReservation\n      ...SellingDealReservationComplete_DismantlingBody_SellingDealReservation\n    }\n  }\n"): (typeof documents)["\n  query SellingDealReservationComplete_SellingDealReservation($ownedCarId: ID!, $sellingDealReservationId: ID!) {\n    sellingDealReservation(ownedCarId: $ownedCarId, sellingDealReservationId: $sellingDealReservationId) {\n      id\n      reservationType\n      isCompleted\n      ...SellingDealReservationComplete_SellingDealReservation\n      ...SellingDealReservationComplete_BringInBody_SellingDealReservation\n      ...SellingDealReservationComplete_DismantlingBody_SellingDealReservation\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment SellingDealReservationEdit_BringInConfirm_SellingDealReservation on SellingDealReservation {\n    id\n    bringInDealerBranchOptions {\n      label\n      value\n    }\n  }\n"): (typeof documents)["\n  fragment SellingDealReservationEdit_BringInConfirm_SellingDealReservation on SellingDealReservation {\n    id\n    bringInDealerBranchOptions {\n      label\n      value\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateSellingDealReservationBringIn($input: UpdateSellingDealReservationBringInInput!) {\n    updateSellingDealReservationBringIn(input: $input) {\n      sellingDealReservation {\n        id\n        displayReservationData\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateSellingDealReservationBringIn($input: UpdateSellingDealReservationBringInInput!) {\n    updateSellingDealReservationBringIn(input: $input) {\n      sellingDealReservation {\n        id\n        displayReservationData\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment SellingDealReservationEdit_DealerBranchSelect_SellingDealReservation on SellingDealReservation {\n    id\n    bringInDealerBranchOptions {\n      label\n      value\n    }\n  }\n"): (typeof documents)["\n  fragment SellingDealReservationEdit_DealerBranchSelect_SellingDealReservation on SellingDealReservation {\n    id\n    bringInDealerBranchOptions {\n      label\n      value\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment SellingDealReservationEdit_BringInForm_FormView_SellingDealReservation on SellingDealReservation {\n    id\n    ...SellingDealReservationEdit_DealerBranchSelect_SellingDealReservation\n    ...SellingDealReservationEdit_BringInForm_PreferredDateInput_SellingDealReservation\n  }\n"): (typeof documents)["\n  fragment SellingDealReservationEdit_BringInForm_FormView_SellingDealReservation on SellingDealReservation {\n    id\n    ...SellingDealReservationEdit_DealerBranchSelect_SellingDealReservation\n    ...SellingDealReservationEdit_BringInForm_PreferredDateInput_SellingDealReservation\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment SellingDealReservationEdit_BringInForm_PreferredDateInput_SellingDealReservation on SellingDealReservation {\n    id\n    carOfficialValuation {\n      id\n      priceValidDeadline\n    }\n    ...SellingDealReservationEdit_TimeSelect_SellingDealReservation\n  }\n"): (typeof documents)["\n  fragment SellingDealReservationEdit_BringInForm_PreferredDateInput_SellingDealReservation on SellingDealReservation {\n    id\n    carOfficialValuation {\n      id\n      priceValidDeadline\n    }\n    ...SellingDealReservationEdit_TimeSelect_SellingDealReservation\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment SellingDealReservationEdit_BringInForm_SellingDealReservation on SellingDealReservation {\n    id\n    carOfficialValuation {\n      id\n      pickupZipCode\n      pickupZipCodeObject {\n        prefecture\n        city\n        town\n      }\n    }\n    ...SellingDealReservationEdit_BringInForm_FormView_SellingDealReservation\n    ...SellingDealReservationEdit_BringInConfirm_SellingDealReservation\n  }\n"): (typeof documents)["\n  fragment SellingDealReservationEdit_BringInForm_SellingDealReservation on SellingDealReservation {\n    id\n    carOfficialValuation {\n      id\n      pickupZipCode\n      pickupZipCodeObject {\n        prefecture\n        city\n        town\n      }\n    }\n    ...SellingDealReservationEdit_BringInForm_FormView_SellingDealReservation\n    ...SellingDealReservationEdit_BringInConfirm_SellingDealReservation\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment SellingDealReservationEdit_DeliveryConfirm_SellingDealReservation on SellingDealReservation {\n    id\n    deliveryPreferredTimeOptions {\n      label\n      value\n    }\n  }\n"): (typeof documents)["\n  fragment SellingDealReservationEdit_DeliveryConfirm_SellingDealReservation on SellingDealReservation {\n    id\n    deliveryPreferredTimeOptions {\n      label\n      value\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateSellingDealReservationDelivery($input: UpdateSellingDealReservationDeliveryInput!) {\n    updateSellingDealReservationDelivery(input: $input) {\n      sellingDealReservation {\n        id\n        displayReservationData\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateSellingDealReservationDelivery($input: UpdateSellingDealReservationDeliveryInput!) {\n    updateSellingDealReservationDelivery(input: $input) {\n      sellingDealReservation {\n        id\n        displayReservationData\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment SellingDealReservationEdit_DeliveryForm_FormView_SellingDealReservation on SellingDealReservation {\n    id\n    ...SellingDealReservationEdit_PreferredDateInput_SellingDealReservation\n  }\n"): (typeof documents)["\n  fragment SellingDealReservationEdit_DeliveryForm_FormView_SellingDealReservation on SellingDealReservation {\n    id\n    ...SellingDealReservationEdit_PreferredDateInput_SellingDealReservation\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment SellingDealReservationEdit_PreferredDateInput_SellingDealReservation on SellingDealReservation {\n    id\n    carOfficialValuation {\n      id\n      priceValidDeadline\n    }\n    ...SellingDealReservationEdit_TimeSelect_SellingDealReservation\n  }\n"): (typeof documents)["\n  fragment SellingDealReservationEdit_PreferredDateInput_SellingDealReservation on SellingDealReservation {\n    id\n    carOfficialValuation {\n      id\n      priceValidDeadline\n    }\n    ...SellingDealReservationEdit_TimeSelect_SellingDealReservation\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment SellingDealReservationEdit_TimeSelect_SellingDealReservation on SellingDealReservation {\n    id\n    deliveryPreferredTimeOptions {\n      label\n      value\n    }\n  }\n"): (typeof documents)["\n  fragment SellingDealReservationEdit_TimeSelect_SellingDealReservation on SellingDealReservation {\n    id\n    deliveryPreferredTimeOptions {\n      label\n      value\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment SellingDealReservationEdit_index_SellingDealReservation on SellingDealReservation {\n    id\n    carOfficialValuation {\n      id\n      pickupZipCode\n      pickupZipCodeObject {\n        prefecture\n        city\n        town\n      }\n    }\n    ...SellingDealReservationEdit_DeliveryForm_FormView_SellingDealReservation\n    ...SellingDealReservationEdit_DeliveryConfirm_SellingDealReservation\n  }\n"): (typeof documents)["\n  fragment SellingDealReservationEdit_index_SellingDealReservation on SellingDealReservation {\n    id\n    carOfficialValuation {\n      id\n      pickupZipCode\n      pickupZipCodeObject {\n        prefecture\n        city\n        town\n      }\n    }\n    ...SellingDealReservationEdit_DeliveryForm_FormView_SellingDealReservation\n    ...SellingDealReservationEdit_DeliveryConfirm_SellingDealReservation\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateSellingDealReservationDismantling($input: UpdateSellingDealReservationDismantlingInput!) {\n    updateSellingDealReservationDismantling(input: $input) {\n      sellingDealReservation {\n        id\n        displayReservationData\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateSellingDealReservationDismantling($input: UpdateSellingDealReservationDismantlingInput!) {\n    updateSellingDealReservationDismantling(input: $input) {\n      sellingDealReservation {\n        id\n        displayReservationData\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment SellingDealReservationEdit_DismantlingForm_FirstForm_SellingDealReservation on SellingDealReservation {\n    id\n    carOfficialValuation {\n      id\n      pickupZipCode\n      pickupZipCodeObject {\n        prefecture\n        city\n        town\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment SellingDealReservationEdit_DismantlingForm_FirstForm_SellingDealReservation on SellingDealReservation {\n    id\n    carOfficialValuation {\n      id\n      pickupZipCode\n      pickupZipCodeObject {\n        prefecture\n        city\n        town\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment SellingDealReservationEdit_DismantlingForm_SellingDealReservation on SellingDealReservation {\n    id\n    ...SellingDealReservationEdit_DismantlingForm_FirstForm_SellingDealReservation\n  }\n"): (typeof documents)["\n  fragment SellingDealReservationEdit_DismantlingForm_SellingDealReservation on SellingDealReservation {\n    id\n    ...SellingDealReservationEdit_DismantlingForm_FirstForm_SellingDealReservation\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query SellingDealReservatinoEdit_SellingDealReservation($ownedCarId: ID!, $sellingDealReservationId: ID!) {\n    sellingDealReservation(ownedCarId: $ownedCarId, sellingDealReservationId: $sellingDealReservationId) {\n      id\n      reservationType\n      isCompleted\n      ...SellingDealReservationEdit_index_SellingDealReservation\n      ...SellingDealReservationEdit_BringInForm_SellingDealReservation\n      ...SellingDealReservationEdit_DismantlingForm_SellingDealReservation\n    }\n  }\n"): (typeof documents)["\n  query SellingDealReservatinoEdit_SellingDealReservation($ownedCarId: ID!, $sellingDealReservationId: ID!) {\n    sellingDealReservation(ownedCarId: $ownedCarId, sellingDealReservationId: $sellingDealReservationId) {\n      id\n      reservationType\n      isCompleted\n      ...SellingDealReservationEdit_index_SellingDealReservation\n      ...SellingDealReservationEdit_BringInForm_SellingDealReservation\n      ...SellingDealReservationEdit_DismantlingForm_SellingDealReservation\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation SellingNeedDocumentConfirm_ConfirmSellingNeedDocument($input: ConfirmSellingNeedDocumentInput!) {\n    confirmSellingNeedDocument(input: $input) {\n      sellingNeedDocument {\n        id\n        nextStepNumber\n        displaySelectedData\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation SellingNeedDocumentConfirm_ConfirmSellingNeedDocument($input: ConfirmSellingNeedDocumentInput!) {\n    confirmSellingNeedDocument(input: $input) {\n      sellingNeedDocument {\n        id\n        nextStepNumber\n        displaySelectedData\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment SellingNeedDocumentConfirmBody_sellingNeedDocument on SellingNeedDocument {\n    id\n    isLoanRemaining\n    ...SellingNeedDocumentConfirmCard_sellingNeedDocument\n  }\n"): (typeof documents)["\n  fragment SellingNeedDocumentConfirmBody_sellingNeedDocument on SellingNeedDocument {\n    id\n    isLoanRemaining\n    ...SellingNeedDocumentConfirmCard_sellingNeedDocument\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query SellingNeedDocumentConfirm_SellingNeedDocument($ownedCarId: ID!, $sellingNeedDocumentId: ID!) {\n    sellingNeedDocument(ownedCarId: $ownedCarId, sellingNeedDocumentId: $sellingNeedDocumentId) {\n      id\n      isAllStepAnswered\n      isUserConfirmed\n      ...SellingNeedDocumentConfirmBody_sellingNeedDocument\n    }\n  }\n"): (typeof documents)["\n  query SellingNeedDocumentConfirm_SellingNeedDocument($ownedCarId: ID!, $sellingNeedDocumentId: ID!) {\n    sellingNeedDocument(ownedCarId: $ownedCarId, sellingNeedDocumentId: $sellingNeedDocumentId) {\n      id\n      isAllStepAnswered\n      isUserConfirmed\n      ...SellingNeedDocumentConfirmBody_sellingNeedDocument\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment SellingNeedDocumentConfirmCard_sellingNeedDocument on SellingNeedDocument {\n    id\n    selectedData {\n      stepId\n      stepNumber\n      question {\n        shortText\n      }\n      choice {\n        text\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment SellingNeedDocumentConfirmCard_sellingNeedDocument on SellingNeedDocument {\n    id\n    selectedData {\n      stepId\n      stepNumber\n      question {\n        shortText\n      }\n      choice {\n        text\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment SellingNeedDocumentDocumentsBody_sellingNeedDocument on SellingNeedDocument {\n    id\n    loanCompanyName\n    selectedData {\n      stepId\n      question {\n        shortText\n      }\n      choice {\n        text\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment SellingNeedDocumentDocumentsBody_sellingNeedDocument on SellingNeedDocument {\n    id\n    loanCompanyName\n    selectedData {\n      stepId\n      question {\n        shortText\n      }\n      choice {\n        text\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query SellingNeedDocumentDocuments_SellingNeedDocument($ownedCarId: ID!, $sellingNeedDocumentId: ID!) {\n    sellingNeedDocument(ownedCarId: $ownedCarId, sellingNeedDocumentId: $sellingNeedDocumentId) {\n      id\n      ...SellingNeedDocumentDocumentsBody_sellingNeedDocument\n    }\n  }\n"): (typeof documents)["\n  query SellingNeedDocumentDocuments_SellingNeedDocument($ownedCarId: ID!, $sellingNeedDocumentId: ID!) {\n    sellingNeedDocument(ownedCarId: $ownedCarId, sellingNeedDocumentId: $sellingNeedDocumentId) {\n      id\n      ...SellingNeedDocumentDocumentsBody_sellingNeedDocument\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment SellingNeedDocumentEditAnswerHistory_sellingNeedDocument on SellingNeedDocument {\n    id\n    selectedData {\n      stepId\n      question {\n        shortText\n      }\n      choice {\n        text\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment SellingNeedDocumentEditAnswerHistory_sellingNeedDocument on SellingNeedDocument {\n    id\n    selectedData {\n      stepId\n      question {\n        shortText\n      }\n      choice {\n        text\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment SellingNeedDocumentEditBody_sellingNeedDocumentStep on SellingNeedDocumentStep {\n    id\n    question {\n      shortText\n      text\n    }\n    choices {\n      text\n      value\n    }\n  }\n"): (typeof documents)["\n  fragment SellingNeedDocumentEditBody_sellingNeedDocumentStep on SellingNeedDocumentStep {\n    id\n    question {\n      shortText\n      text\n    }\n    choices {\n      text\n      value\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment SellingNeedDocumentEditBody_sellingNeedDocument on SellingNeedDocument {\n    id\n    ...SellingNeedDocumentEditAnswerHistory_sellingNeedDocument\n  }\n"): (typeof documents)["\n  fragment SellingNeedDocumentEditBody_sellingNeedDocument on SellingNeedDocument {\n    id\n    ...SellingNeedDocumentEditAnswerHistory_sellingNeedDocument\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query SellingNeedDocumentStepWrapper_SellingNeedDocumentStep(\n    $ownedCarId: ID!\n    $sellingNeedDocumentId: ID!\n    $step: Int!\n  ) {\n    sellingNeedDocumentStep(ownedCarId: $ownedCarId, sellingNeedDocumentId: $sellingNeedDocumentId, step: $step) {\n      ...SellingNeedDocumentEditBody_sellingNeedDocumentStep\n    }\n  }\n"): (typeof documents)["\n  query SellingNeedDocumentStepWrapper_SellingNeedDocumentStep(\n    $ownedCarId: ID!\n    $sellingNeedDocumentId: ID!\n    $step: Int!\n  ) {\n    sellingNeedDocumentStep(ownedCarId: $ownedCarId, sellingNeedDocumentId: $sellingNeedDocumentId, step: $step) {\n      ...SellingNeedDocumentEditBody_sellingNeedDocumentStep\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment SellingNeedDocumentStepWrapper_sellingNeedDocument on SellingNeedDocument {\n    id\n    ...SellingNeedDocumentEditBody_sellingNeedDocument\n  }\n"): (typeof documents)["\n  fragment SellingNeedDocumentStepWrapper_sellingNeedDocument on SellingNeedDocument {\n    id\n    ...SellingNeedDocumentEditBody_sellingNeedDocument\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query SellingNeedDocumentEdit_SellingNeedDocument($ownedCarId: ID!, $sellingNeedDocumentId: ID!) {\n    sellingNeedDocument(ownedCarId: $ownedCarId, sellingNeedDocumentId: $sellingNeedDocumentId) {\n      id\n      nextStepNumber\n      isAllStepAnswered\n      isUserConfirmed\n      isRequireInspectionCertificateImage\n      ...SellingNeedDocumentStepWrapper_sellingNeedDocument\n    }\n  }\n"): (typeof documents)["\n  query SellingNeedDocumentEdit_SellingNeedDocument($ownedCarId: ID!, $sellingNeedDocumentId: ID!) {\n    sellingNeedDocument(ownedCarId: $ownedCarId, sellingNeedDocumentId: $sellingNeedDocumentId) {\n      id\n      nextStepNumber\n      isAllStepAnswered\n      isUserConfirmed\n      isRequireInspectionCertificateImage\n      ...SellingNeedDocumentStepWrapper_sellingNeedDocument\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation useSellingNeedDocumentEdit_SelectSellingNeedDocument($input: SelectSellingNeedDocumentInput!) {\n    selectSellingNeedDocument(input: $input) {\n      sellingNeedDocument {\n        id\n        isAllStepAnswered\n        ...SellingNeedDocumentEditAnswerHistory_sellingNeedDocument\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation useSellingNeedDocumentEdit_SelectSellingNeedDocument($input: SelectSellingNeedDocumentInput!) {\n    selectSellingNeedDocument(input: $input) {\n      sellingNeedDocument {\n        id\n        isAllStepAnswered\n        ...SellingNeedDocumentEditAnswerHistory_sellingNeedDocument\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query SellingNeedDocumentUpload_SellingNeedDocument($ownedCarId: ID!, $sellingNeedDocumentId: ID!) {\n    sellingNeedDocument(ownedCarId: $ownedCarId, sellingNeedDocumentId: $sellingNeedDocumentId) {\n      id\n      isRequireInspectionCertificateImage\n    }\n  }\n"): (typeof documents)["\n  query SellingNeedDocumentUpload_SellingNeedDocument($ownedCarId: ID!, $sellingNeedDocumentId: ID!) {\n    sellingNeedDocument(ownedCarId: $ownedCarId, sellingNeedDocumentId: $sellingNeedDocumentId) {\n      id\n      isRequireInspectionCertificateImage\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation useSellingNeedDocumentUpload_UploadSellingNeedDocumentInspectionCertificateImage(\n    $input: UploadSellingNeedDocumentInspectionCertificateImageInput!\n  ) {\n    uploadSellingNeedDocumentInspectionCertificateImage(input: $input) {\n      sellingNeedDocument {\n        id\n        isRequireInspectionCertificateImage\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation useSellingNeedDocumentUpload_UploadSellingNeedDocumentInspectionCertificateImage(\n    $input: UploadSellingNeedDocumentInspectionCertificateImageInput!\n  ) {\n    uploadSellingNeedDocumentInspectionCertificateImage(input: $input) {\n      sellingNeedDocument {\n        id\n        isRequireInspectionCertificateImage\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation useRevertSellingNeedDocumentSelectedData_RevertSellingNeedDocumentSelectedData(\n    $input: RevertSellingNeedDocumentSelectedDataInput!\n  ) {\n    revertSellingNeedDocumentSelectedData(input: $input) {\n      sellingNeedDocument {\n        id\n        nextStepNumber\n        isAllStepAnswered\n        isUserConfirmed\n        ...SellingNeedDocumentConfirmCard_sellingNeedDocument\n        ...SellingNeedDocumentEditAnswerHistory_sellingNeedDocument\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation useRevertSellingNeedDocumentSelectedData_RevertSellingNeedDocumentSelectedData(\n    $input: RevertSellingNeedDocumentSelectedDataInput!\n  ) {\n    revertSellingNeedDocumentSelectedData(input: $input) {\n      sellingNeedDocument {\n        id\n        nextStepNumber\n        isAllStepAnswered\n        isUserConfirmed\n        ...SellingNeedDocumentConfirmCard_sellingNeedDocument\n        ...SellingNeedDocumentEditAnswerHistory_sellingNeedDocument\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateUser_UserEdit($input: UpdateUserInput!) {\n    updateUser(input: $input) {\n      user {\n        id\n        lastName\n        firstName\n        fullName\n        zipCode\n        zipCodeAddress {\n          id\n          prefecture\n          city\n        }\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateUser_UserEdit($input: UpdateUserInput!) {\n    updateUser(input: $input) {\n      user {\n        id\n        lastName\n        firstName\n        fullName\n        zipCode\n        zipCodeAddress {\n          id\n          prefecture\n          city\n        }\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment ReviewCard_userReview on UserReview {\n    id\n    reviewType\n    satisfactionRate\n    closedMonth\n    makeName\n    carModelName\n    title\n    conveniencePoint\n    staffComment\n    staff {\n      id\n      fullName\n    }\n  }\n"): (typeof documents)["\n  fragment ReviewCard_userReview on UserReview {\n    id\n    reviewType\n    satisfactionRate\n    closedMonth\n    makeName\n    carModelName\n    title\n    conveniencePoint\n    staffComment\n    staff {\n      id\n      fullName\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment ReviewsIndex_userReview on UserReview {\n    id\n    ...ReviewCard_userReview\n    ...ReviewModal_userReview\n  }\n"): (typeof documents)["\n  fragment ReviewsIndex_userReview on UserReview {\n    id\n    ...ReviewCard_userReview\n    ...ReviewModal_userReview\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment ReviewModal_userReview on UserReview {\n    id\n    reviewType\n    satisfactionRate\n    closedMonth\n    makeName\n    carModelName\n    title\n    conveniencePoint\n    worryPoint\n    reason\n    staffComment\n    staff {\n      id\n      fullName\n      imageUrl\n    }\n  }\n"): (typeof documents)["\n  fragment ReviewModal_userReview on UserReview {\n    id\n    reviewType\n    satisfactionRate\n    closedMonth\n    makeName\n    carModelName\n    title\n    conveniencePoint\n    worryPoint\n    reason\n    staffComment\n    staff {\n      id\n      fullName\n      imageUrl\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment CarCardGroup_carStock on CarStock {\n    id\n    ...LargeCarCard_carStock\n    ...SmallCarCard_carStock\n    ...CarIndex_CarMovies_carStock\n  }\n"): (typeof documents)["\n  fragment CarCardGroup_carStock on CarStock {\n    id\n    ...LargeCarCard_carStock\n    ...SmallCarCard_carStock\n    ...CarIndex_CarMovies_carStock\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment CarMoviePopup_carStock on CarStock {\n    id\n    ulid\n    carModelYear\n    mileageKm\n    carModelName\n    gradeName\n    totalPaymentAmount\n    tags {\n      id\n      name\n    }\n    promotion {\n      id\n      catchCopy\n      staffComment\n      postedBy {\n        id\n        imageUrl\n        fullName\n      }\n    }\n    videos {\n      id\n      url\n      thumbnailUrl\n    }\n  }\n"): (typeof documents)["\n  fragment CarMoviePopup_carStock on CarStock {\n    id\n    ulid\n    carModelYear\n    mileageKm\n    carModelName\n    gradeName\n    totalPaymentAmount\n    tags {\n      id\n      name\n    }\n    promotion {\n      id\n      catchCopy\n      staffComment\n      postedBy {\n        id\n        imageUrl\n        fullName\n      }\n    }\n    videos {\n      id\n      url\n      thumbnailUrl\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment CarOfficialValuationShared_carOfficialValuation on CarOfficialValuation {\n    id\n    ...CarOfficialValuationShared_useCarOfficialValuationForm_carOfficialValuation\n  }\n"): (typeof documents)["\n  fragment CarOfficialValuationShared_carOfficialValuation on CarOfficialValuation {\n    id\n    ...CarOfficialValuationShared_useCarOfficialValuationForm_carOfficialValuation\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment CarOfficialValuationShared_ownedCar on OwnedCar {\n    id\n    ...CarOfficialValuationShared_useCarOfficialValuationForm_ownedCar\n  }\n"): (typeof documents)["\n  fragment CarOfficialValuationShared_ownedCar on OwnedCar {\n    id\n    ...CarOfficialValuationShared_useCarOfficialValuationForm_ownedCar\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment CarOfficialValuationShared_useCarOfficialValuationForm_ownedCar on OwnedCar {\n    id\n    makeName\n    carModelName\n    gradeName\n    firstRegistrationYear\n    odorStatus\n    odorStatusComment\n    accidentStatus\n    accidentComment\n    latestMileageKm\n    inspectionCertificateImage {\n      id\n      url\n    }\n    favoriteImage {\n      id\n      url\n    }\n    ownedCarRepairFiles {\n      id\n      fileUrl\n      fileName\n      isPdf\n    }\n  }\n"): (typeof documents)["\n  fragment CarOfficialValuationShared_useCarOfficialValuationForm_ownedCar on OwnedCar {\n    id\n    makeName\n    carModelName\n    gradeName\n    firstRegistrationYear\n    odorStatus\n    odorStatusComment\n    accidentStatus\n    accidentComment\n    latestMileageKm\n    inspectionCertificateImage {\n      id\n      url\n    }\n    favoriteImage {\n      id\n      url\n    }\n    ownedCarRepairFiles {\n      id\n      fileUrl\n      fileName\n      isPdf\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment CarOfficialValuationShared_useCarOfficialValuationForm_carOfficialValuation on CarOfficialValuation {\n    id\n    pickupZipCode\n    ownedCar {\n      id\n      ...CarOfficialValuationShared_useCarOfficialValuationForm_ownedCar\n    }\n  }\n"): (typeof documents)["\n  fragment CarOfficialValuationShared_useCarOfficialValuationForm_carOfficialValuation on CarOfficialValuation {\n    id\n    pickupZipCode\n    ownedCar {\n      id\n      ...CarOfficialValuationShared_useCarOfficialValuationForm_ownedCar\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment CarValuationFormParts_CarSelectModal_CarCard_ownedCar on OwnedCar {\n    id\n    makeName\n    carModelName\n    firstRegistrationYear\n    gradeName\n    odorStatus\n    odorStatusText\n    odorStatusComment\n    accidentStatus\n    accidentStatusText\n    accidentComment\n    latestMileageKm\n    inspectionCertificateImage {\n      id\n      url\n    }\n    favoriteImage {\n      id\n      url\n    }\n    mainImage {\n      id\n      url\n    }\n  }\n"): (typeof documents)["\n  fragment CarValuationFormParts_CarSelectModal_CarCard_ownedCar on OwnedCar {\n    id\n    makeName\n    carModelName\n    firstRegistrationYear\n    gradeName\n    odorStatus\n    odorStatusText\n    odorStatusComment\n    accidentStatus\n    accidentStatusText\n    accidentComment\n    latestMileageKm\n    inspectionCertificateImage {\n      id\n      url\n    }\n    favoriteImage {\n      id\n      url\n    }\n    mainImage {\n      id\n      url\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment CarValuationFormParts_CarSelectModal_ownedCar on OwnedCar {\n    id\n    ...CarValuationFormParts_CarSelectModal_CarCard_ownedCar\n  }\n"): (typeof documents)["\n  fragment CarValuationFormParts_CarSelectModal_ownedCar on OwnedCar {\n    id\n    ...CarValuationFormParts_CarSelectModal_CarCard_ownedCar\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment LargeCarCard_carStock on CarStock {\n    id\n    ulid\n    carModelYear\n    mileageKm\n    carModelName\n    gradeName\n    totalPaymentAmount\n    salesStatus\n    publishedAt\n    tags {\n      id\n      name\n    }\n    promotion {\n      id\n      catchCopy\n      staffComment\n      postedBy {\n        id\n        imageUrl\n      }\n    }\n    dealerBranch {\n      id\n      landTransportArea\n    }\n    imageUrls\n  }\n"): (typeof documents)["\n  fragment LargeCarCard_carStock on CarStock {\n    id\n    ulid\n    carModelYear\n    mileageKm\n    carModelName\n    gradeName\n    totalPaymentAmount\n    salesStatus\n    publishedAt\n    tags {\n      id\n      name\n    }\n    promotion {\n      id\n      catchCopy\n      staffComment\n      postedBy {\n        id\n        imageUrl\n      }\n    }\n    dealerBranch {\n      id\n      landTransportArea\n    }\n    imageUrls\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query formCarStockQuery($makerId: ID!) {\n    carNameOption(makerId: $makerId) {\n      id\n      carNameOptions {\n        id\n        value\n        label\n      }\n    }\n  }\n"): (typeof documents)["\n  query formCarStockQuery($makerId: ID!) {\n    carNameOption(makerId: $makerId) {\n      id\n      carNameOptions {\n        id\n        value\n        label\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query formOptionCarStockSearch {\n    formOption {\n      id\n      carStockSearchMakerOption {\n        id\n        value\n        label\n      }\n      carStockSearchBodyTypeOption {\n        id\n        value\n        label\n      }\n    }\n  }\n"): (typeof documents)["\n  query formOptionCarStockSearch {\n    formOption {\n      id\n      carStockSearchMakerOption {\n        id\n        value\n        label\n      }\n      carStockSearchBodyTypeOption {\n        id\n        value\n        label\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment SmallCarCard_carStock on CarStock {\n    id\n    ulid\n    carModelYear\n    mileageKm\n    carModelName\n    gradeName\n    totalPaymentAmount\n    salesStatus\n    publishedAt\n    tags {\n      id\n      name\n    }\n    promotion {\n      id\n      catchCopy\n      staffComment\n      postedBy {\n        id\n        imageUrl\n      }\n    }\n    dealerBranch {\n      id\n      landTransportArea\n    }\n    imageUrls\n  }\n"): (typeof documents)["\n  fragment SmallCarCard_carStock on CarStock {\n    id\n    ulid\n    carModelYear\n    mileageKm\n    carModelName\n    gradeName\n    totalPaymentAmount\n    salesStatus\n    publishedAt\n    tags {\n      id\n      name\n    }\n    promotion {\n      id\n      catchCopy\n      staffComment\n      postedBy {\n        id\n        imageUrl\n      }\n    }\n    dealerBranch {\n      id\n      landTransportArea\n    }\n    imageUrls\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation useOwnedCarImage_DestroyOwnedCarImage($input: DestroyOwnedCarImageInput!) {\n    destroyOwnedCarImage(input: $input) {\n      id\n      isDestroyed\n      errors {\n        message\n        path\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation useOwnedCarImage_DestroyOwnedCarImage($input: DestroyOwnedCarImageInput!) {\n    destroyOwnedCarImage(input: $input) {\n      id\n      isDestroyed\n      errors {\n        message\n        path\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation createCarStockFavorite($input: CreateCarStockFavoriteInput!) {\n    createCarStockFavorite(input: $input) {\n      carStockFavorite {\n        id\n        carStock {\n          id\n          ...SmallCarCard_carStock\n        }\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation createCarStockFavorite($input: CreateCarStockFavoriteInput!) {\n    createCarStockFavorite(input: $input) {\n      carStockFavorite {\n        id\n        carStock {\n          id\n          ...SmallCarCard_carStock\n        }\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation destroyCarStockFavorite($input: DestroyCarStockFavoriteInput!) {\n    destroyCarStockFavorite(input: $input) {\n      isSuccess\n      errors {\n        message\n        path\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation destroyCarStockFavorite($input: DestroyCarStockFavoriteInput!) {\n    destroyCarStockFavorite(input: $input) {\n      isSuccess\n      errors {\n        message\n        path\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation login($input: LoginInput!) {\n    login(input: $input) {\n      user {\n        id\n        lastName\n        firstName\n        zipCode\n        lineAccount {\n          id\n          name\n        }\n        favoritedCarStocks {\n          id\n        }\n        inquiries {\n          id\n          carStocks {\n            id\n          }\n        }\n      }\n      isCreatedUser\n      errors {\n        path\n        message\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation login($input: LoginInput!) {\n    login(input: $input) {\n      user {\n        id\n        lastName\n        firstName\n        zipCode\n        lineAccount {\n          id\n          name\n        }\n        favoritedCarStocks {\n          id\n        }\n        inquiries {\n          id\n          carStocks {\n            id\n          }\n        }\n      }\n      isCreatedUser\n      errors {\n        path\n        message\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation updateLineAccountName($input: UpdateLineAccountNameInput!) {\n    updateLineAccountName(input: $input) {\n      lineAccount {\n        id\n        name\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation updateLineAccountName($input: UpdateLineAccountNameInput!) {\n    updateLineAccountName(input: $input) {\n      lineAccount {\n        id\n        name\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query convertLatlng($longitude: Float!, $latitude: Float!) {\n    convertLatlng(longitude: $longitude, latitude: $latitude) {\n      zipCode\n      address\n      prefectureCode\n      errors {\n        message\n      }\n    }\n  }\n"): (typeof documents)["\n  query convertLatlng($longitude: Float!, $latitude: Float!) {\n    convertLatlng(longitude: $longitude, latitude: $latitude) {\n      zipCode\n      address\n      prefectureCode\n      errors {\n        message\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query convertZipCode($zipCode: String!) {\n    convertZipCode(zipCode: $zipCode) {\n      zipCode\n      address\n      prefectureCode\n      prefectureName\n      cityName\n      errors {\n        message\n      }\n    }\n  }\n"): (typeof documents)["\n  query convertZipCode($zipCode: String!) {\n    convertZipCode(zipCode: $zipCode) {\n      zipCode\n      address\n      prefectureCode\n      prefectureName\n      cityName\n      errors {\n        message\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query user {\n    viewer {\n      id\n      lastName\n      firstName\n      zipCode\n      tracking\n      lineAccount {\n        id\n        name\n      }\n      favoritedCarStocks {\n        id\n      }\n      inquiries {\n        id\n        carStocks {\n          id\n        }\n      }\n      activeOneClickContracts {\n        id\n        carStock {\n          id\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query user {\n    viewer {\n      id\n      lastName\n      firstName\n      zipCode\n      tracking\n      lineAccount {\n        id\n        name\n      }\n      favoritedCarStocks {\n        id\n      }\n      inquiries {\n        id\n        carStocks {\n          id\n        }\n      }\n      activeOneClickContracts {\n        id\n        carStock {\n          id\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query fetchDirectUploadParams($requests: [DirectUploadRequest!]!) {\n    s3DirectUploadParams(requests: $requests) {\n      url\n      contentToken\n      fields\n    }\n  }\n"): (typeof documents)["\n  query fetchDirectUploadParams($requests: [DirectUploadRequest!]!) {\n    s3DirectUploadParams(requests: $requests) {\n      url\n      contentToken\n      fields\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query CarStocksIndexPage($first: Int, $salesStatus: CarSalesStatusEnum) {\n    carStocks(first: $first, salesStatus: $salesStatus) {\n      edges {\n        node {\n          id\n          landTransportCosts {\n            cost\n            prefectureCode\n          }\n          ...CarsIndex_carStock\n        }\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n"): (typeof documents)["\n  query CarStocksIndexPage($first: Int, $salesStatus: CarSalesStatusEnum) {\n    carStocks(first: $first, salesStatus: $salesStatus) {\n      edges {\n        node {\n          id\n          landTransportCosts {\n            cost\n            prefectureCode\n          }\n          ...CarsIndex_carStock\n        }\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query SpecialShowPage($first: Int, $after: String, $salesStatus: CarSalesStatusEnum, $tagGroup: TagGroupEnum) {\n    carStocks(first: $first, after: $after, salesStatus: $salesStatus, tagGroup: $tagGroup) {\n      edges {\n        node {\n          id\n          landTransportCosts {\n            cost\n            prefectureCode\n          }\n          ...SpecialShow_carStock\n        }\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n"): (typeof documents)["\n  query SpecialShowPage($first: Int, $after: String, $salesStatus: CarSalesStatusEnum, $tagGroup: TagGroupEnum) {\n    carStocks(first: $first, after: $after, salesStatus: $salesStatus, tagGroup: $tagGroup) {\n      edges {\n        node {\n          id\n          landTransportCosts {\n            cost\n            prefectureCode\n          }\n          ...SpecialShow_carStock\n        }\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query CarStockShowPage($ulid: String!) {\n    carStock(ulid: $ulid) {\n      id\n      makeName\n      carModelName\n      carModelYear\n      gradeName\n      mileageKm\n      inspectionExpiryDate\n      images {\n        id\n        url\n      }\n      promotion {\n        id\n        description\n        catchCopy\n        scores {\n          score\n        }\n      }\n      carStockDetail {\n        id\n        repairHistoryCategory\n        interiorEvaluationScore\n      }\n      ...CarsShow_carStock\n      oneClickContractCounts\n    }\n  }\n"): (typeof documents)["\n  query CarStockShowPage($ulid: String!) {\n    carStock(ulid: $ulid) {\n      id\n      makeName\n      carModelName\n      carModelYear\n      gradeName\n      mileageKm\n      inspectionExpiryDate\n      images {\n        id\n        url\n      }\n      promotion {\n        id\n        description\n        catchCopy\n        scores {\n          score\n        }\n      }\n      carStockDetail {\n        id\n        repairHistoryCategory\n        interiorEvaluationScore\n      }\n      ...CarsShow_carStock\n      oneClickContractCounts\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query CarsMoviesPage($first: Int, $after: String, $hasVideo: Boolean, $salesStatus: CarSalesStatusEnum) {\n    carStocks(first: $first, after: $after, hasVideo: $hasVideo, salesStatus: $salesStatus) {\n      edges {\n        node {\n          id\n          landTransportCosts {\n            cost\n            prefectureCode\n          }\n          ...CarMovies_carStock\n        }\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n"): (typeof documents)["\n  query CarsMoviesPage($first: Int, $after: String, $hasVideo: Boolean, $salesStatus: CarSalesStatusEnum) {\n    carStocks(first: $first, after: $after, hasVideo: $hasVideo, salesStatus: $salesStatus) {\n      edges {\n        node {\n          id\n          landTransportCosts {\n            cost\n            prefectureCode\n          }\n          ...CarMovies_carStock\n        }\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query userInterviewCarShow($ulid: String!) {\n    carStock(ulid: $ulid) {\n      id\n      ulid\n      makeName\n      carModelName\n      carModelYear\n      gradeName\n      mileageKm\n      imageUrls\n      makeCode\n      carModelCode\n    }\n  }\n"): (typeof documents)["\n  query userInterviewCarShow($ulid: String!) {\n    carStock(ulid: $ulid) {\n      id\n      ulid\n      makeName\n      carModelName\n      carModelYear\n      gradeName\n      mileageKm\n      imageUrls\n      makeCode\n      carModelCode\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation Logout($input: LogoutInput!) {\n    logout(input: $input) {\n      errors {\n        message\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation Logout($input: LogoutInput!) {\n    logout(input: $input) {\n      errors {\n        message\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query formOptionOneClickNew {\n    formOption {\n      id\n      requestTypeOption {\n        id\n        value\n        label\n      }\n      prefectureOption {\n        id\n        value\n        label\n      }\n    }\n  }\n"): (typeof documents)["\n  query formOptionOneClickNew {\n    formOption {\n      id\n      requestTypeOption {\n        id\n        value\n        label\n      }\n      prefectureOption {\n        id\n        value\n        label\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ReviewsPage($reviewType: UserReviewReviewTypeEnum!, $first: Int, $after: String) {\n    userReviews(reviewType: $reviewType, first: $first, after: $after) {\n      nodes {\n        ...ReviewsIndex_userReview\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n"): (typeof documents)["\n  query ReviewsPage($reviewType: UserReviewReviewTypeEnum!, $first: Int, $after: String) {\n    userReviews(reviewType: $reviewType, first: $first, after: $after) {\n      nodes {\n        ...ReviewsIndex_userReview\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query CarStocksSearchPage(\n    $first: Int\n    $after: String\n    $salesStatus: CarSalesStatusEnum\n    $bodyType: CarStockBodyTypeEnum\n    $makeCode: String\n    $carModelCode: String\n    $shift: String\n    $yearFrom: String\n    $yearTo: String\n    $freeText: String\n    $sortType: CarStockSortTypeEnum\n    $hasVideo: Boolean\n    $includeTotalCount: Boolean!\n  ) {\n    carStocks(\n      first: $first\n      after: $after\n      salesStatus: $salesStatus\n      bodyType: $bodyType\n      makeCode: $makeCode\n      carModelCode: $carModelCode\n      shift: $shift\n      yearFrom: $yearFrom\n      yearTo: $yearTo\n      freeText: $freeText\n      sortType: $sortType\n      hasVideo: $hasVideo\n    ) {\n      edges {\n        node {\n          ...CarsSearch_carStock\n        }\n      }\n      totalCount @include(if: $includeTotalCount)\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n"): (typeof documents)["\n  query CarStocksSearchPage(\n    $first: Int\n    $after: String\n    $salesStatus: CarSalesStatusEnum\n    $bodyType: CarStockBodyTypeEnum\n    $makeCode: String\n    $carModelCode: String\n    $shift: String\n    $yearFrom: String\n    $yearTo: String\n    $freeText: String\n    $sortType: CarStockSortTypeEnum\n    $hasVideo: Boolean\n    $includeTotalCount: Boolean!\n  ) {\n    carStocks(\n      first: $first\n      after: $after\n      salesStatus: $salesStatus\n      bodyType: $bodyType\n      makeCode: $makeCode\n      carModelCode: $carModelCode\n      shift: $shift\n      yearFrom: $yearFrom\n      yearTo: $yearTo\n      freeText: $freeText\n      sortType: $sortType\n      hasVideo: $hasVideo\n    ) {\n      edges {\n        node {\n          ...CarsSearch_carStock\n        }\n      }\n      totalCount @include(if: $includeTotalCount)\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query makerAndBodyTypeOption {\n    formOption {\n      id\n      carStockSearchMakerOption {\n        id\n        value\n        label\n      }\n      carStockSearchBodyTypeOption {\n        id\n        value\n        label\n      }\n    }\n  }\n"): (typeof documents)["\n  query makerAndBodyTypeOption {\n    formOption {\n      id\n      carStockSearchMakerOption {\n        id\n        value\n        label\n      }\n      carStockSearchBodyTypeOption {\n        id\n        value\n        label\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query UserReviewFormOptionQuery {\n    formOption {\n      id\n      userReviewTypeOption {\n        id\n        value\n        label\n      }\n    }\n  }\n"): (typeof documents)["\n  query UserReviewFormOptionQuery {\n    formOption {\n      id\n      userReviewTypeOption {\n        id\n        value\n        label\n      }\n    }\n  }\n"];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;