import type { FunctionComponent, ReactNode } from 'react';
import styles from '~/styles/layout/main.module.css';
import { Footer } from './Footer';
import { Header } from './Header';

type Props = {
  children: ReactNode;
  isHideHeader?: boolean;
  isHideFooter?: boolean;
};

export const Layout: FunctionComponent<Props> = ({ children, isHideHeader, isHideFooter }) => {
  return (
    <>
      {!isHideHeader && <Header />}
      <main className={[styles.main, isHideHeader ? styles['hide-header'] : ''].join(' ')}>{children}</main>
      {!isHideFooter && <Footer />}
    </>);

};