import liff from '@line/liff';
import { useLocation, useNavigate } from '@remix-run/react';
import { useEffect, useState, type FunctionComponent } from 'react';
import Slider from 'react-slick';
import { graphql, useFragment, type FragmentType } from '~/gql/generated';
import { type LargeCarCard_CarStockFragment } from '~/gql/generated/graphql';
import { useAnalytics, useFavorite } from '~/hooks';
import {
  useDeliveryAddressContext,
  useErrorFlashContext,
  useInfiniteScrollContext,
  useLineFavoriteModalContext } from
'~/providers';
import styles from '~/styles/shared/large-car-card.module.css';
import "~/styles/shared/react-slick.css?__remix_sideEffect__";
import { type CarStockLandTransportCostType } from '~/types/landTransportCost';
import { isNewPublished, priceNumber, priceText, shortCarModelYear } from '~/utils/carStock';

const blockClass = 'large-car-card';

const LargeCarCardCarStockFragment = graphql(`
  fragment LargeCarCard_carStock on CarStock {
    id
    ulid
    carModelYear
    mileageKm
    carModelName
    gradeName
    totalPaymentAmount
    salesStatus
    publishedAt
    tags {
      id
      name
    }
    promotion {
      id
      catchCopy
      staffComment
      postedBy {
        id
        imageUrl
      }
    }
    dealerBranch {
      id
      landTransportArea
    }
    imageUrls
  }
`);

type Props = {
  carStock: FragmentType<typeof LargeCarCardCarStockFragment>;
  isFavorited: boolean;
  isStoreClickId?: boolean;
};

export const LargeCarCard: FunctionComponent<Props> = (props) => {
  const { isFavorited, isStoreClickId } = props;
  const carStock = useFragment(LargeCarCardCarStockFragment, props.carStock) as LargeCarCard_CarStockFragment &
  CarStockLandTransportCostType;
  const { open: openLineFavoriteModal } = useLineFavoriteModalContext();
  const { favorite } = useFavorite();
  const { open: openErrorFlash } = useErrorFlashContext();
  const [swiping, setSwiping] = useState(false);
  const tag = carStock.tags?.[0]?.name;
  const sliderSettings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    dotsClass: `${blockClass}__slick-dots slick-dots`,
    beforeChange: () => setSwiping(true),
    afterChange: () => setSwiping(false)
  };
  const { prefectureCode } = useDeliveryAddressContext();
  const [landTransportCost, setLandTransportCost] = useState<number | null>(null);
  const location = useLocation();
  const { storeElementId } = useInfiniteScrollContext();
  const { sendTrackEvent } = useAnalytics();
  const elementId = `car-stock-${carStock.ulid}`;
  const navigate = useNavigate();

  useEffect(() => {
    if (!prefectureCode) {
      setLandTransportCost(null);
      return;
    }
    if (landTransportCost) return;
    if (carStock.landTransportCost) {
      setLandTransportCost(carStock.landTransportCost);
      return;
    }

    const landTransportCostByPrefectureCode = carStock.landTransportCosts?.find(
      (cost) => cost.prefectureCode === prefectureCode
    )?.cost;
    if (!landTransportCostByPrefectureCode) return;

    setLandTransportCost(landTransportCostByPrefectureCode);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carStock.landTransportCost, carStock.landTransportCosts, prefectureCode]);

  const handleClickFavorite = async (e: React.MouseEvent) => {
    e.stopPropagation();

    const { isAuthError, isSuccess } = await favorite({
      isFavorited,
      carStockId: carStock.id,
      eventFrom: 'large-car-card'
    });

    if (isAuthError) {
      openLineFavoriteModal();
      return;
    }

    if (!isSuccess) {
      openErrorFlash();
      return;
    }
  };

  const handleClickCard = () => {
    if (isStoreClickId) {
      storeElementId(location.key, elementId);
    }
    sendTrackEvent('click_large_car_card', { car_stock_id: carStock.id });
    if (liff.isInClient()) {
      navigate(`/cars/${carStock.ulid}`);
    } else {
      window.open(`/cars/${carStock.ulid}`);
    }
  };

  return (
    <>
      <div
        className={[styles[blockClass], carStock.salesStatus === 'CLOSED' ? styles['is-soldout'] : ''].join(' ')}
        id={elementId}>

        <div className={styles[`${blockClass}__image`]}>
          <Slider {...sliderSettings}>
            {carStock.imageUrls?.slice(0, 2).map((imageUrl, index) =>
            <img
              src={imageUrl || '/images/pictures/no_image.png'}
              alt='car'
              className={styles[`${blockClass}__image-image`]}
              onClick={() => {
                if (swiping) return;
                handleClickCard();
              }}
              key={index} />

            )}
          </Slider>
          <div className={styles[`${blockClass}__image-favorite`]} onClick={handleClickFavorite}>
            <img
              src={`/images/icons/${isFavorited ? 'ic_favorite_filled.svg' : 'ic_favorite_white.svg'}`}
              alt='favorite' />

          </div>
          <p className={styles[`${blockClass}__image-price`]}>
            {carStock.totalPaymentAmount ?
            <>
                <span className={styles[`${blockClass}__image-price__text`]}>総額</span>
                <span className={styles[`${blockClass}__image-price__number`]}>
                  {priceNumber(carStock.totalPaymentAmount, landTransportCost)}
                </span>
                {priceText(carStock.totalPaymentAmount, landTransportCost)}
              </> :

            <span>価格はお問い合わせください</span>
            }
          </p>
          {tag && <p className={styles[`${blockClass}__image-tag`]}># {tag}</p>}
          {carStock.salesStatus === 'CLOSED' ?
          <p className={styles[`${blockClass}__image-soldout`]}>SOLD OUT</p> :

          isNewPublished(carStock.publishedAt) && <p className={styles[`${blockClass}__image-new`]}>NEW</p>
          }
        </div>
        <div className={styles[`${blockClass}__content`]} onClick={handleClickCard}>
          <div className={styles[`${blockClass}__features`]}>
            <div className={styles[`${blockClass}__features-item`]}>
              <span className={styles[`${blockClass}__features-item__icon`]}>年</span>
              <span className={styles[`${blockClass}__features-item__text`]}>
                {shortCarModelYear(carStock.carModelYear) || 'ー'}
              </span>
            </div>
            <div className={styles[`${blockClass}__features-item`]}>
              <span className={styles[`${blockClass}__features-item__icon`]}>走</span>
              <span className={styles[`${blockClass}__features-item__text`]}>
                {carStock.mileageKm >= 10000 ? `${Math.floor(carStock.mileageKm / 1000) / 10}万` : carStock.mileageKm}km
              </span>
            </div>
            <div className={styles[`${blockClass}__features-item`]}>
              <span className={styles[`${blockClass}__features-item__icon`]}>車</span>
              <span className={styles[`${blockClass}__features-item__text`]}>{carStock.carModelName}</span>
            </div>
            <div className={styles[`${blockClass}__features-item`]}>
              <span className={styles[`${blockClass}__features-item__icon`]}>G</span>
              <span className={styles[`${blockClass}__features-item__text`]}>{carStock.gradeName}</span>
            </div>
          </div>
          {carStock.promotion &&
          <div className={styles[`${blockClass}__comment`]}>
              <p className={styles[`${blockClass}__comment-title`]}>{carStock.promotion.catchCopy}</p>
              <div className={styles[`${blockClass}__comment-staff`]}>
                <div className={styles[`${blockClass}__comment-staff__icon`]}>
                  <img src={carStock.promotion.postedBy?.imageUrl || '/images/pictures/no_image.png'} alt='icon' />
                </div>
                {carStock.promotion.staffComment &&
              <p className={styles[`${blockClass}__comment-staff__text`]}>
                    <span>{carStock.promotion.staffComment}</span>
                  </p>
              }
              </div>
            </div>
          }
        </div>
      </div>
    </>);

};