import liff from '@line/liff';
import { useLocation, useNavigate } from '@remix-run/react';
import { useEffect, useState, type FunctionComponent } from 'react';
import { graphql, useFragment, type FragmentType } from '~/gql/generated';
import { type SmallCarCard_CarStockFragment } from '~/gql/generated/graphql';
import { useAnalytics, useAuth, useFavorite } from '~/hooks';
import {
  useDeliveryAddressContext,
  useErrorFlashContext,
  useInfiniteScrollContext,
  useLineFavoriteModalContext } from
'~/providers';
import styles from '~/styles/shared/small-car-card.module.css';
import { type CarStockLandTransportCostType } from '~/types/landTransportCost';
import { isNewPublished, priceNumber, priceText, shortCarModelYear } from '~/utils/carStock';
import { CarCardImageNormal } from '../CarCardImage/CarCardImageNormal';

const blockClass = 'small-car-card';

export const SmallCarCardCarStockFragment = graphql(`
  fragment SmallCarCard_carStock on CarStock {
    id
    ulid
    carModelYear
    mileageKm
    carModelName
    gradeName
    totalPaymentAmount
    salesStatus
    publishedAt
    tags {
      id
      name
    }
    promotion {
      id
      catchCopy
      staffComment
      postedBy {
        id
        imageUrl
      }
    }
    dealerBranch {
      id
      landTransportArea
    }
    imageUrls
  }
`);

type Props = {
  carStock: FragmentType<typeof SmallCarCardCarStockFragment>;
  isFavorited: boolean;
  hiddenComment?: boolean;
  unclickable?: boolean;
  imageHeight?: number;
  isDisplayInquired?: boolean;
  isStoreClickId?: boolean;
};

export const SmallCarCard: FunctionComponent<Props> = (props) => {
  const { isFavorited, hiddenComment, unclickable, imageHeight, isDisplayInquired, isStoreClickId } = props;
  const carStock = useFragment(SmallCarCardCarStockFragment, props.carStock) as SmallCarCard_CarStockFragment &
  CarStockLandTransportCostType;
  const { user } = useAuth();
  const inquiredCarStockIds =
  user?.inquiries?.map((inquiry) => inquiry.carStocks?.map((carStock) => carStock.id) || [])?.flat() || [];
  const isInquired = inquiredCarStockIds.includes(carStock.id);
  const { open: openLineFavoriteModal } = useLineFavoriteModalContext();
  const { favorite } = useFavorite();
  const { open: openErrorFlash } = useErrorFlashContext();
  const tag = carStock.tags?.[0]?.name;
  const { prefectureCode } = useDeliveryAddressContext();
  const [landTransportCost, setLandTransportCost] = useState<number | null>(null);
  const location = useLocation();
  const { storeElementId } = useInfiniteScrollContext();
  const { sendTrackEvent } = useAnalytics();
  const elementId = `car-stock-${carStock.ulid}`;
  const navigate = useNavigate();

  useEffect(() => {
    if (!prefectureCode) {
      setLandTransportCost(null);
      return;
    }
    if (landTransportCost) return;
    if (carStock.landTransportCost) {
      setLandTransportCost(carStock.landTransportCost);
      return;
    }

    const landTransportCostByPrefectureCode = carStock.landTransportCosts?.find(
      (cost) => cost.prefectureCode === prefectureCode
    )?.cost;
    if (!landTransportCostByPrefectureCode) return;

    setLandTransportCost(landTransportCostByPrefectureCode);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carStock.landTransportCost, carStock.landTransportCosts, prefectureCode]);

  const handleClickFavorite = async () => {
    if (unclickable) return;

    const { isAuthError, isSuccess } = await favorite({
      isFavorited,
      carStockId: carStock.id,
      eventFrom: 'small-car-card'
    });

    if (isAuthError) {
      openLineFavoriteModal();
      return;
    }

    if (!isSuccess) {
      openErrorFlash();
      return;
    }
  };

  const handleClickCard = () => {
    if (unclickable) return;
    if (isStoreClickId) {
      storeElementId(location.key, elementId);
    }
    sendTrackEvent('click_small_car_card', { car_stock_id: carStock.id });
    if (liff.isInClient()) {
      navigate(`/cars/${carStock.ulid}`);
    } else {
      window.open(`/cars/${carStock.ulid}`);
    }
  };

  return (
    <>
      <div
        className={[styles[blockClass], carStock.salesStatus === 'CLOSED' ? styles['is-soldout'] : ''].join(' ')}
        id={elementId}
        onClick={handleClickCard}>

        <div className={styles[`${blockClass}__image`]}>
          <div style={{ height: imageHeight }}>
            <CarCardImageNormal
              src={carStock.imageUrls?.[0]}
              alt='car'
              isFavorite={isFavorited}
              handleFavorite={handleClickFavorite} />

          </div>
          <p className={styles[`${blockClass}__image-price`]}>
            {carStock.totalPaymentAmount ?
            <>
                <span className={styles[`${blockClass}__image-price__text`]}>総額</span>
                <span className={styles[`${blockClass}__image-price__number`]}>
                  {priceNumber(carStock.totalPaymentAmount, landTransportCost)}
                </span>
                {priceText(carStock.totalPaymentAmount, landTransportCost)}
              </> :

            <span>価格はお問い合わせください</span>
            }
          </p>
          {carStock.salesStatus === 'CLOSED' ?
          <p className={styles[`${blockClass}__image-soldout`]}>SOLD OUT</p> :
          isNewPublished(carStock.publishedAt) ?
          <p className={styles[`${blockClass}__image-new`]}>NEW</p> :

          tag && <p className={styles[`${blockClass}__image-tag`]}># {tag}</p>
          }
        </div>
        <div className={styles[`${blockClass}__features`]}>
          <div className={styles[`${blockClass}__features-wrapper`]}>
            <div className={styles[`${blockClass}__features-item`]}>
              <span className={styles[`${blockClass}__features-item__icon`]}>年</span>
              <span className={styles[`${blockClass}__features-item__text`]}>
                {shortCarModelYear(carStock.carModelYear) || 'ー'}
              </span>
            </div>
            <div className={styles[`${blockClass}__features-item`]}>
              <span className={styles[`${blockClass}__features-item__icon`]}>走</span>
              <span className={styles[`${blockClass}__features-item__text`]}>
                {carStock.mileageKm >= 10000 ? `${Math.floor(carStock.mileageKm / 1000) / 10}万` : carStock.mileageKm}km
              </span>
            </div>
            <div className={styles[`${blockClass}__features-item`]}>
              <span className={styles[`${blockClass}__features-item__icon`]}>車</span>
              <span className={styles[`${blockClass}__features-item__text`]}>{carStock.carModelName}</span>
            </div>
            <div className={styles[`${blockClass}__features-item`]}>
              <span className={styles[`${blockClass}__features-item__icon`]}>G</span>
              <span className={styles[`${blockClass}__features-item__text`]}>{carStock.gradeName}</span>
            </div>
          </div>
        </div>
        {!hiddenComment && carStock.promotion &&
        <div className={styles[`${blockClass}__comment`]}>
            <p className={styles[`${blockClass}__comment-title`]}>{carStock.promotion.catchCopy}</p>
            <div className={styles[`${blockClass}__comment-staff`]}>
              <div className={styles[`${blockClass}__comment-staff__icon`]}>
                <img src={carStock.promotion.postedBy?.imageUrl || '/images/pictures/no_image.png'} alt='icon' />
              </div>
              {carStock.promotion.staffComment &&
            <p className={styles[`${blockClass}__comment-staff__text`]}>
                  <span>{carStock.promotion.staffComment}</span>
                </p>
            }
            </div>
          </div>
        }
        {isDisplayInquired && isInquired &&
        <div className={styles[`${blockClass}__inquired-wrapper`]}>
            <span className={styles[`${blockClass}__inquired`]}>お問合せ済</span>
          </div>
        }
      </div>
    </>);

};